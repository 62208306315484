import React from 'react';
import styled from 'styled-components';

const StyledHeader = styled.div`
  ${(p) => p.theme.typography.h3}
  margin: ${(p) => p.theme.spacing(0, 3)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  children: React.ReactNode;
};

export default function CollectionHeader(props: Props): JSX.Element {
  return <StyledHeader {...props} />;
}
