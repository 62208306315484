import Footer, { height as footerHeight } from 'components/Footer';
import Header, { height as navHeight } from 'components/Header';
import React from 'react';
import styled from 'styled-components';

const StyledMain = styled.main`
  margin-top: ${navHeight};
  margin-bottom: ${footerHeight};
`;

const Chrome: React.FC = ({ children }) => {
  return (
    <>
      <Header />
      <StyledMain>{children}</StyledMain>
      <Footer />
    </>
  );
};

export default Chrome;
