import { createMuiTheme } from '@material-ui/core/styles';
import 'typeface-roboto';
import 'typeface-roboto-condensed';
import 'typeface-roboto-slab';

const fontFamilyRobotoCondensed = ['"Roboto Condensed"', 'sans-serif;'].join(
  ',',
);

const fontFamilyRobotoSlab = ['"Roboto Slab"', 'sans-serif;'].join(',');

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      dark: 'hsl(126, 98%, 15%)',
      main: 'hsl(126, 98%, 20%)',
      light: 'hsl(126, 98%, 25%)',
    },
    secondary: {
      main: '#424242',
      light: '#ebebeb',
      dark: '#242424',
    },
    text: {
      secondary: '#242424',
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: fontFamilyRobotoCondensed,
    h1: {
      fontFamily: fontFamilyRobotoSlab,
      fontWeight: 700,
      fontSize: '2rem',
      margin: '0',
      '@media (max-width:768px)': {
        fontSize: '1.625rem',
      },
    },
    h2: {
      fontFamily: fontFamilyRobotoCondensed,
      fontWeight: 700,
      fontSize: '1.625rem',
      margin: '0',
    },
    h3: {
      fontFamily: fontFamilyRobotoSlab,
      fontWeight: 700,
      fontSize: '1rem',
    },
    h4: {
      fontFamily: fontFamilyRobotoCondensed,
      fontSize: '0.6875rem',
      fontWeight: 700,
      letterSpacing: '-.02rem',
    },
    body1: {
      fontFamily: fontFamilyRobotoCondensed,
      fontWeight: 400,
      fontSize: '1em',
    },
    body2: {
      fontFamily: fontFamilyRobotoCondensed,
      fontWeight: 400,
      fontSize: '1em',
    },
    subtitle1: {
      fontFamily: fontFamilyRobotoCondensed,
      fontWeight: 700,
      fontSize: '0.8125rem',
    },
    subtitle2: {
      fontFamily: fontFamilyRobotoCondensed,
      fontWeight: 400,
      fontSize: '0.8125rem',
    },
    caption: {
      fontFamily: fontFamilyRobotoCondensed,
      fontWeight: 700,
      fontSize: '0.6875rem',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        height: '2.625rem',
        padding: '0 .5rem',
        fontSize: '0.8125rem',
      },
    },
    MuiIconButton: {
      root: {
        padding: '0 .5rem',
        fontSize: '0.8125rem',
      },
    },
    MuiSelect: {
      iconOutlined: {
        right: '9px',
      },
    },
    MuiChip: {
      outlined: {
        color: '#fff',
        borderColor: '#fff',
      },
      sizeSmall: {
        fontSize: '.5rem',
        height: '1rem',
        fontWeight: 600,
        textTransform: 'uppercase',
      },
      label: {
        paddingTop: '.125rem',
        paddingBottom: '.125rem',
      },
      labelSmall: {
        paddingLeft: '.25rem',
        paddingRight: '.25rem',
      },
    },
    MuiFormControl: {
      root: {
        display: 'block',
        fontSize: '1rem',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '1rem',
      },
    },
    MuiTableCell: {
      root: {
        padding: '.125rem',
        fontSize: '.85rem',
      },
    },
  },
});

export default theme;
