import { TextField, TextFieldProps } from '@material-ui/core';
import type { DateRange } from '@material-ui/pickers';
import {
  DateRangeDelimiter,
  DateRangePicker,
  DateRangePickerProps,
} from '@material-ui/pickers';
import React from 'react';

export type DateFieldProps = Omit<
  DateRangePickerProps,
  'onChange' | 'value' | 'renderInput'
> & {
  onChange?: (range: DateRange | null) => void;
  value: DateRange;
  helperTextStart?: string;
  helperTextEnd?: string;
  TextFieldProps?: TextFieldProps;
  delimiter?: string;
  /**
   * Skip input rendering
   */
  renderInputs?: boolean;
};

const DateRangeField: React.FC<DateFieldProps> = (props) => {
  const {
    onChange,
    helperTextStart,
    helperTextEnd,
    delimiter,
    value,
    renderInputs,
    ...other
  } = props;
  const [selectedDates, setSelectedDates] = React.useState<DateRange>(
    value || [null, null],
  );

  React.useEffect(() => {
    if (
      value &&
      value[0] !== selectedDates[0] &&
      value[1] !== selectedDates[1]
    ) {
      setSelectedDates(value);
    }
  }, [value, selectedDates]);

  return (
    <DateRangePicker
      {...other}
      value={selectedDates}
      clearable={true}
      renderInput={(startProps, endProps) => {
        if (renderInputs === false) {
          // fix this once we upgrade to latest MUI (we can't return null here)
          return <></>;
        }
        return (
          <>
            <TextField
              {...startProps}
              variant="outlined"
              helperText={helperTextStart}
              {...props.TextFieldProps}
            />
            <DateRangeDelimiter>{delimiter}</DateRangeDelimiter>
            <TextField
              {...endProps}
              variant="outlined"
              helperText={helperTextEnd}
              {...props.TextFieldProps}
            />
          </>
        );
      }}
      onChange={(range) => {
        setSelectedDates(range);
        if (props.onChange) {
          props.onChange(range);
        }
      }}
    />
  );
};

export default DateRangeField;
