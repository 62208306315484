import { gql } from '@apollo/client';
import { OrderListSliderItemFulfillmentFragment } from './Fulfillment/fragments';
import { OrderListSliderItemShipmentFragment } from './Shipment/fragments';
import { OrderListSliderItemWholeSaleFragment } from './WholeSale/fragments';

export const OrderListSliderItemFragment = gql`
  fragment OrderListSliderItemFragment on Order {
    ...OrderListSliderItemFulfillmentFragment
    ...OrderListSliderItemShipmentFragment
    ...OrderListSliderItemWholeSaleFragment
  }
  ${OrderListSliderItemFulfillmentFragment}
  ${OrderListSliderItemShipmentFragment}
  ${OrderListSliderItemWholeSaleFragment}
`;
