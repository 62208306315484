import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { navigate } from '@reach/router';
import { AdminChatNotifications } from 'contexts/Firebase/AdminChatNotifications';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import cleanUrl from 'utils/clean-url';

const StyledButton = styled(Button)`
  color: #00ff00;
`;

const StyledSnackbar = styled(Snackbar)`
  @media print {
    display: none;
  }
`;

const checkUnresolved = (notificationList: any[]) => {
  let hasUnresolved = false;
  notificationList.forEach((notification) => {
    const resolved = notification.data().resolved;
    if (!resolved) {
      hasUnresolved = true;
    }
  });
  return hasUnresolved;
};

const AdminChatToast: React.FC = () => {
  const { notifications } = useContext(AdminChatNotifications);
  const [isToastShown, setIsToastShown] = useState(false);

  useEffect(() => {
    if (!notifications) {
      return;
    }

    const isOpenIssue = checkUnresolved(notifications);

    if (isOpenIssue) {
      setIsToastShown(true);
    }
    // We do not want to add `initialized` as a dependency. This is because
    // I only want to trigger notifications for new messages. If I leave the
    // `initialized` in, then the useEffect will detect when I update the state
    // and I only want it watching for new `notifications`.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  const handleClose = useCallback(() => {
    setIsToastShown(false);
  }, [setIsToastShown]);

  const toastLinkClick = useCallback(() => {
    const searchParams = new URLSearchParams();
    searchParams.set('admin-notification', '');
    navigate(cleanUrl('', searchParams));

    setIsToastShown(false);
  }, [setIsToastShown]);

  return (
    <StyledSnackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isToastShown}
      onClose={handleClose}
      message="New Support Update"
      action={
        <React.Fragment>
          <StyledButton color="primary" onClick={toastLinkClick}>
            Open admin notifications
          </StyledButton>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};

export default AdminChatToast;
