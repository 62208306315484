import { Button, IconButton, Typography } from '@material-ui/core';
import { Store } from '@material-ui/icons';
import { Link, useLocation } from '@reach/router';
import AdminNotificationBell from 'components/AdminNotificationBell';
import NotificationsBellWrapper from 'containers/NotificationBell';
import { Auth } from 'contexts/Auth';
import { UserRole } from 'generated-types';
import React, { useContext } from 'react';
import styled from 'styled-components';
import cleanUrl from 'utils/clean-url';
import AccountLinks from './Account';

const NavLinks = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0 0.25rem;
  .MuiButton-label {
    white-space: nowrap;
  }
`;

const Links: React.FC = (props) => {
  const { search } = useLocation();
  const { user } = useContext(Auth);

  const addListingSearchParams = new URLSearchParams(search);
  addListingSearchParams.set('add-listing', '');
  const aboutSearchParams = new URLSearchParams(search);
  aboutSearchParams.set('about', '');
  const helpTopicsSearchParams = new URLSearchParams(search);
  helpTopicsSearchParams.set('help-topics', '');
  const notificationsSearchParams = new URLSearchParams(search);
  notificationsSearchParams.set('notification', '');
  const adminNotificationsSearchParams = new URLSearchParams(search);
  adminNotificationsSearchParams.set('admin-notification', '');

  return (
    <Typography {...props} variant="body1" component="div">
      <NavLinks>
        {user?.id && (
          <IconButton
            component={Link}
            to={cleanUrl(`/merchants/${user.id}`)}
            color="primary"
          >
            <Store />
          </IconButton>
        )}
        <Button
          component={Link}
          to={cleanUrl('', addListingSearchParams)}
          variant="contained"
          color="primary"
        >
          Add listing
        </Button>
        <Button
          component={Link}
          to={cleanUrl('', aboutSearchParams)}
          color="primary"
        >
          About
        </Button>
        <Button
          component={Link}
          color="primary"
          to={cleanUrl('', helpTopicsSearchParams)}
        >
          Help
        </Button>
        {user?.id && (
          <Button
            component={Link}
            color="primary"
            to={cleanUrl('', notificationsSearchParams)}
          >
            <NotificationsBellWrapper />
          </Button>
        )}
        {user?.roles.includes(UserRole.ADMIN) && (
          <Button
            component={Link}
            color="secondary"
            to={cleanUrl('', adminNotificationsSearchParams)}
          >
            <AdminNotificationBell />
          </Button>
        )}
        <AccountLinks />
      </NavLinks>
    </Typography>
  );
};

export default Links;
