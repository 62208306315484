import { CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';

const StyledHeader = styled.div`
  margin: ${(p) => p.theme.spacing(0, 3)};
  display: flex;
  flex-direction: 'row';
  justify-content: space-between;
`;

const StyledList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  padding: ${(p) => p.theme.spacing(0.5, 3, 3)};
  margin: 0;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const StyledLoading = styled.div`
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

type Props = {
  title: string;
  seeMore?: React.ReactNode;
  onLoadMore?: () => void;
  hasMore?: boolean;
  loading?: boolean;
};

const ListSlider: React.FC<Props> = (props) => {
  const {
    title,
    seeMore,
    children,
    onLoadMore,
    hasMore,
    loading,
    ...rest
  } = props;

  return (
    <section {...rest}>
      <StyledHeader>
        <Typography variant="h3">{title}</Typography>
        {seeMore}
      </StyledHeader>
      <StyledList>
        {children}
        {hasMore && (
          <li>
            <StyledLoading>
              {!loading && (
                <Waypoint
                  onEnter={() => {
                    onLoadMore && onLoadMore();
                  }}
                  horizontal={true}
                />
              )}
              <CircularProgress />
            </StyledLoading>
          </li>
        )}
      </StyledList>
    </section>
  );
};

export default ListSlider;
