import { useCallback, useEffect, useRef, useState } from 'react';

const useTimeout = (
  callback: () => void,
  timeout: number = 0,
): [
  React.MutableRefObject<boolean | undefined>,
  React.Dispatch<React.SetStateAction<boolean>>,
] => {
  const id = useRef<number>();
  const [_trigger, setTrigger] = useState(false);
  const trigger = useRef<boolean>();
  trigger.current = _trigger;

  const cancel = useCallback(() => {
    const timeoutId = id.current;
    if (timeoutId) {
      id.current = undefined;
      clearTimeout(timeoutId);
    }
  }, [id]);

  useEffect(() => {
    if (trigger.current) {
      id.current = setTimeout(callback, timeout);
    } else {
      cancel();
    }
    return cancel;
  }, [callback, timeout, _trigger, cancel]);

  // return REF and useState setter
  return [trigger, setTrigger];
};

export default useTimeout;
