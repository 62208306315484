import NotificationBell from 'components/NotificationBell';
import { ChatNotifications } from 'contexts/Firebase/ChatNotifications';
import React, { useContext, useEffect, useState } from 'react';

const NotificationsBell: React.FC = () => {
  const { notifications } = useContext(ChatNotifications);
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    if (!notifications) {
      return;
    }
    setNotificationCount(notifications.size);
  }, [notifications]);

  return <NotificationBell count={notificationCount} />;
};

export default NotificationsBell;
