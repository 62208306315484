import React from 'react';
import styled, { css } from 'styled-components';

type StyledProps = {
  backgroundImage: string;
  widthInPx: number;
  heightInPx: number;
};

// Probably need to set line-height normal here and in the listing header
const StyledArticle = styled.article<StyledProps>`
  border-radius: 6px;
  position: relative;
  ${(p) => css`
    width: ${p.widthInPx}px;
    height: ${p.heightInPx}px;
    background-color: ${p.theme.palette.primary.light};
  `}
  ${(p) =>
    p.backgroundImage &&
    css`
      background-image: url(${p.backgroundImage}?ixlib=rb-1.2.1&w=${p.widthInPx}&h=${p.heightInPx}&auto=format&fit=crop&q=0&colorquant=32);
    `}

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 6px;
    background: linear-gradient(
        160deg,
        hsla(0, 0%, 0%, 0) 0%,
        hsla(0, 0%, 0%, 0) 10%,
        hsla(0, 0%, 0%, 0.5) 100%
      ),
      linear-gradient(160deg, hsla(0, 0%, 0%, 0.5) 0%, hsla(0, 0%, 0%, 0) 50%);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.4), 0 1px 2px rgba(0, 0, 0, 0.12);
    transition: box-shadow 0.2s ease-out;
    overflow: hidden;
  }

  &:hover {
    &::before {
      opacity: 1;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.2);
    }
  }
`;

// this will make the content above the gradients
const Content = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

type Props = {
  backgroundImage: string;
  widthInPx?: number;
  heightInPx?: number;
};

const ListSliderTile: React.FC<Props> = (props) => {
  const {
    backgroundImage,
    widthInPx = 288,
    heightInPx = 110,
    children,
    ...rest
  } = props;

  return (
    <StyledArticle
      {...rest}
      backgroundImage={backgroundImage}
      widthInPx={widthInPx}
      heightInPx={heightInPx}
    >
      <Content>{children}</Content>
    </StyledArticle>
  );
};

export default ListSliderTile;
