import { Link } from '@reach/router';
import React from 'react';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  ${(p) => p.theme.typography.body1}
  text-decoration: none;
`;

type Props = {
  to: string;
  children: React.ReactNode;
};

export default function CollectionSeeMore(props: Props): JSX.Element | null {
  return <StyledLink {...props} />;
}
