import * as Sentry from '@sentry/browser';
import Loading from 'components/Loading';
import config from 'configs/Firebase/config';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React, { createContext, useState } from 'react';

type FirebaseContext = {
  isLoading: boolean;
  isLoggedIn: boolean;
};

firebase.initializeApp(config);

export const Firebase = createContext<FirebaseContext>({
  isLoading: true,
  isLoggedIn: false,
});

const Provider: React.FC = (props) => {
  const [context, setContext] = useState<FirebaseContext>({
    isLoading: true,
    isLoggedIn: false,
  });

  if (!context.isLoggedIn) {
    firebase
      .auth()
      .signInAnonymously()
      .then(() => {
        setContext({ isLoading: false, isLoggedIn: true });
      })
      .catch((error: any) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;

        Sentry.captureException(
          `Firebase error: ${errorCode}\n${errorMessage}`,
        );
        setContext({ isLoading: false, isLoggedIn: false });
      });
  }

  if (context.isLoading) {
    return <Loading />;
  }

  return (
    <Firebase.Provider value={context}>{props.children}</Firebase.Provider>
  );
};

export default Provider;
