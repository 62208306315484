import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import Contact from './Contact';
import Legal from './Legal';
import Privacy from './Privacy';

const Menu = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  & a {
    display: inline-flex;
    align-items: center;
    padding: 0 8px;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    color: ${(p) => p.theme.palette.secondary.main};
  }
  & button {
    font-size: 16px;
    font-weight: bold;
    color: ${(p) => p.theme.palette.secondary.main};
    cursor: pointer;
    border: none;
  }
`;

const Links: React.FC = (props) => {
  return (
    <Typography {...props} variant="body1" component="div">
      <Menu>
        <Legal />
        <Privacy />
        <Contact />
      </Menu>
    </Typography>
  );
};

export default Links;
