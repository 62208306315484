import { Button, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SearchIcon from '@material-ui/icons/Search';
import StoreIcon from '@material-ui/icons/Store';
import { Link, useLocation } from '@reach/router';
import { Auth } from 'contexts/Auth';
import React, { useContext } from 'react';
import styled from 'styled-components';
import cleanUrl from 'utils/clean-url';
import MobileSearch from './MobileSearch';

const StyledNav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

const NavButton = styled<any>(Button)`
  && {
    height: 4.25rem;
    margin-bottom: 1rem;
  }

  & .label {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: ${(p) => p.theme.palette.text.secondary};

    & span {
      margin-top: 4px;
      text-transform: uppercase;
      ${(p) => p.theme.typography.fontFamily}
      font-weight: 700;
      font-size: 9px;
      color: ${(p) => p.theme.palette.secondary.main};
    }
  }
`;

const Menu = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;

  & li {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
`;

const MobileLinks: React.FC = (props) => {
  const [state, setState] = React.useState({
    showSearch: false,
  });

  const { search } = useLocation();
  const { user } = useContext(Auth);

  const helpTopicsSearchParams = new URLSearchParams(search);
  helpTopicsSearchParams.set('help-topics', '');

  return (
    <Typography {...props} variant="h3" component="div">
      {state.showSearch && (
        <StyledNav>
          <MobileSearch
            onSearch={() => {
              setState({ showSearch: false });
            }}
          ></MobileSearch>
        </StyledNav>
      )}
      <Menu>
        {user?.id && (
          <li>
            <NavButton
              component={Link}
              to={cleanUrl(`/merchants/${user.id}`)}
              classes={{ label: 'label' }}
            >
              <StoreIcon className="icon" />
              <span>Store</span>
            </NavButton>
          </li>
        )}
        <li>
          <NavButton
            component={Link}
            to="?add-listing"
            classes={{ label: 'label' }}
          >
            <AddCircleOutlineIcon className="icon" />
            <span>Add</span>
          </NavButton>
        </li>
        <li>
          <NavButton
            classes={{ label: 'label' }}
            onClick={() => {
              setState((prevState) => ({
                showSearch: !prevState.showSearch,
              }));
            }}
          >
            <SearchIcon className="icon" />
            <span>Search</span>
          </NavButton>
        </li>
        <li>
          <NavButton
            component={Link}
            to="?account-details"
            classes={{ label: 'label' }}
          >
            <AccountCircleIcon className="icon" />
            <span>Account</span>
          </NavButton>
        </li>
        <li>
          <NavButton
            component={Link}
            to={cleanUrl('', helpTopicsSearchParams)}
            classes={{ label: 'label' }}
          >
            <HelpOutlineIcon className="icon" />
            <span>Help</span>
          </NavButton>
        </li>
      </Menu>
    </Typography>
  );
};

export default MobileLinks;
