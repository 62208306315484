import type { AlertProps } from 'components/Alert';
import Alert from 'components/Alert';
import React, { createContext, useCallback, useState } from 'react';

type ToastKey = number;

type ToastContext = {
  addToast: (alertProps: AlertProps) => ToastKey;
  removeToast: (key: ToastKey) => void;
};

type ToastQueue = Array<{
  key: number;
  alertProps: AlertProps;
}>;

export const Toasts = createContext<ToastContext>({
  addToast: () => 0,
  removeToast: () => {},
});

const ToastsProvider: React.FC = (props) => {
  const [toasts, setToasts] = useState<ToastQueue>([]);

  const addToast = useCallback(
    (alertProps: AlertProps): ToastKey => {
      const key = new Date().getTime() + Math.random();

      setToasts((prevState) => [...prevState, { key, alertProps }]);

      return key;
    },
    [setToasts],
  );
  const removeToast = useCallback(
    (key: ToastKey) => {
      setToasts((prevState) => prevState.filter((toast) => toast.key !== key));
    },
    [setToasts],
  );

  const [context] = useState<ToastContext>({
    addToast,
    removeToast,
  });

  return (
    <Toasts.Provider value={context}>
      <>
        {props.children}
        {toasts.map(({ key, alertProps }) => (
          <Alert
            {...alertProps}
            open={true}
            key={key}
            onClose={() => {
              removeToast(key);
              if (alertProps.onClose) alertProps.onClose();
            }}
          />
        ))}
      </>
    </Toasts.Provider>
  );
};

export default ToastsProvider;
