import Loading from 'components/Loading';
import { Auth } from 'contexts/Auth';
import { Firebase } from 'contexts/Firebase';
import firebase from 'firebase/app';
import 'firebase/firestore';
import type { CurrentUserQuery } from 'generated-types';
import React, { createContext, useContext, useEffect, useState } from 'react';

type ChatNotificationsContext = {
  notifications?: any;
  currentChatId?: string;
};

type ProviderProps = {
  user?: CurrentUserQuery['me'] | null;
  isLoggedIn?: boolean;
};

export const ChatNotifications = createContext<ChatNotificationsContext>({});

const Provider: React.FC<ProviderProps> = ({ children, user, isLoggedIn }) => {
  const [context, setContext] = useState<ChatNotificationsContext>({
    notifications: null,
  });

  useEffect(() => {
    if (!user?.id) {
      return;
    }
    if (!isLoggedIn) {
      return;
    }
    const fsNotificationCollection = firebase.firestore().collection(user.id);

    const onSnapshotCallback = (querySnapshot: any) => {
      setContext({ notifications: querySnapshot });
    };

    const unsub = fsNotificationCollection.onSnapshot(onSnapshotCallback);
    return () => {
      unsub();
    };
  }, [user, isLoggedIn]);

  return (
    <ChatNotifications.Provider value={context}>
      {children}
    </ChatNotifications.Provider>
  );
};

const ProviderWrapper: React.FC = ({ children }) => {
  const { isLoading, isLoggedIn } = useContext(Firebase);
  const { user } = useContext(Auth);
  if (isLoading) {
    return <Loading />;
  }
  return (
    <Provider user={user} isLoggedIn={isLoggedIn}>
      {children}
    </Provider>
  );
};

export default ProviderWrapper;
