import { Auth } from 'contexts/Auth';
import type { OfferListCounterPartyFragment } from 'generated-types';
import React, { useContext } from 'react';

const listingTypeLabelMap = {
  ForSaleListing: 'Purchase Offer',
  WantedListing: 'Fulfillment Offer',
  TransportationListing: 'Transport Offer',
};

type Props = {
  offer: OfferListCounterPartyFragment;
};

const OfferListCounterParty: React.FC<Props> = ({ offer }) => {
  const { user } = useContext(Auth);
  const { listing } = offer;

  // we should always be authenticated at this point...
  if (!user) {
    throw new Error('In OfferListCounterParty rendering and not Authenticated');
  }

  const offerIsFromUser = offer.fromUser.id === user.id;
  const counterParty = offerIsFromUser ? offer.toUser : offer.fromUser;
  const listingIsFromPoster = listing.postedBy.id === user.id;

  return (
    <>
      <strong>{listingTypeLabelMap[listing.__typename]}</strong>{' '}
      {listingIsFromPoster ? 'from' : 'to'} {counterParty.displayName}
    </>
  );
};

export default OfferListCounterParty;
