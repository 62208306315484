const firebaseConfig = {
  apiKey: 'AIzaSyDxzj8AHdLAGu0cfE6hKauXhdwaZEoISYE',
  authDomain: 'supplycaravan-production.firebaseapp.com',
  projectId: 'supplycaravan-production',
  storageBucket: 'supplycaravan-production.appspot.com',
  messagingSenderId: '351314759664',
  appId: '1:351314759664:web:28f6892b30f67483cdd735',
};

export default firebaseConfig;
