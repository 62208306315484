import { gql, useQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import ListSlider from 'components/ListSlider';
import ListSliderItem from 'components/ListSlider/Item';
import Loading from 'components/Loading';
import OfferListSliderItem from 'components/Offer/ListSliderItem';
import { OfferListSliderItemFragment } from 'components/Offer/ListSliderItem/fragments';
import type { OffersQuery, OffersQueryVariables } from 'generated-types';
import React, { useCallback, useState } from 'react';

const OFFERS = gql`
  query OffersQuery(
    $after: String
    $proximity: Int
    $counterpartyIdOrSlug: String
    $availabilityStart: Date
    $availabilityEnd: Date
  ) {
    offers(
      query: { stages: [PENDING], counterpartyIdOrSlug: $counterpartyIdOrSlug }
      first: 10
      after: $after
      filter: {
        proximity: $proximity
        availabilityStart: $availabilityStart
        availabilityEnd: $availabilityEnd
      }
    ) {
      nodes {
        id
        ...OfferListSliderItemFragment
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${OfferListSliderItemFragment}
`;

type Props = {
  proximity?: number;
  counterpartyIdOrSlug?: string;
  availabilityRange?: {
    start?: string;
    end?: string;
  };
};

const OffersContainer: React.FC<Props> = ({
  proximity,
  counterpartyIdOrSlug,
  availabilityRange,
}) => {
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const { loading, error, data, fetchMore } = useQuery<
    OffersQuery,
    OffersQueryVariables
  >(OFFERS, {
    variables: {
      proximity,
      counterpartyIdOrSlug,
      availabilityStart: availabilityRange?.start,
      availabilityEnd: availabilityRange?.end,
    },
  });

  const loadMoreHandler = useCallback(() => {
    if (!data) {
      throw new Error('Trying to load more without current data');
    }
    if (fetchMoreLoading) {
      throw new Error(
        'Trying to load next page before current page finishes loading',
      );
    }

    setFetchMoreLoading(true);

    fetchMore({
      variables: {
        after: data.offers.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setFetchMoreLoading(false);

        if (!fetchMoreResult) return prev;

        return {
          offers: {
            __typename: prev.offers.__typename,
            nodes: [...prev.offers.nodes, ...fetchMoreResult.offers.nodes],
            pageInfo: fetchMoreResult.offers.pageInfo,
            totalCount: prev.offers.totalCount,
          },
        };
      },
    });
  }, [fetchMore, fetchMoreLoading, data]);

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <Typography color="error">Unable to load Offers.</Typography>;
  }

  if (data.offers.totalCount === 0) {
    // No pending offers
    return null;
  }

  return (
    <ListSlider
      title={`Offers (${data.offers.totalCount})`}
      onLoadMore={loadMoreHandler}
      hasMore={data.offers.pageInfo.hasNextPage}
      loading={fetchMoreLoading}
    >
      {data.offers.nodes.map((item) => (
        <ListSliderItem key={item.id}>
          <OfferListSliderItem offer={item} />
        </ListSliderItem>
      ))}
    </ListSlider>
  );
};

export default OffersContainer;
