import {
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
} from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import FacetsDialog from 'components/Facets/Dialog';
import { SearchParametersContext } from 'contexts/SearchParameters';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledSearchForm = styled.form`
  position: relative;
  width: 100%;
  height: 42px;
  background-color: white;
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
`;

const StyledFormControl = styled(FormControl)`
  ${(p) => p.theme.typography.fontFamily}
  font-size: 0.875em;
  height: 100%;
  border-radius: 5.25em;
  box-shadow: inset 0 0 0 1px rgb(235, 235, 235);
  border: none;
  padding: 0 1em;
  outline: none;
  transition: all 0.1s;

  &:focus {
    box-shadow: inset 0 0 0 2px ${(p) => p.theme.palette.primary.dark};
  }
`;

const StyledInput = styled(Input)`
  background-color: transparent;
  width: 100%;
  height: 100%;

  &:before,
  &:after {
    display: none;
  }
`;

type Props = {
  onSearch?: () => void;
};

const MobileSearch: React.FC<Props> = (props) => {
  const [text, setText] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const { parameters, setParameters } = useContext(SearchParametersContext);

  // Initial settings from context, watch changes
  useEffect(() => {
    setSubmitted(false);
    if (parameters.q) {
      setText(parameters.q);
      setShowClose(true);
    }
  }, [parameters.q]);

  // When submit triggered, issue callback prop
  useEffect(() => {
    if (submitted && props.onSearch) {
      props.onSearch();
    }
  }, [submitted, props]);

  // When submit fired, update params and navigate
  const handleSubmit = useCallback(
    (event: React.SyntheticEvent | null) => {
      if (event) event.preventDefault();
      setSubmitted(true);
      setParameters({
        navigate: '/',
        ...parameters,
        q: text,
      });
    },
    [text, parameters, setParameters],
  );

  // Value change event
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setText(e.target.value);
      setShowClose(e.target.value.length > 0);
    },
    [setShowClose, setText],
  );

  // Check for ENTER key to trigger submit
  const handleCheckSubmit = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.keyCode === 13) {
        handleSubmit(null);
      }
    },
    [handleSubmit],
  );

  // Reset the search parameters and component state
  const resetSearchParameters = useCallback(() => {
    setText('');
    setShowClose(false);
    setParameters({
      navigate: '/',
      ...parameters,
      q: undefined,
      filters: undefined,
    });
  }, [setShowClose, setText, parameters, setParameters]);

  return (
    <StyledSearchForm method="POST" onSubmit={handleSubmit}>
      <StyledFormControl fullWidth={true} variant="filled">
        <StyledInput
          name="q"
          type={'text'}
          placeholder={'Search marketplace for...'}
          value={text}
          onChange={handleChange}
          onKeyDown={handleCheckSubmit}
          endAdornment={
            <InputAdornment position="end">
              <IconButton component={Button} onClick={resetSearchParameters}>
                <Close
                  visibility={showClose ? 'visible' : 'hidden'}
                  fontSize="small"
                />
              </IconButton>
              <IconButton component={Button} onClick={handleSubmit}>
                <Search fontSize="small" />
              </IconButton>
              <FacetsDialog />
            </InputAdornment>
          }
        />
      </StyledFormControl>
    </StyledSearchForm>
  );
};

export default MobileSearch;
