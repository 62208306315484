import type { BigDecimal } from 'graphql-custom-scalars';
import type { Currency } from 'graphql-custom-scalars';
import type { Date } from 'graphql-custom-scalars';
import type { DateTime } from 'graphql-custom-scalars';
import type { Upload } from 'graphql-custom-scalars';
import type { Coordinates } from 'graphql-custom-scalars';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Decimals passed as strings to avoid precision loss */
  BigDecimal: BigDecimal;
  /** Currency code in ISO 4217 format */
  Currency: Currency;
  /** Date in ISO 8601 - formatted in YYYY-mm-dd */
  Date: Date;
  /** DateTime in ISO 8601 - formatted in YYYY-mm-ddTHH:mm:ss.mmmZ */
  DateTime: DateTime;
  /** The `Upload` scalar type represents a file upload. */
  Upload: Upload;
  /** Coordinates as [longitude, latitude] */
  Coordinates: Coordinates;
};







export type PingPayload = {
  __typename?: 'PingPayload';
  success: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  ping: PingPayload;
  me: Maybe<User>;
  /** Business Classifications */
  businessClassifications: BusinessClassificationsPayload;
  /**
   * To use during sign-up or password update to check if password policy is
   * valid. This is helper query is useful to do validation before submission.
   */
  validatePasswordStrength: Maybe<Scalars['Boolean']>;
  /** List of all users */
  users: UsersPayload;
  /** Retrieve chat info for a user */
  userById: Maybe<User>;
  /** Merchant information (normally used to show special user's page) */
  merchant: Merchant;
  /** List of user's Carts */
  carts: CartsConnection;
  /** Get cart by id */
  cart: Cart;
  /** List of all categories */
  categories: CategoriesPayload;
  /** List of all listings */
  listings: ListingsConnection;
  /** Get listing by id */
  listing: Listing;
  /** List of all user's offers */
  offers: OffersConnection;
  /** Get offer by id */
  offer: Offer;
  /** List of all user's orders */
  orders: OrdersConnection;
  /** Get order by id */
  order: Order;
  /** Get order item by id */
  orderItem: WholeSaleOrderItem;
  /** List of all products */
  products: ProductsPayload;
  /** Query tags */
  tags: TagsConnection;
  /**
   * Get transfer by id
   * Only users related with this transaction can see it.
   */
  transaction: Transaction;
  /** Select a single chat channel from a sender and recipient */
  chatChannel: ChatChannel;
  /** Select a single chat channel from an id */
  chatChannelById: ChatChannel;
  /** Select an admin chat channel from an id */
  adminChatChannelById: ChatChannel;
  /** Select a list of chat channels from a context */
  chatChannelsByContext: Array<ChatChannel>;
  /** Select the list of HELP chat channels for an admin */
  adminHelpChatChannels: Array<ChatChannel>;
  /** Select a single chat channel for non-logged in help chats */
  noAuthHelpChatChannel: NoAuthChatChannelAndIdentifier;
  /** Select chat channel info for a list of chat channels */
  decoratedChatChannelsByIds: Array<DecoratedChatChannels>;
};


export type QueryvalidatePasswordStrengthArgs = {
  password: Scalars['String'];
};


export type QueryusersArgs = {
  first?: Maybe<Scalars['Int']>;
  sortField?: Maybe<UserSortFieldEnum>;
  sortDirection?: Maybe<UserSortDirectionEnum>;
  query?: Maybe<UsersQueryFilter>;
};


export type QueryuserByIdArgs = {
  id: Scalars['String'];
};


export type QuerymerchantArgs = {
  idOrSlug?: Maybe<Scalars['String']>;
};


export type QuerycartsArgs = {
  query?: Maybe<CartsSearchQuery>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QuerycartArgs = {
  id: Scalars['ID'];
};


export type QuerylistingsArgs = {
  query?: Maybe<ListingSearchQuery>;
  filter?: Maybe<ListingQueryFilter>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QuerylistingArgs = {
  id: Scalars['ID'];
};


export type QueryoffersArgs = {
  query?: Maybe<OfferSearchQuery>;
  filter?: Maybe<ListingQueryFilter>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryofferArgs = {
  id: Scalars['ID'];
};


export type QueryordersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  query?: Maybe<OrdersQueryFilter>;
};


export type QueryorderArgs = {
  id: Scalars['ID'];
};


export type QueryorderItemArgs = {
  id: Scalars['ID'];
};


export type QueryproductsArgs = {
  query?: Maybe<ProductSearchQuery>;
  first?: Maybe<Scalars['Int']>;
};


export type QuerytagsArgs = {
  query?: Maybe<TagsSearchQuery>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QuerytransactionArgs = {
  id: Scalars['ID'];
};


export type QuerychatChannelArgs = {
  query: ChatChannelSearchQuery;
  first?: Maybe<Scalars['Int']>;
};


export type QuerychatChannelByIdArgs = {
  id: Scalars['String'];
};


export type QueryadminChatChannelByIdArgs = {
  id: Scalars['String'];
};


export type QuerychatChannelsByContextArgs = {
  context: ChatContextInput;
};


export type QuerydecoratedChatChannelsByIdsArgs = {
  ids: Array<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  ping: PingPayload;
  login: User;
  logout: Scalars['Boolean'];
  loginAs: User;
  generateToken: Scalars['String'];
  emailProcessQueue: Scalars['Boolean'];
  dwollaProcessQueue: Scalars['Boolean'];
  /** Get an IAV token from Dwolla for UI flow */
  createDwollaIAVToken: CreateDwollaIAVTokenPayload;
  /**
   * Removes a User's Dwolla Funding Source.
   * This requires the owner of the Funding Source to be logged in.
   */
  removeDwollaFundingSource: Maybe<Scalars['Boolean']>;
  /** Upload a document for Dwolla Customer's validation */
  dwollaCustomerDocumentUpload: DwollaCustomerDocument;
  /**
   * Initiate transfer between user's accounts using Dwolla funding sources.
   * Both source and destination need to be valid ids from user's funding
   * sources.
   */
  initiateDwollaTransfer: InitiateDwollaTransferPayload;
  /** Beneficial Owners (>=25% ownership) listings are required by federal law. */
  dwollaAddBeneficialOwner: DwollaBeneficialOwner;
  /** Register a User in the system */
  registerUser: User;
  /**
   * Update a User in the system
   * This can only be used by the logged in user
   */
  updateUserInfo: User;
  /**
   * Validate Email of a given user
   * This requires the user to be logged in
   */
  verifyEmailUser: Scalars['Boolean'];
  /**
   * Approve User in the system to allow them to take critical actions, such as
   * add listings and make offers.
   */
  approveUser: User;
  /**
   * Admin only delete a user in the system
   * This can only be used by the admin
   */
  deleteUser: Scalars['Boolean'];
  /** Creates a forgot password request */
  createForgotPasswordRequest: Scalars['Boolean'];
  /** Resets a user's password */
  resetPassword: Scalars['Boolean'];
  /** Add WholeSale Listing to a Cart */
  addListingToCart: Cart;
  /** Removes Items from Cart */
  removeFromCart: Cart;
  /** Requests a Cart to be accepted for Checkout */
  requestCartCheckout: Cart;
  /** Accepts a Cart checkout */
  acceptCartCheckout: Cart;
  /** Declines a Cart checkout */
  declineCartCheckout: Cart;
  /** Update a Cart Item */
  updateCartItem: CartItem;
  /** Generate demo data for Categories */
  generateDemoCategories: Maybe<Scalars['Boolean']>;
  /**
   * Add new listings
   * @deprecated Use addForSaleListing.
   */
  addListing: Listing;
  addForSaleListing: ForSaleListing;
  addWantedListing: WantedListing;
  addTransportationListing: TransportationListing;
  addWholeSaleListing: WholeSaleListing;
  /** Unpublishes a listing. Only owner of this listing can take this action. */
  unpublishListing: Listing;
  /** Update a listing. Only owner of this listing can take this action. */
  updateWholeSaleListing: WholeSaleListing;
  /** Make an offer to a user of a listing */
  makeOffer: Maybe<Offer>;
  /** Make a FullPrice offer to a user of a listing */
  makeFullPriceOffer: Maybe<FullPriceOffer>;
  /**
   * Accept an offer that is in Pending stage.
   * Only the user that received the offer can take this action.
   */
  acceptOffer: Maybe<Offer>;
  /**
   * Decline an offer that is in Pending stage.
   * Only the user that received the offer can take this action.
   */
  declineOffer: Maybe<Offer>;
  /**
   * Cancel an offer.
   * Only the user that sent the offer can take this action.
   */
  cancelOffer: Maybe<Offer>;
  /**
   * Counter an offer that is in Pending stage. Returns a new Offer with a link
   * to the previous offer.
   * Only the user that received the offer can take this action.
   */
  counterOffer: Maybe<Offer>;
  /** Counter for a FullPriceOffer */
  counterFullPriceOffer: Maybe<FullPriceOffer>;
  /**
   * Fulfill an order.
   * Only the seller of this order can take this action.
   */
  fulfillOrder: Maybe<Order>;
  /** Archives an order for a particular user for a particular order. */
  archiveOrder: Maybe<Order>;
  /** Un-archives an order for a particular user for a particular order. */
  unarchiveOrder: Maybe<Order>;
  /** Update the price of an Order Item */
  updateOrderItem: WholeSaleOrderItem;
  /** Update an Order */
  updateOrder: Order;
  /**
   * Sync Product data with DB. This adds new products that don't exist and
   * updates existing records.
   */
  syncProductData: Maybe<Scalars['Boolean']>;
  /**
   * Sync Tags data with DB.
   * This adds new tags that don't exist and updates existing records.
   */
  syncTagData: Maybe<Scalars['Boolean']>;
  /**
   * Make a payment on an accepted offer.
   * Only the user that is the buyer can take this action.
   *
   * The 'fundingSourceId' is optional, if not provided, it will use the default
   * funding source of the user (dwolla balance funding source).
   */
  makePayment: Transaction;
  /**
   * Mark an accepted-offer as paid off platform.
   * Only the user that is the buyer can take this action.
   */
  makePaymentOffPlatform: Transaction;
  /**
   * Confirm that a payment off platform was made.
   * Only the user that is the seller can take this action.
   */
  confirmOffPlatformPayment: Transaction;
  /** Set a chat channel as active */
  setChatChannelActive: Maybe<Scalars['Boolean']>;
  /** Set Order's Rating */
  setRating: Maybe<Rating>;
  /** Upload an avatar file as the currently logged in user */
  uploadAvatar: Media;
};


export type MutationloginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};


export type MutationloginAsArgs = {
  id: Scalars['ID'];
};


export type MutationgenerateTokenArgs = {
  description: Scalars['String'];
};


export type MutationremoveDwollaFundingSourceArgs = {
  id: Scalars['ID'];
};


export type MutationdwollaCustomerDocumentUploadArgs = {
  input: DwollaCustomerDocumentUploadInput;
};


export type MutationinitiateDwollaTransferArgs = {
  input: InitiateDwollaTransferInput;
};


export type MutationdwollaAddBeneficialOwnerArgs = {
  input: AddDwollaBeneficialOwnerInput;
};


export type MutationregisterUserArgs = {
  input: RegisterUserInput;
};


export type MutationupdateUserInfoArgs = {
  input: UpdateUserInfoInput;
};


export type MutationverifyEmailUserArgs = {
  token: Scalars['String'];
};


export type MutationapproveUserArgs = {
  id: Scalars['ID'];
};


export type MutationdeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationcreateForgotPasswordRequestArgs = {
  email: Scalars['String'];
};


export type MutationresetPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  key: Scalars['String'];
};


export type MutationaddListingToCartArgs = {
  input: AddListingToCartInput;
};


export type MutationremoveFromCartArgs = {
  input: RemoveFromCartInput;
};


export type MutationrequestCartCheckoutArgs = {
  input: RequestCartCheckoutInput;
};


export type MutationacceptCartCheckoutArgs = {
  input: AcceptCartCheckoutInput;
};


export type MutationdeclineCartCheckoutArgs = {
  input: DeclineCartCheckoutInput;
};


export type MutationupdateCartItemArgs = {
  input: UpdateCartItemInput;
};


export type MutationaddListingArgs = {
  input: AddListingInput;
};


export type MutationaddForSaleListingArgs = {
  input: AddForSaleListingInput;
};


export type MutationaddWantedListingArgs = {
  input: AddWantedListingInput;
};


export type MutationaddTransportationListingArgs = {
  input: AddTransportationListingInput;
};


export type MutationaddWholeSaleListingArgs = {
  input: AddWholeSaleListingInput;
};


export type MutationunpublishListingArgs = {
  input: UnpublishListingInput;
};


export type MutationupdateWholeSaleListingArgs = {
  input: UpdateWholeSaleListingInput;
};


export type MutationmakeOfferArgs = {
  input: MakeOfferInput;
};


export type MutationmakeFullPriceOfferArgs = {
  input: MakeFullPriceOfferInput;
};


export type MutationacceptOfferArgs = {
  id: Scalars['ID'];
};


export type MutationdeclineOfferArgs = {
  id: Scalars['ID'];
};


export type MutationcancelOfferArgs = {
  id: Scalars['ID'];
};


export type MutationcounterOfferArgs = {
  input: CounterOfferInput;
};


export type MutationcounterFullPriceOfferArgs = {
  input: CounterFullPriceOfferInput;
};


export type MutationfulfillOrderArgs = {
  id: Scalars['ID'];
};


export type MutationarchiveOrderArgs = {
  orderId: Scalars['ID'];
};


export type MutationunarchiveOrderArgs = {
  orderId: Scalars['ID'];
};


export type MutationupdateOrderItemArgs = {
  input: UpdateOrderItemInput;
};


export type MutationupdateOrderArgs = {
  input: UpdateOrderInput;
};


export type MutationsyncProductDataArgs = {
  token: Scalars['String'];
};


export type MutationsyncTagDataArgs = {
  token: Scalars['String'];
};


export type MutationmakePaymentArgs = {
  orderId: Scalars['ID'];
  fundingSourceId?: Maybe<Scalars['ID']>;
};


export type MutationmakePaymentOffPlatformArgs = {
  orderId: Scalars['ID'];
};


export type MutationconfirmOffPlatformPaymentArgs = {
  orderId: Scalars['ID'];
};


export type MutationsetChatChannelActiveArgs = {
  chatChannelId: Scalars['ID'];
};


export type MutationsetRatingArgs = {
  input: RatingInput;
};


export type MutationuploadAvatarArgs = {
  file: Scalars['Upload'];
};

export type Subscription = {
  __typename?: 'Subscription';
  ping: PingPayload;
};

/** Pagination based on Cursor Connections */
export type PageInfo = {
  __typename?: 'PageInfo';
  hasPreviousPage: Scalars['Boolean'];
  hasNextPage: Scalars['Boolean'];
  startCursor: Maybe<Scalars['String']>;
  endCursor: Maybe<Scalars['String']>;
};

/** Address States */
export enum AddressState {
  AL = 'AL',
  AK = 'AK',
  AS = 'AS',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  DC = 'DC',
  FM = 'FM',
  FL = 'FL',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MH = 'MH',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  MP = 'MP',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PW = 'PW',
  PA = 'PA',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VI = 'VI',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY'
}


/** GeoJSON Geometry point */
export type PointGeometry = {
  __typename?: 'PointGeometry';
  type: Scalars['String'];
  coordinates: Scalars['Coordinates'];
};

export type Address = {
  __typename?: 'Address';
  id: Scalars['ID'];
  street: Scalars['String'];
  city: Scalars['String'];
  state: AddressState;
  postalCode: Scalars['String'];
  location: PointGeometry;
};

export type PointGeometryInput = {
  type: Scalars['String'];
  coordinates: Scalars['Coordinates'];
};

export type AddressInput = {
  street: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  location?: Maybe<PointGeometryInput>;
};

export type IndustryClassification = {
  __typename?: 'IndustryClassification';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BusinessClassification = {
  __typename?: 'BusinessClassification';
  id: Scalars['ID'];
  name: Scalars['String'];
  industries: Array<IndustryClassification>;
};

/** Payload for businessClassifications query */
export type BusinessClassificationsPayload = {
  __typename?: 'BusinessClassificationsPayload';
  results: Array<BusinessClassification>;
  count: Scalars['Int'];
};

/** Payload for createDwollaIAVToken mutation */
export type CreateDwollaIAVTokenPayload = {
  __typename?: 'CreateDwollaIAVTokenPayload';
  token: Scalars['String'];
};

export type DwollaFundingSource = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DwollaBankFundingSource = DwollaFundingSource & {
  __typename?: 'DwollaBankFundingSource';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Dwolla Balance definition */
export type DwollaBalance = {
  __typename?: 'DwollaBalance';
  value: Scalars['BigDecimal'];
  currency: Scalars['Currency'];
};

export type DwollaBalanceFundingSource = DwollaFundingSource & {
  __typename?: 'DwollaBalanceFundingSource';
  id: Scalars['ID'];
  name: Scalars['String'];
  balance: DwollaBalance;
};

/** Dwolla Customer Status */
export enum DwollaCustomerStatus {
  UNVERIFIED = 'UNVERIFIED',
  RETRY = 'RETRY',
  KBA = 'KBA',
  DOCUMENT = 'DOCUMENT',
  VERIFIED = 'VERIFIED',
  SUSPENDED = 'SUSPENDED',
  DEACTIVATED = 'DEACTIVATED'
}

/** Dwolla Customer Entitlements available for DwollaCustomer */
export type DwollaCustomerEntitlements = {
  __typename?: 'DwollaCustomerEntitlements';
  id: Scalars['ID'];
  /** Can this Dwolla Customer receive payments? */
  canReceivePayments: Scalars['Boolean'];
  /**
   * Does this Dwolla Customer need to verify the owner or controller of the
   * business with a document?
   */
  needToVerifyWithDocument: Scalars['Boolean'];
  /** Does this Dwolla Customer need to verify the business with a document? */
  needToVerifyBusinessWithDocument: Scalars['Boolean'];
};

export type DwollaCustomer = {
  __typename?: 'DwollaCustomer';
  id: Scalars['ID'];
  status: Maybe<DwollaCustomerStatus>;
  fundingSources: Array<DwollaFundingSource>;
  entitlements: Maybe<DwollaCustomerEntitlements>;
};

export type DwollaControllerAddress = {
  __typename?: 'DwollaControllerAddress';
  address1: Scalars['String'];
  address2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateProvinceRegion: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
};

export type DwollaController = {
  __typename?: 'DwollaController';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  address: DwollaControllerAddress;
  title: Scalars['String'];
  dateOfBirth: Scalars['String'];
  ssn4: Scalars['String'];
};

export type DwollaControllerAddressInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateProvinceRegion: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
};

export type DwollaControllerInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  address: DwollaControllerAddressInput;
  title: Scalars['String'];
  dateOfBirth: Scalars['String'];
  ssn4: Scalars['String'];
};

/** Dwolla Customer Document Type */
export enum DwollaCustomerDocumentType {
  PASSPORT = 'PASSPORT',
  LICENSE = 'LICENSE',
  ID_CARD = 'ID_CARD',
  OTHER = 'OTHER'
}

/** Dwolla Customer Document Status */
export enum DwollaCustomerDocumentStatus {
  PENDING = 'PENDING',
  REVIEWED = 'REVIEWED'
}

export type DwollaCustomerDocument = {
  __typename?: 'DwollaCustomerDocument';
  id: Scalars['ID'];
  status: DwollaCustomerDocumentStatus;
};

export type DwollaCustomerDocumentUploadInput = {
  documentType: DwollaCustomerDocumentType;
  file: Scalars['Upload'];
};

export enum DwollaTransferStatus {
  PENDING = 'PENDING',
  PROCESSED = 'PROCESSED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED'
}

/** Dwolla Transfer amount */
export type DwollaTransferAmount = {
  __typename?: 'DwollaTransferAmount';
  value: Scalars['BigDecimal'];
  currency: Scalars['Currency'];
};

/** Dwolla Transfer amount input */
export type DwollaTransferAmountInput = {
  value: Scalars['BigDecimal'];
  currency: Scalars['Currency'];
};

/** Initiate transfer input to be used by initiateDwollaTransfer mutation */
export type InitiateDwollaTransferInput = {
  source: Scalars['ID'];
  destination: Scalars['ID'];
  amount: DwollaTransferAmountInput;
};

export type InitiateDwollaTransferPayload = {
  __typename?: 'InitiateDwollaTransferPayload';
  id: Scalars['ID'];
  status: DwollaTransferStatus;
  amount: DwollaTransferAmount;
};

export type DwollaBeneficialOwnerAddress = {
  __typename?: 'DwollaBeneficialOwnerAddress';
  address1: Scalars['String'];
  address2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateProvinceRegion: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
};

export type DwollaBeneficialOwner = {
  __typename?: 'DwollaBeneficialOwner';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  address: DwollaBeneficialOwnerAddress;
  dateOfBirth: Scalars['String'];
};

export type DwollaBeneficialOwnerAddressInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  stateProvinceRegion: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
};

/** Beneficial Owner details to send to Dwolla */
export type AddDwollaBeneficialOwnerInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  address: DwollaBeneficialOwnerAddressInput;
  dateOfBirth: Scalars['String'];
  ssn: Scalars['String'];
};

/** Business Types */
export enum BusinessType {
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
  CORPORATION = 'CORPORATION',
  LLC = 'LLC',
  PARTNERSHIP = 'PARTNERSHIP'
}

/** User Roles */
export enum UserRole {
  ADMIN = 'ADMIN'
}

/** Entitlements available for User */
export enum UserEntitlement {
  CAN_ADD_LISTING = 'CAN_ADD_LISTING',
  CAN_PUBLISH_LISTING = 'CAN_PUBLISH_LISTING',
  CAN_MANAGE_USERS = 'CAN_MANAGE_USERS',
  CAN_APPROVE_USERS = 'CAN_APPROVE_USERS',
  CAN_DELETE_USERS = 'CAN_DELETE_USERS',
  CAN_LOGIN_AS_USERS = 'CAN_LOGIN_AS_USERS',
  CAN_SYNC_DATA = 'CAN_SYNC_DATA'
}

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  businessName: Scalars['String'];
  displayName: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phone: Scalars['String'];
  roles: Array<UserRole>;
  operationId: Maybe<Scalars['String']>;
  federalShellfishNumber: Maybe<Scalars['String']>;
  /** The entitlements that current user has in this User. */
  entitlements: Array<UserEntitlement>;
  /**
   * Is the User approved to take critical actions, such as add listings or make
   * offers.
   * @deprecated Use 'approvedAt'.
   */
  isApproved: Scalars['Boolean'];
  /**
   * Is the User approved to take critical actions, such as add listings or make
   * offers.
   */
  approvedAt: Maybe<Scalars['Date']>;
  billingAddress: Address;
  shippingAddress: Address;
  businessType: Maybe<BusinessType>;
  businessDescription: Maybe<Scalars['String']>;
  businessTradeName: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  /** Terms applied to new listings by default */
  defaultTerms: Maybe<Scalars['String']>;
  industryClassification: Maybe<IndustryClassification>;
  /**
   * Employer Identification Number (defined always when businessType is
   * CORPORATION, LLC or PARTNERSHIP)
   */
  ein: Maybe<Scalars['String']>;
  /** Date of birth (required to open a Dwolla account) */
  dateOfBirth: Maybe<Scalars['Date']>;
  /**
   * Last 4 digits of the Social Security Number (required to open a Dwolla
   * account)
   */
  ssn4: Maybe<Scalars['String']>;
  avatar: Maybe<Media>;
  merchantBanner: Maybe<Media>;
  /**
   * Flag to indicate if the user has been deleted
   * @deprecated Use 'deletedAt'.
   */
  isDeleted: Scalars['Boolean'];
  /** Date account was deleted */
  deletedAt: Maybe<Scalars['DateTime']>;
  /** Date account was created */
  createdAt: Scalars['DateTime'];
  impersonatedBy: Maybe<User>;
  dwollaController: Maybe<DwollaController>;
  dwollaCustomer: Maybe<DwollaCustomer>;
};

/** Payload for users query */
export type UsersPayload = {
  __typename?: 'UsersPayload';
  results: Array<User>;
  count: Scalars['Int'];
};

/** User list sorting enum */
export enum UserSortFieldEnum {
  firstName = 'firstName',
  lastName = 'lastName',
  displayName = 'displayName',
  createdAt = 'createdAt',
  email = 'email',
  businessName = 'businessName'
}

/** User list sorting direction enum */
export enum UserSortDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type UsersQueryFilter = {
  text?: Maybe<Scalars['String']>;
};

/** Register User input to be used by registerUser mutation */
export type RegisterUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  businessName: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
  billingAddress: AddressInput;
  shippingAddress?: Maybe<AddressInput>;
  acceptedTerms: Scalars['Boolean'];
  avatar?: Maybe<Scalars['Upload']>;
  /** USDA Verification */
  operationId?: Maybe<Scalars['String']>;
  federalShellfishNumber?: Maybe<Scalars['String']>;
};

/** Update User Info input to be used by updateUserInfo mutation */
export type UpdateUserInfoInput = {
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  /** USDA Verification */
  operationId?: Maybe<Scalars['String']>;
  federalShellfishNumber?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<AddressInput>;
  shippingAddress?: Maybe<AddressInput>;
  businessName?: Maybe<Scalars['String']>;
  businessType?: Maybe<BusinessType>;
  businessDescription?: Maybe<Scalars['String']>;
  businessTradeName?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  /** Terms applied to new listings by default */
  defaultTerms?: Maybe<Scalars['String']>;
  /** Industry Classification Id as per Dwolla integration */
  industryClassificationId?: Maybe<Scalars['ID']>;
  /**
   * Employer Identification Number (required when businessType is CORPORATION,
   * LLC or PARTNERSHIP)
   */
  ein?: Maybe<Scalars['String']>;
  /** Date of birth (required to open a Dwolla account) */
  dateOfBirth?: Maybe<Scalars['Date']>;
  /**
   * Last 4 digits of the Social Security Number (required to open a Dwolla
   * account)
   */
  ssn4?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['Upload']>;
  merchantBanner?: Maybe<Scalars['Upload']>;
  /** Dwolla Controller */
  dwollaController?: Maybe<DwollaControllerInput>;
};

/** Price definition */
export type Price = PriceValue & {
  __typename?: 'Price';
  amount: Scalars['BigDecimal'];
  currency: Scalars['Currency'];
};

/** Price per unit definition */
export type PricePerUnit = PriceValue & UnitPriceValue & {
  __typename?: 'PricePerUnit';
  amount: Scalars['BigDecimal'];
  currency: Scalars['Currency'];
  unit: Unit;
};

export type RatingResults = {
  __typename?: 'RatingResults';
  results: Array<Rating>;
  count: Maybe<Scalars['Int']>;
};

export type Merchant = PublicUser & {
  __typename?: 'Merchant';
  id: Scalars['ID'];
  displayName: Scalars['String'];
  averageRating: Maybe<Scalars['BigDecimal']>;
  ratings: RatingResults;
  ordersFulfilled: Scalars['Int'];
  activeListings: Scalars['Int'];
  businessName: Maybe<Scalars['String']>;
  businessDescription: Maybe<Scalars['String']>;
  businessTradeName: Maybe<Scalars['String']>;
  billingAddress: PublicAddress;
  slug: Maybe<Scalars['String']>;
  avatar: Maybe<Media>;
  merchantBanner: Maybe<Media>;
  terms: Maybe<Scalars['String']>;
};


export type MerchantratingsArgs = {
  first?: Maybe<Scalars['Int']>;
  hasComment?: Maybe<Scalars['Boolean']>;
};

export type CartPrice = PriceValue & {
  __typename?: 'CartPrice';
  amount: Maybe<Scalars['BigDecimal']>;
  currency: Scalars['Currency'];
  isHidden: Scalars['Boolean'];
  isVariable: Scalars['Boolean'];
};

export type CartItemPrice = PriceValue & UnitPriceValue & {
  __typename?: 'CartItemPrice';
  amount: Maybe<Scalars['BigDecimal']>;
  currency: Scalars['Currency'];
  unit: Unit;
  isHidden: Scalars['Boolean'];
  isVariable: Scalars['Boolean'];
};

/** Cart stage */
export enum CartStage {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED'
}

/** Cart Item Entitlements available for User */
export enum CartItemEntitlement {
  CAN_EDIT_CART_ITEM = 'CAN_EDIT_CART_ITEM',
  CAN_DELETE_CART_ITEM = 'CAN_DELETE_CART_ITEM'
}

/** Cart Entitlements available for User */
export enum CartEntitlement {
  CAN_VIEW_CART = 'CAN_VIEW_CART',
  CAN_VIEW_CART_PRICE = 'CAN_VIEW_CART_PRICE',
  CAN_EDIT_CART = 'CAN_EDIT_CART',
  CAN_REQUEST_CHECKOUT = 'CAN_REQUEST_CHECKOUT',
  CAN_REQUEST_DELIVERY_DATE = 'CAN_REQUEST_DELIVERY_DATE',
  CAN_ACCEPT_CHECKOUT = 'CAN_ACCEPT_CHECKOUT',
  CAN_DECLINE_CHECKOUT = 'CAN_DECLINE_CHECKOUT'
}

export type CartItem = {
  __typename?: 'CartItem';
  id: Scalars['ID'];
  listing: WholeSaleListing;
  quantity: Quantity;
  price: CartItemPrice;
  totalPrice: CartPrice;
  notes: Maybe<Scalars['String']>;
  entitlements: Array<CartItemEntitlement>;
};

/** Cart User definition */
export type CartUser = PublicUser & {
  __typename?: 'CartUser';
  id: Scalars['ID'];
  displayName: Scalars['String'];
  averageRating: Maybe<Scalars['BigDecimal']>;
  ratings: RatingResults;
  ordersFulfilled: Scalars['Int'];
  activeListings: Scalars['Int'];
  businessName: Maybe<Scalars['String']>;
  businessDescription: Maybe<Scalars['String']>;
  businessTradeName: Maybe<Scalars['String']>;
  billingAddress: PublicAddress;
  slug: Maybe<Scalars['String']>;
  avatar: Maybe<Media>;
  merchantBanner: Maybe<Media>;
  terms: Maybe<Scalars['String']>;
};


/** Cart User definition */
export type CartUserratingsArgs = {
  first?: Maybe<Scalars['Int']>;
  hasComment?: Maybe<Scalars['Boolean']>;
};

export type Cart = {
  __typename?: 'Cart';
  id: Scalars['ID'];
  /** The owner of the cart */
  fromUser: CartUser;
  /** The user we are buying from */
  toUser: CartUser;
  items: Array<CartItem>;
  stage: CartStage;
  totalPrice: CartPrice;
  /**
   * The requested delivery date for the cart items. Only exists if the listing
   * states that delivery is NEGOTIABLE or INCLUDED
   */
  requestedDeliveryDate: Maybe<Scalars['Date']>;
  /**
   * When the Cart is in DECLINED stage this field should provide the reason why
   * the Cart was declined (seller needs to provide one when they decline).
   */
  lastDeclinedReason: Maybe<Scalars['String']>;
  /**
   * When the Cart is in ACCEPTED stage this field will provide the Order
   * associated with this Cart.
   */
  order: Maybe<WholeSaleOrder>;
  entitlements: Array<CartEntitlement>;
};

/** Search options for carts query */
export type CartsSearchQuery = {
  stages?: Maybe<Array<CartStage>>;
  /** Carts by merchant */
  postedById?: Maybe<Scalars['ID']>;
  /** Carts requested to this merchant */
  toUserIdOrSlug?: Maybe<Scalars['String']>;
  /** Carts created by User (aka Buyer) */
  fromMe?: Maybe<Scalars['Boolean']>;
  /** Carts to be processed by User (aka Seller) */
  toMe?: Maybe<Scalars['Boolean']>;
  /** Carts with items, ignore empty carts */
  withItems?: Maybe<Scalars['Boolean']>;
};

export type CartEdge = {
  __typename?: 'CartEdge';
  cursor: Scalars['String'];
  node: Cart;
};

/** Payload for carts query */
export type CartsConnection = {
  __typename?: 'CartsConnection';
  edges: Array<CartEdge>;
  nodes: Array<Cart>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Cart Quantity input to be used by addListingToCart */
export type CartQuantityInput = {
  amount: Scalars['BigDecimal'];
  unit: Unit;
};

/** Input to be used by addListingToCart mutation */
export type AddListingToCartInput = {
  /** Listing can only be of WholeSaleListing type */
  listingId: Scalars['ID'];
  quantity: CartQuantityInput;
  notes?: Maybe<Scalars['String']>;
};

/** Input to be used by removeFromCart mutation */
export type RemoveFromCartInput = {
  cartId: Scalars['ID'];
  cartItemIds: Array<Scalars['ID']>;
};

/** Input to be used by requestCartCheckout mutation */
export type RequestCartCheckoutInput = {
  cartId: Scalars['ID'];
  requestedDeliveryDate?: Maybe<Scalars['Date']>;
};

/** Input to be used by acceptCartCheckout mutation */
export type AcceptCartCheckoutInput = {
  cartId: Scalars['ID'];
};

/** Input to be used by declineCartCheckout mutation */
export type DeclineCartCheckoutInput = {
  cartId: Scalars['ID'];
  reason: Scalars['String'];
};

/** Input to be used by updateCartItem mutation */
export type UpdateCartItemInput = {
  id: Scalars['ID'];
  quantity: CartQuantityInput;
  /**
   * This field is specifically a buyser to seller note on listing specification
   * Example: buyer wants to inform seller that they're requesting a certain cut
   * pattern for fish
   */
  notes?: Maybe<Scalars['String']>;
};

/** Categories */
export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  image: Maybe<Media>;
};

/** Payload for categories query */
export type CategoriesPayload = {
  __typename?: 'CategoriesPayload';
  results: Array<Category>;
  count: Scalars['Int'];
};

/** Cuts and sizes */
export enum CutsAndSizes {
  ROUND = 'ROUND',
  SIRLOIN = 'SIRLOIN',
  BRISKET = 'BRISKET',
  FORE_SHANK = 'FORE_SHANK',
  CHUCK = 'CHUCK',
  SHORT_LOIN = 'SHORT_LOIN',
  RIB = 'RIB',
  GROUND = 'GROUND',
  CUBES = 'CUBES',
  TONGUE = 'TONGUE',
  LIVER = 'LIVER',
  WHOLE = 'WHOLE',
  BREASTS = 'BREASTS',
  WINGS = 'WINGS',
  LEGS = 'LEGS',
  FILLET = 'FILLET',
  STEAK = 'STEAK',
  HEADED_GUTTED = 'HEADED_GUTTED',
  EXTRA_COLOSSAL = 'EXTRA_COLOSSAL',
  SUPER_COLOSSAL = 'SUPER_COLOSSAL',
  COLOSSAL = 'COLOSSAL',
  EXTRA_JUMBO = 'EXTRA_JUMBO',
  JUMBO = 'JUMBO',
  EXTRA_LARGE = 'EXTRA_LARGE',
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
  TINY = 'TINY',
  SALAD_STYLE = 'SALAD_STYLE'
}

/** Delivery availability status */
export enum DeliveryAvailability {
  NEGOTIABLE = 'NEGOTIABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  INCLUDED = 'INCLUDED'
}

/** The type of listing */
export enum ListingType {
  FOR_SALE = 'FOR_SALE',
  WANTED = 'WANTED',
  TRANSPORTATION_WANTED = 'TRANSPORTATION_WANTED',
  WHOLE_SALE = 'WHOLE_SALE'
}

/** Listing Entitlements available for current User session */
export enum ListingEntitlement {
  CAN_UNPUBLISH = 'CAN_UNPUBLISH',
  CAN_MAKE_OFFER = 'CAN_MAKE_OFFER',
  CAN_ADD_TO_CART = 'CAN_ADD_TO_CART',
  CAN_UPDATE_PRICE = 'CAN_UPDATE_PRICE',
  CAN_VIEW_PRICE = 'CAN_VIEW_PRICE'
}

export type WholesalePriceValue = {
  amount: Maybe<Scalars['BigDecimal']>;
  currency: Scalars['Currency'];
  isHidden: Scalars['Boolean'];
  isVariable: Scalars['Boolean'];
};

/** Wholesale Price definition */
export type WholesalePrice = PriceValue & WholesalePriceValue & {
  __typename?: 'WholesalePrice';
  amount: Maybe<Scalars['BigDecimal']>;
  currency: Scalars['Currency'];
  isHidden: Scalars['Boolean'];
  isVariable: Scalars['Boolean'];
};

/** Wholesale Price per unit definition */
export type WholesalePricePerUnit = PriceValue & UnitPriceValue & WholesalePriceValue & {
  __typename?: 'WholesalePricePerUnit';
  amount: Maybe<Scalars['BigDecimal']>;
  currency: Scalars['Currency'];
  unit: Unit;
  isHidden: Scalars['Boolean'];
  isVariable: Scalars['Boolean'];
};

/** Listing User definition */
export type ListingUser = PublicUser & {
  __typename?: 'ListingUser';
  id: Scalars['ID'];
  displayName: Scalars['String'];
  averageRating: Maybe<Scalars['BigDecimal']>;
  ratings: RatingResults;
  ordersFulfilled: Scalars['Int'];
  activeListings: Scalars['Int'];
  businessName: Maybe<Scalars['String']>;
  businessDescription: Maybe<Scalars['String']>;
  businessTradeName: Maybe<Scalars['String']>;
  billingAddress: PublicAddress;
  slug: Maybe<Scalars['String']>;
  avatar: Maybe<Media>;
  merchantBanner: Maybe<Media>;
  terms: Maybe<Scalars['String']>;
};


/** Listing User definition */
export type ListingUserratingsArgs = {
  first?: Maybe<Scalars['Int']>;
  hasComment?: Maybe<Scalars['Boolean']>;
};

/** Listing Availability definition */
export type ListingAvailability = {
  __typename?: 'ListingAvailability';
  year: Maybe<Scalars['Int']>;
  estimated: Maybe<Scalars['Date']>;
  rangeStart: Maybe<Scalars['Date']>;
  rangeEnd: Maybe<Scalars['Date']>;
};

/** Interface for all types of listings */
export type Listing = {
  id: Scalars['ID'];
  product: Product;
  postedBy: ListingUser;
  postedAddress: Address;
  isPublished: Scalars['Boolean'];
  /** The entitlements that this user has in this Listing. */
  entitlements: Array<ListingEntitlement>;
  /** Tags for this listing */
  tags: Array<Tag>;
  description: Maybe<Scalars['String']>;
  terms: Maybe<Scalars['String']>;
};

/** Transportation listing */
export type TransportationListing = Listing & {
  __typename?: 'TransportationListing';
  id: Scalars['ID'];
  product: Product;
  postedBy: ListingUser;
  postedAddress: Address;
  isPublished: Scalars['Boolean'];
  entitlements: Array<ListingEntitlement>;
  tags: Array<Tag>;
  description: Maybe<Scalars['String']>;
  terms: Maybe<Scalars['String']>;
  /** Current open Offer (of current logged in user) */
  openOffer: Maybe<Offer>;
  quantity: Quantity;
  pickupDateTime: Scalars['DateTime'];
  moveByDateTime: Scalars['DateTime'];
  deliverByDateTime: Scalars['DateTime'];
  productPackagingAndStorage: Maybe<Scalars['String']>;
  equipmentRequirements: Maybe<Scalars['String']>;
  pickupAddress: Address;
  deliveryAddress: Address;
  estimatedTravelDistance: Scalars['Float'];
  /**
   * Public information about the listing sold status.
   * Usually when this is true, it also means that it is unpublished too.
   */
  isSold: Scalars['Boolean'];
};

/** ForSale Listing */
export type ForSaleListing = Listing & {
  __typename?: 'ForSaleListing';
  id: Scalars['ID'];
  product: Product;
  postedBy: ListingUser;
  postedAddress: Address;
  isPublished: Scalars['Boolean'];
  entitlements: Array<ListingEntitlement>;
  tags: Array<Tag>;
  description: Maybe<Scalars['String']>;
  terms: Maybe<Scalars['String']>;
  /** Current open Offer (of current logged in user) */
  openOffer: Maybe<Offer>;
  price: PricePerUnit;
  quantity: Quantity;
  availability: ListingAvailability;
  estimatedHarvestDate: Maybe<Scalars['Date']>;
  deliveryAvailability: DeliveryAvailability;
  /**
   * Public information about the listing sold status.
   * Usually when this is true, it also means that it is unpublished too.
   */
  isSold: Scalars['Boolean'];
  cutOrSize: Maybe<CutsAndSizes>;
};

/** WantedListing */
export type WantedListing = Listing & {
  __typename?: 'WantedListing';
  id: Scalars['ID'];
  product: Product;
  postedBy: ListingUser;
  postedAddress: Address;
  isPublished: Scalars['Boolean'];
  entitlements: Array<ListingEntitlement>;
  tags: Array<Tag>;
  description: Maybe<Scalars['String']>;
  terms: Maybe<Scalars['String']>;
  /** Current open Offer (of current logged in user) */
  openOffer: Maybe<Offer>;
  quantity: Quantity;
  price: PricePerUnit;
  availability: ListingAvailability;
  /**
   * Public information about the listing sold status.
   * Usually when this is true, it also means that it is unpublished too.
   */
  isSold: Scalars['Boolean'];
  cutOrSize: Maybe<CutsAndSizes>;
};

/** WholeSaleListing */
export type WholeSaleListing = Listing & {
  __typename?: 'WholeSaleListing';
  id: Scalars['ID'];
  product: Product;
  postedBy: ListingUser;
  postedAddress: Address;
  isPublished: Scalars['Boolean'];
  entitlements: Array<ListingEntitlement>;
  tags: Array<Tag>;
  description: Maybe<Scalars['String']>;
  terms: Maybe<Scalars['String']>;
  wholesalePrice: WholesalePricePerUnit;
  unit: Unit;
  upc: Maybe<Scalars['String']>;
  msrp: Maybe<PricePerUnit>;
  estimatedHarvestDate: Maybe<Scalars['Date']>;
  deliveryAvailability: DeliveryAvailability;
  cutOrSize: Maybe<CutsAndSizes>;
  itemId: Maybe<Scalars['String']>;
};

/** Filter object for both listings and offers filter */
export type ListingQueryFilter = {
  availabilityEstimated?: Maybe<Scalars['Date']>;
  availabilityStart?: Maybe<Scalars['Date']>;
  availabilityEnd?: Maybe<Scalars['Date']>;
  proximity?: Maybe<Scalars['Int']>;
  postedById?: Maybe<Scalars['ID']>;
};

/** Search options for listings query */
export type ListingSearchQuery = {
  types?: Maybe<Array<ListingType>>;
  categoryIds?: Maybe<Array<Scalars['ID']>>;
  /** Return only the specified tags. */
  includeTagIds?: Maybe<Array<Scalars['ID']>>;
  text?: Maybe<Scalars['String']>;
  /** Filter by Merchant ID or Slug */
  postedByIdOrSlug?: Maybe<Scalars['String']>;
};

export type ListingEdge = {
  __typename?: 'ListingEdge';
  cursor: Scalars['String'];
  node: Listing;
};

/** Payload for listings query */
export type ListingsConnection = {
  __typename?: 'ListingsConnection';
  edges: Array<ListingEdge>;
  nodes: Array<Listing>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Listing price input to be used by addListing mutation */
export type ListingPriceInput = {
  amount: Scalars['BigDecimal'];
  currency: Scalars['Currency'];
  unit: Unit;
};

/** Wholesale listing price input to be used by addWholesaleListing mutation */
export type WholesaleListingPriceInput = {
  amount: Scalars['BigDecimal'];
  currency: Scalars['Currency'];
  unit: Unit;
  isHidden: Scalars['Boolean'];
  isVariable: Scalars['Boolean'];
};

/** Listing quantity input to be used by addListing mutation */
export type ListingQuantityInput = {
  amount: Scalars['BigDecimal'];
  unit: Unit;
};

/** Listing Availability input to be used by addListing mutation */
export type ListingAvailabilityInput = {
  year?: Maybe<Scalars['Int']>;
  estimated?: Maybe<Scalars['Date']>;
  rangeStart?: Maybe<Scalars['Date']>;
  rangeEnd?: Maybe<Scalars['Date']>;
};

/**
 * Add Listing input to be used by addListing mutation
 * @deprecated - The mutation using this input is deprecated.
 */
export type AddListingInput = {
  type: ListingType;
  productId: Scalars['ID'];
  price: ListingPriceInput;
  quantity: ListingQuantityInput;
  availability: ListingAvailabilityInput;
  deliveryAvailability: DeliveryAvailability;
  description?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
  estimatedHarvestDate?: Maybe<Scalars['Date']>;
  /** An array of Node IDs of tags for this listing. */
  tagIds?: Maybe<Array<Scalars['ID']>>;
  cutOrSize?: Maybe<CutsAndSizes>;
};

/** Add Listing input to be used by addForSaleListing mutation */
export type AddForSaleListingInput = {
  type: ListingType;
  productId: Scalars['ID'];
  price: ListingPriceInput;
  quantity: ListingQuantityInput;
  availability: ListingAvailabilityInput;
  deliveryAvailability: DeliveryAvailability;
  description?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
  estimatedHarvestDate?: Maybe<Scalars['Date']>;
  /** An array of Node IDs of tags for this listing. */
  tagIds?: Maybe<Array<Scalars['ID']>>;
  cutOrSize?: Maybe<CutsAndSizes>;
};

/** Add Wanted Listing input to be used by addWantedListing mutation */
export type AddWantedListingInput = {
  type: ListingType;
  productId: Scalars['ID'];
  price: ListingPriceInput;
  quantity: ListingQuantityInput;
  availability: ListingAvailabilityInput;
  description?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
  estimatedHarvestDate?: Maybe<Scalars['Date']>;
  /** An array of Node IDs of tags for this listing. */
  tagIds?: Maybe<Array<Scalars['ID']>>;
  cutOrSize?: Maybe<CutsAndSizes>;
};

/** Add Transportation Listing input to be used by addTransportationListing */
export type AddTransportationListingInput = {
  productId: Scalars['ID'];
  quantity: ListingQuantityInput;
  pickupDateTime: Scalars['DateTime'];
  moveByDateTime: Scalars['DateTime'];
  deliverByDateTime: Scalars['DateTime'];
  pickupAddress: AddressInput;
  deliveryAddress: AddressInput;
  productPackagingAndStorage?: Maybe<Scalars['String']>;
  equipmentRequirements?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
  /** An array of Node IDs of tags for this listing. */
  tagIds?: Maybe<Array<Scalars['ID']>>;
};

/** Add Whole Sale Listing input to be used by addWholeSaleListing */
export type AddWholeSaleListingInput = {
  productId: Scalars['ID'];
  upc?: Maybe<Scalars['String']>;
  msrp?: Maybe<ListingPriceInput>;
  price: WholesaleListingPriceInput;
  deliveryAvailability: DeliveryAvailability;
  description?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
  estimatedHarvestDate?: Maybe<Scalars['Date']>;
  /** An array of Node IDs of tags for this listing. */
  tagIds?: Maybe<Array<Scalars['ID']>>;
  cutOrSize?: Maybe<CutsAndSizes>;
  itemId?: Maybe<Scalars['String']>;
};

/** Unpublish Listing input to be used by unpublishListing mutation. */
export type UnpublishListingInput = {
  id: Scalars['ID'];
};

/** Update a wholesale listing. Only certain fields allowed. */
export type UpdateWholeSaleListingInput = {
  id: Scalars['ID'];
  price: ListingPriceInput;
};

/** Offer stage */
export enum OfferStage {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  CANCELED = 'CANCELED'
}

/** Entitlements available for User */
export enum OfferEntitlement {
  CAN_VIEW_OFFER = 'CAN_VIEW_OFFER',
  CAN_ACCEPT_OFFER = 'CAN_ACCEPT_OFFER',
  CAN_DECLINE_OFFER = 'CAN_DECLINE_OFFER',
  CAN_CANCEL_OFFER = 'CAN_CANCEL_OFFER',
  CAN_COUNTER_OFFER = 'CAN_COUNTER_OFFER'
}

/** Offer User definition */
export type OfferUser = PublicUser & {
  __typename?: 'OfferUser';
  id: Scalars['ID'];
  displayName: Scalars['String'];
  averageRating: Maybe<Scalars['BigDecimal']>;
  ratings: RatingResults;
  ordersFulfilled: Scalars['Int'];
  activeListings: Scalars['Int'];
  businessName: Maybe<Scalars['String']>;
  businessDescription: Maybe<Scalars['String']>;
  businessTradeName: Maybe<Scalars['String']>;
  billingAddress: PublicAddress;
  slug: Maybe<Scalars['String']>;
  avatar: Maybe<Media>;
  merchantBanner: Maybe<Media>;
  terms: Maybe<Scalars['String']>;
};


/** Offer User definition */
export type OfferUserratingsArgs = {
  first?: Maybe<Scalars['Int']>;
  hasComment?: Maybe<Scalars['Boolean']>;
};

export type PerUnitOfferListing = ForSaleListing | WantedListing;

/** Offer details */
export type Offer = {
  id: Scalars['ID'];
  notes: Maybe<Scalars['String']>;
  fromUser: OfferUser;
  toUser: OfferUser;
  stage: OfferStage;
  /** The entitlements that current user has in this offer. */
  entitlements: Array<OfferEntitlement>;
  previousOffer: Maybe<Offer>;
  nextOffer: Maybe<Offer>;
  order: Maybe<Order>;
};

/** PerUnit Offer */
export type PerUnitOffer = Offer & {
  __typename?: 'PerUnitOffer';
  id: Scalars['ID'];
  listing: PerUnitOfferListing;
  notes: Maybe<Scalars['String']>;
  fromUser: OfferUser;
  toUser: OfferUser;
  stage: OfferStage;
  entitlements: Array<OfferEntitlement>;
  previousOffer: Maybe<Offer>;
  nextOffer: Maybe<Offer>;
  order: Maybe<Order>;
  price: PricePerUnit;
  totalPrice: Price;
};

/** FullPrice Offer */
export type FullPriceOffer = Offer & {
  __typename?: 'FullPriceOffer';
  id: Scalars['ID'];
  listing: TransportationListing;
  notes: Maybe<Scalars['String']>;
  fromUser: OfferUser;
  toUser: OfferUser;
  stage: OfferStage;
  entitlements: Array<OfferEntitlement>;
  previousOffer: Maybe<Offer>;
  nextOffer: Maybe<Offer>;
  order: Maybe<Order>;
  price: Price;
};

/** Search options for offers query */
export type OfferSearchQuery = {
  stages?: Maybe<Array<OfferStage>>;
  counterpartyIdOrSlug?: Maybe<Scalars['String']>;
};

export type OfferEdge = {
  __typename?: 'OfferEdge';
  cursor: Scalars['String'];
  node: Offer;
};

/** Payload for offers query */
export type OffersConnection = {
  __typename?: 'OffersConnection';
  edges: Array<OfferEdge>;
  nodes: Array<Offer>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Offer price input to be used by makeOffer mutation */
export type OfferPriceInput = {
  amount: Scalars['BigDecimal'];
  currency: Scalars['Currency'];
  unit: Unit;
};

/** Offer price input for FullPrice offers, used by makeFullPriceOffer mutation */
export type FullPriceOfferInput = {
  amount: Scalars['BigDecimal'];
  currency: Scalars['Currency'];
};

/** Make offer input to be used by makeOffer mutation */
export type MakeOfferInput = {
  listingId: Scalars['ID'];
  price: OfferPriceInput;
  notes?: Maybe<Scalars['String']>;
};

/** Make offer input to be used by makeFullPriceOffer mutation */
export type MakeFullPriceOfferInput = {
  listingId: Scalars['ID'];
  price: FullPriceOfferInput;
  notes?: Maybe<Scalars['String']>;
};

/** Counter offer input to be used by counterOffer mutation */
export type CounterOfferInput = {
  offerId: Scalars['ID'];
  price: OfferPriceInput;
  notes?: Maybe<Scalars['String']>;
};

/** Counter offer input to be used by counterFullPriceOffer mutation */
export type CounterFullPriceOfferInput = {
  offerId: Scalars['ID'];
  price: FullPriceOfferInput;
  notes?: Maybe<Scalars['String']>;
};

/** Entitlements available for User */
export enum OrderEntitlement {
  CAN_CONFIRM_OFF_PLATFORM_PAYMENT = 'CAN_CONFIRM_OFF_PLATFORM_PAYMENT',
  CAN_VIEW_ORDER = 'CAN_VIEW_ORDER',
  CAN_EDIT_ORDER = 'CAN_EDIT_ORDER',
  CAN_FULFILL = 'CAN_FULFILL',
  CAN_MAKE_PAYMENT = 'CAN_MAKE_PAYMENT',
  CAN_MAKE_PAYMENT_OFF_PLATFORM = 'CAN_MAKE_PAYMENT_OFF_PLATFORM',
  CAN_SET_RATING = 'CAN_SET_RATING'
}

export type Order = {
  id: Scalars['ID'];
  shortId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  deliveredAt: Maybe<Scalars['DateTime']>;
  isArchived: Scalars['Boolean'];
  paid: Scalars['Boolean'];
  /** The fields that can be updated during Order stage */
  notes: Maybe<Scalars['String']>;
  terms: Maybe<Scalars['String']>;
  /** The entitlements that current user has in this order. */
  entitlements: Array<OrderEntitlement>;
  /** The rating that the logged in user gave to this Order */
  rating: Maybe<Rating>;
};

/** Order User definition */
export type OrderUser = PublicUser & {
  __typename?: 'OrderUser';
  id: Scalars['ID'];
  displayName: Scalars['String'];
  averageRating: Maybe<Scalars['BigDecimal']>;
  ratings: RatingResults;
  ordersFulfilled: Scalars['Int'];
  activeListings: Scalars['Int'];
  businessName: Maybe<Scalars['String']>;
  businessDescription: Maybe<Scalars['String']>;
  businessTradeName: Maybe<Scalars['String']>;
  billingAddress: PublicAddress;
  slug: Maybe<Scalars['String']>;
  avatar: Maybe<Media>;
  merchantBanner: Maybe<Media>;
  terms: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


/** Order User definition */
export type OrderUserratingsArgs = {
  first?: Maybe<Scalars['Int']>;
  hasComment?: Maybe<Scalars['Boolean']>;
};

export type FulfillmentOrder = Order & {
  __typename?: 'FulfillmentOrder';
  id: Scalars['ID'];
  shortId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  deliveredAt: Maybe<Scalars['DateTime']>;
  isArchived: Scalars['Boolean'];
  paid: Scalars['Boolean'];
  entitlements: Array<OrderEntitlement>;
  rating: Maybe<Rating>;
  notes: Maybe<Scalars['String']>;
  terms: Maybe<Scalars['String']>;
  offer: PerUnitOffer;
  quantity: Quantity;
  price: PricePerUnit;
  totalPrice: Price;
  buyer: OrderUser;
  seller: OrderUser;
};

export type ShipmentOrder = Order & {
  __typename?: 'ShipmentOrder';
  id: Scalars['ID'];
  shortId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  deliveredAt: Maybe<Scalars['DateTime']>;
  isArchived: Scalars['Boolean'];
  paid: Scalars['Boolean'];
  entitlements: Array<OrderEntitlement>;
  rating: Maybe<Rating>;
  notes: Maybe<Scalars['String']>;
  terms: Maybe<Scalars['String']>;
  offer: FullPriceOffer;
  quantity: Quantity;
  price: Price;
  requester: OrderUser;
  transporter: OrderUser;
};

export type WholeSaleOrderItem = {
  __typename?: 'WholeSaleOrderItem';
  id: Scalars['ID'];
  order: WholeSaleOrder;
  listing: WholeSaleListing;
  quantity: Quantity;
  price: WholesalePricePerUnit;
  totalPrice: WholesalePrice;
  notes: Maybe<Scalars['String']>;
  entitlements: Array<OrderItemEntitlement>;
};

export type WholeSaleOrder = Order & {
  __typename?: 'WholeSaleOrder';
  id: Scalars['ID'];
  shortId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  deliveredAt: Maybe<Scalars['DateTime']>;
  isArchived: Scalars['Boolean'];
  paid: Scalars['Boolean'];
  entitlements: Array<OrderEntitlement>;
  rating: Maybe<Rating>;
  notes: Maybe<Scalars['String']>;
  terms: Maybe<Scalars['String']>;
  buyer: OrderUser;
  seller: OrderUser;
  /** @deprecated Use wholesaleTotalPrice. */
  totalPrice: Price;
  wholesaleTotalPrice: WholesalePrice;
  items: Array<WholeSaleOrderItem>;
  cart: Cart;
};

/** Order Item Entitlements available for User */
export enum OrderItemEntitlement {
  CAN_EDIT_ORDER_ITEM = 'CAN_EDIT_ORDER_ITEM'
}

export type OrderEdge = {
  __typename?: 'OrderEdge';
  cursor: Scalars['String'];
  node: Order;
};

/** Payload for offers query */
export type OrdersConnection = {
  __typename?: 'OrdersConnection';
  edges: Array<OrderEdge>;
  nodes: Array<Order>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type OrdersQueryFilter = {
  /**
   * Filters by archived or unarchived orders when provided a value; when not provided, shows both
   * archived/unarchived orders
   */
  isArchived?: Maybe<Scalars['Boolean']>;
};

/** Input to be used by updateOrderItem mutation */
export type UpdateOrderItemInput = {
  id: Scalars['ID'];
  priceAmount: Scalars['BigDecimal'];
  quantityAmount: Scalars['BigDecimal'];
};

/** Input to be used by updateOrder mutation */
export type UpdateOrderInput = {
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
};

/** Price definition */
export type PriceValue = {
  amount: Maybe<Scalars['BigDecimal']>;
  currency: Maybe<Scalars['Currency']>;
};

/** Price per Unit definition */
export type UnitPriceValue = {
  amount: Maybe<Scalars['BigDecimal']>;
  currency: Maybe<Scalars['Currency']>;
  unit: Unit;
};

/** Product definition */
export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name: Scalars['String'];
  category: Category;
  type: Maybe<Scalars['String']>;
  image: Maybe<Media>;
};

/** Search options for products query */
export type ProductSearchQuery = {
  text?: Maybe<Scalars['String']>;
};

/** Payload for products query */
export type ProductsPayload = {
  __typename?: 'ProductsPayload';
  results: Array<Product>;
  count: Scalars['Int'];
};

export type PublicAddress = {
  __typename?: 'PublicAddress';
  id: Scalars['ID'];
  street: Scalars['String'];
  city: Scalars['String'];
  state: AddressState;
  postalCode: Scalars['String'];
  location: PointGeometry;
};

export type PublicUser = {
  id: Scalars['ID'];
  displayName: Scalars['String'];
  averageRating: Maybe<Scalars['BigDecimal']>;
  ratings: RatingResults;
  ordersFulfilled: Scalars['Int'];
  activeListings: Scalars['Int'];
  businessName: Maybe<Scalars['String']>;
  businessDescription: Maybe<Scalars['String']>;
  businessTradeName: Maybe<Scalars['String']>;
  billingAddress: PublicAddress;
  slug: Maybe<Scalars['String']>;
  avatar: Maybe<Media>;
  merchantBanner: Maybe<Media>;
  terms: Maybe<Scalars['String']>;
};


export type PublicUserratingsArgs = {
  first?: Maybe<Scalars['Int']>;
  hasComment?: Maybe<Scalars['Boolean']>;
};

/** Quantity definition */
export type Quantity = {
  __typename?: 'Quantity';
  amount: Scalars['BigDecimal'];
  unit: Unit;
};

/** The units */
export enum Unit {
  BUSHEL = 'BUSHEL',
  TON = 'TON',
  TONNE = 'TONNE',
  CWT = 'CWT',
  LB = 'LB',
  KG = 'KG',
  UNIT = 'UNIT',
  PECK = 'PECK',
  DRY_GALLON = 'DRY_GALLON',
  QUART = 'QUART',
  PINT = 'PINT',
  GALLON = 'GALLON',
  YARD = 'YARD',
  CASE = 'CASE',
  BOX = 'BOX',
  BOTTLE = 'BOTTLE',
  JAR = 'JAR',
  BAG = 'BAG',
  CONTAINER = 'CONTAINER',
  BUNCH = 'BUNCH'
}

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Search options for tags query */
export type TagsSearchQuery = {
  text?: Maybe<Scalars['String']>;
};

export type TagEdge = {
  __typename?: 'TagEdge';
  cursor: Scalars['String'];
  node: Tag;
};

/** Payload for carts query */
export type TagsConnection = {
  __typename?: 'TagsConnection';
  edges: Array<TagEdge>;
  nodes: Array<Tag>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Result of a makePayment mutation */
export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['ID'];
  status: Scalars['String'];
  order: Order;
};

/** ChatContextEnum definition */
export enum ChatContextEnum {
  HELP = 'HELP',
  LISTING = 'LISTING',
  MERCHANT = 'MERCHANT',
  OFFER = 'OFFER',
  ORDER = 'ORDER'
}

/** ChatChannel definition */
export type ChatChannel = {
  __typename?: 'ChatChannel';
  id: Scalars['String'];
  user1: Scalars['String'];
  user2: Scalars['String'];
  user1Deco: Maybe<ChatUser>;
  user2Deco: Maybe<ChatUser>;
  contextId: Maybe<Scalars['String']>;
  contextType: ChatContextEnum;
  secretKey: Scalars['String'];
};

/** A sanitized version of the user, available to notification users */
export type ChatUser = PublicUser & {
  __typename?: 'ChatUser';
  id: Scalars['ID'];
  displayName: Scalars['String'];
  averageRating: Maybe<Scalars['BigDecimal']>;
  ratings: RatingResults;
  ordersFulfilled: Scalars['Int'];
  activeListings: Scalars['Int'];
  businessName: Maybe<Scalars['String']>;
  businessDescription: Maybe<Scalars['String']>;
  businessTradeName: Maybe<Scalars['String']>;
  billingAddress: PublicAddress;
  slug: Maybe<Scalars['String']>;
  avatar: Maybe<Media>;
  merchantBanner: Maybe<Media>;
  terms: Maybe<Scalars['String']>;
};


/** A sanitized version of the user, available to notification users */
export type ChatUserratingsArgs = {
  first?: Maybe<Scalars['Int']>;
  hasComment?: Maybe<Scalars['Boolean']>;
};

/** ChatContextInput definition */
export type ChatContextInput = {
  contextId?: Maybe<Scalars['String']>;
  contextType: ChatContextEnum;
};

/** Search options for chatChannel query */
export type ChatChannelSearchQuery = {
  user1: Scalars['String'];
  user2: Scalars['String'];
  context?: Maybe<ChatContextInput>;
};

export type NoAuthChatChannelAndIdentifier = {
  __typename?: 'NoAuthChatChannelAndIdentifier';
  chatChannel: ChatChannel;
  id: Scalars['String'];
};

/** Union type of Listing, Offer, and Orders to decorate the chat context */
export type ChatContextDecoration = WantedListing | ForSaleListing | TransportationListing | WholeSaleListing | PerUnitOffer | FullPriceOffer | FulfillmentOrder | ShipmentOrder | Merchant;

/**
 * Decorated chat channels includes the chat channel information as well as info
 * about the related listing/offer
 */
export type DecoratedChatChannels = {
  __typename?: 'DecoratedChatChannels';
  chatChannel: ChatChannel;
  contextDecoration: Maybe<ChatContextDecoration>;
};

/** Ratings for User */
export type Rating = {
  __typename?: 'Rating';
  id: Scalars['ID'];
  score: Scalars['Int'];
  comment: Maybe<Scalars['String']>;
};

/** Rating input to be used by setRating mutation */
export type RatingInput = {
  orderId: Scalars['ID'];
  score: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
};

/** Media definition. Currently only supports image urls */
export type Media = {
  __typename?: 'Media';
  url: Scalars['String'];
};

export enum CacheControlScope {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

export type AccountBankingLinksFragment = (
  { __typename?: 'User' }
  & { dwollaCustomer: Maybe<(
    { __typename?: 'DwollaCustomer' }
    & Pick<DwollaCustomer, 'id' | 'status'>
  )> }
);

export type IndustryClassificationFragment = (
  { __typename?: 'IndustryClassification' }
  & Pick<IndustryClassification, 'id' | 'name'>
);

export type BusinessClassificationFragment = (
  { __typename?: 'BusinessClassification' }
  & Pick<BusinessClassification, 'id' | 'name'>
  & { industries: Array<(
    { __typename?: 'IndustryClassification' }
    & Pick<IndustryClassification, 'id'>
    & IndustryClassificationFragment
  )> }
);

export type BusinessClassificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type BusinessClassificationsQuery = (
  { __typename?: 'Query' }
  & { businessClassifications: (
    { __typename?: 'BusinessClassificationsPayload' }
    & Pick<BusinessClassificationsPayload, 'count'>
    & { results: Array<(
      { __typename?: 'BusinessClassification' }
      & Pick<BusinessClassification, 'id'>
      & BusinessClassificationFragment
    )> }
  ) }
);

export type ValidatePasswordStrengthQueryVariables = Exact<{
  password: Scalars['String'];
}>;


export type ValidatePasswordStrengthQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'validatePasswordStrength'>
);

type AccountOrdersListFragment_FulfillmentOrder_ = (
  { __typename: 'FulfillmentOrder' }
  & Pick<FulfillmentOrder, 'id' | 'shortId' | 'deliveredAt' | 'isArchived' | 'createdAt' | 'paid'>
  & { price: (
    { __typename?: 'PricePerUnit' }
    & Pick<PricePerUnit, 'amount'>
  ), offer: (
    { __typename?: 'PerUnitOffer' }
    & Pick<PerUnitOffer, 'id'>
    & { listing: (
      { __typename?: 'ForSaleListing' }
      & Pick<ForSaleListing, 'id'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'WantedListing' }
      & Pick<WantedListing, 'id'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      ) }
    ) }
  ) }
);

type AccountOrdersListFragment_ShipmentOrder_ = (
  { __typename: 'ShipmentOrder' }
  & Pick<ShipmentOrder, 'id' | 'shortId' | 'deliveredAt' | 'isArchived' | 'createdAt' | 'paid'>
  & { price: (
    { __typename?: 'Price' }
    & Pick<Price, 'amount'>
  ), offer: (
    { __typename?: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id'>
    & { listing: (
      { __typename?: 'TransportationListing' }
      & Pick<TransportationListing, 'id'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      ) }
    ) }
  ) }
);

type AccountOrdersListFragment_WholeSaleOrder_ = (
  { __typename: 'WholeSaleOrder' }
  & Pick<WholeSaleOrder, 'id' | 'shortId' | 'deliveredAt' | 'isArchived' | 'createdAt' | 'paid'>
  & { cart: (
    { __typename?: 'Cart' }
    & { totalPrice: (
      { __typename?: 'CartPrice' }
      & Pick<CartPrice, 'amount'>
    ) }
  ), items: Array<(
    { __typename?: 'WholeSaleOrderItem' }
    & Pick<WholeSaleOrderItem, 'id'>
    & { listing: (
      { __typename?: 'WholeSaleListing' }
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name'>
      ) }
    ) }
  )> }
);

export type AccountOrdersListFragment = AccountOrdersListFragment_FulfillmentOrder_ | AccountOrdersListFragment_ShipmentOrder_ | AccountOrdersListFragment_WholeSaleOrder_;

export type AccountUpdateFormFragment = (
  { __typename?: 'User' }
  & Pick<User, 'firstName' | 'lastName' | 'businessName' | 'displayName' | 'email' | 'phone' | 'defaultTerms' | 'operationId' | 'federalShellfishNumber'>
  & { avatar: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )>, billingAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'street' | 'city' | 'state' | 'postalCode'>
  ), shippingAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'street' | 'city' | 'state' | 'postalCode'>
  ) }
);

export type TagsInputQueryVariables = Exact<{ [key: string]: never; }>;


export type TagsInputQuery = (
  { __typename?: 'Query' }
  & { tags: (
    { __typename?: 'TagsConnection' }
    & { nodes: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )> }
  ) }
);

export type AddListingFormProductFragment = (
  { __typename?: 'Product' }
  & Pick<Product, 'id' | 'name' | 'type'>
);

type AddListingSuccessFragment_TransportationListing_ = (
  { __typename: 'TransportationListing' }
  & Pick<TransportationListing, 'id'>
  & { quantity: (
    { __typename?: 'Quantity' }
    & Pick<Quantity, 'amount' | 'unit'>
  ), product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
  ) }
);

type AddListingSuccessFragment_ForSaleListing_ = (
  { __typename: 'ForSaleListing' }
  & Pick<ForSaleListing, 'id'>
  & { price: (
    { __typename?: 'PricePerUnit' }
    & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
  ), quantity: (
    { __typename?: 'Quantity' }
    & Pick<Quantity, 'amount' | 'unit'>
  ), availability: (
    { __typename?: 'ListingAvailability' }
    & Pick<ListingAvailability, 'estimated'>
  ), product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
  ) }
);

type AddListingSuccessFragment_WantedListing_ = (
  { __typename: 'WantedListing' }
  & Pick<WantedListing, 'id'>
  & { price: (
    { __typename?: 'PricePerUnit' }
    & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
  ), quantity: (
    { __typename?: 'Quantity' }
    & Pick<Quantity, 'amount' | 'unit'>
  ), availability: (
    { __typename?: 'ListingAvailability' }
    & Pick<ListingAvailability, 'estimated'>
  ), product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
  ) }
);

type AddListingSuccessFragment_WholeSaleListing_ = (
  { __typename: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'id'>
  & { wholesalePrice: (
    { __typename?: 'WholesalePricePerUnit' }
    & Pick<WholesalePricePerUnit, 'amount' | 'currency' | 'unit'>
  ), product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
  ) }
);

export type AddListingSuccessFragment = AddListingSuccessFragment_TransportationListing_ | AddListingSuccessFragment_ForSaleListing_ | AddListingSuccessFragment_WantedListing_ | AddListingSuccessFragment_WholeSaleListing_;

export type CommonCartUserCartDetailsFragment = (
  { __typename?: 'CartUser' }
  & Pick<CartUser, 'id' | 'displayName' | 'averageRating' | 'businessName' | 'slug'>
  & { avatar: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )> }
);

export type CartDetailsFragment = (
  { __typename?: 'Cart' }
  & Pick<Cart, 'id' | 'stage' | 'requestedDeliveryDate' | 'lastDeclinedReason'>
  & { toUser: (
    { __typename?: 'CartUser' }
    & CommonCartUserCartDetailsFragment
  ), fromUser: (
    { __typename?: 'CartUser' }
    & CommonCartUserCartDetailsFragment
  ), items: Array<(
    { __typename?: 'CartItem' }
    & Pick<CartItem, 'id'>
    & CartItemFragment
  )> }
);

export type CartHeaderFragment = (
  { __typename?: 'Cart' }
  & Pick<Cart, 'id' | 'stage'>
  & { totalPrice: (
    { __typename?: 'CartPrice' }
    & Pick<CartPrice, 'amount' | 'currency' | 'isHidden'>
  ), items: Array<(
    { __typename?: 'CartItem' }
    & Pick<CartItem, 'id'>
  )> }
);

export type CartListSliderItemFragment = (
  { __typename?: 'Cart' }
  & Pick<Cart, 'id' | 'stage'>
  & { totalPrice: (
    { __typename?: 'CartPrice' }
    & Pick<CartPrice, 'amount' | 'currency'>
  ), fromUser: (
    { __typename?: 'CartUser' }
    & Pick<CartUser, 'id' | 'displayName' | 'businessName' | 'slug'>
  ), toUser: (
    { __typename?: 'CartUser' }
    & Pick<CartUser, 'id' | 'displayName' | 'businessName' | 'slug'>
  ), items: Array<(
    { __typename?: 'CartItem' }
    & Pick<CartItem, 'id'>
    & { listing: (
      { __typename?: 'WholeSaleListing' }
      & Pick<WholeSaleListing, 'id'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'type'>
        & { image: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )> }
      ) }
    ) }
  )> }
);

export type CartRequestCheckoutActionFragment = (
  { __typename?: 'Cart' }
  & Pick<Cart, 'id'>
  & CartRequestCheckoutFormFragment
);

export type CartRequestCheckoutFormFragment = (
  { __typename?: 'Cart' }
  & Pick<Cart, 'id' | 'requestedDeliveryDate'>
);

export type CartItemFragment = (
  { __typename?: 'CartItem' }
  & Pick<CartItem, 'id' | 'notes' | 'entitlements'>
  & { listing: (
    { __typename?: 'WholeSaleListing' }
    & Pick<WholeSaleListing, 'id' | 'cutOrSize'>
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'type'>
      & { image: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )> }
    ), tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )> }
  ), quantity: (
    { __typename?: 'Quantity' }
    & Pick<Quantity, 'amount' | 'unit'>
  ), price: (
    { __typename?: 'CartItemPrice' }
    & Pick<CartItemPrice, 'amount' | 'currency' | 'unit' | 'isVariable' | 'isHidden'>
  ), totalPrice: (
    { __typename?: 'CartPrice' }
    & Pick<CartPrice, 'amount' | 'currency'>
  ) }
);

export type DwollaAccountFormFragment = (
  { __typename?: 'User' }
  & Pick<User, 'businessName' | 'businessType' | 'ein' | 'dateOfBirth' | 'ssn4'>
  & { industryClassification: Maybe<(
    { __typename?: 'IndustryClassification' }
    & Pick<IndustryClassification, 'id' | 'name'>
  )>, dwollaController: Maybe<(
    { __typename?: 'DwollaController' }
    & Pick<DwollaController, 'id'>
    & DwollaControllerFormFragment
  )>, dwollaCustomer: Maybe<(
    { __typename?: 'DwollaCustomer' }
    & Pick<DwollaCustomer, 'id' | 'status'>
  )> }
);

export type DwollaControllerFormFragment = (
  { __typename?: 'DwollaController' }
  & Pick<DwollaController, 'id' | 'firstName' | 'lastName' | 'title' | 'dateOfBirth' | 'ssn4'>
  & { address: (
    { __typename?: 'DwollaControllerAddress' }
    & Pick<DwollaControllerAddress, 'address1' | 'address2' | 'city' | 'stateProvinceRegion' | 'postalCode' | 'country'>
  ) }
);

export type DwollaDocumentUploadFragment = (
  { __typename?: 'DwollaCustomer' }
  & Pick<DwollaCustomer, 'id'>
  & { entitlements: Maybe<(
    { __typename?: 'DwollaCustomerEntitlements' }
    & Pick<DwollaCustomerEntitlements, 'needToVerifyWithDocument' | 'needToVerifyBusinessWithDocument'>
  )> }
);

export type ListingTypeFacetQueryVariables = Exact<{ [key: string]: never; }>;


export type ListingTypeFacetQuery = (
  { __typename?: 'Query' }
  & { forSale: (
    { __typename?: 'ListingsConnection' }
    & Pick<ListingsConnection, 'totalCount'>
  ), wholesale: (
    { __typename?: 'ListingsConnection' }
    & Pick<ListingsConnection, 'totalCount'>
  ), wanted: (
    { __typename?: 'ListingsConnection' }
    & Pick<ListingsConnection, 'totalCount'>
  ), transportation: (
    { __typename?: 'ListingsConnection' }
    & Pick<ListingsConnection, 'totalCount'>
  ) }
);

export type TagsFacetQueryVariables = Exact<{ [key: string]: never; }>;


export type TagsFacetQuery = (
  { __typename?: 'Query' }
  & { tags: (
    { __typename?: 'TagsConnection' }
    & { nodes: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )> }
  ) }
);

type ListingDetails_TransportationListing_Fragment = (
  { __typename?: 'TransportationListing' }
  & Pick<TransportationListing, 'pickupDateTime' | 'moveByDateTime' | 'deliverByDateTime' | 'productPackagingAndStorage' | 'equipmentRequirements' | 'estimatedTravelDistance' | 'id' | 'description' | 'terms'>
  & { pickupAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'city' | 'state'>
  ), deliveryAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'city' | 'state'>
  ), postedAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'city' | 'state'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )> }
  & ListingPoster_TransportationListing_Fragment
);

type ListingDetails_ForSaleListing_Fragment = (
  { __typename?: 'ForSaleListing' }
  & Pick<ForSaleListing, 'estimatedHarvestDate' | 'deliveryAvailability' | 'id' | 'description' | 'terms'>
  & { availability: (
    { __typename?: 'ListingAvailability' }
    & Pick<ListingAvailability, 'year' | 'rangeStart' | 'rangeEnd'>
  ), postedAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'city' | 'state'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )> }
  & ListingPoster_ForSaleListing_Fragment
);

type ListingDetails_WantedListing_Fragment = (
  { __typename?: 'WantedListing' }
  & Pick<WantedListing, 'id' | 'description' | 'terms'>
  & { availability: (
    { __typename?: 'ListingAvailability' }
    & Pick<ListingAvailability, 'year' | 'rangeStart' | 'rangeEnd'>
  ), postedAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'city' | 'state'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )> }
  & ListingPoster_WantedListing_Fragment
);

type ListingDetails_WholeSaleListing_Fragment = (
  { __typename?: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'estimatedHarvestDate' | 'upc' | 'deliveryAvailability' | 'itemId' | 'id' | 'description' | 'terms'>
  & { msrp: Maybe<(
    { __typename?: 'PricePerUnit' }
    & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
  )>, wholesalePrice: (
    { __typename?: 'WholesalePricePerUnit' }
    & Pick<WholesalePricePerUnit, 'isVariable'>
  ), postedAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'city' | 'state'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )> }
  & ListingPoster_WholeSaleListing_Fragment
);

export type ListingDetailsFragment = ListingDetails_TransportationListing_Fragment | ListingDetails_ForSaleListing_Fragment | ListingDetails_WantedListing_Fragment | ListingDetails_WholeSaleListing_Fragment;

type ListingHeader_TransportationListing_Fragment = (
  { __typename?: 'TransportationListing' }
  & Pick<TransportationListing, 'isSold' | 'id' | 'isPublished'>
  & { quantity: (
    { __typename?: 'Quantity' }
    & Pick<Quantity, 'amount' | 'unit'>
  ), product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type ListingHeader_ForSaleListing_Fragment = (
  { __typename?: 'ForSaleListing' }
  & Pick<ForSaleListing, 'isSold' | 'cutOrSize' | 'id' | 'isPublished'>
  & { price: (
    { __typename?: 'PricePerUnit' }
    & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
  ), quantity: (
    { __typename?: 'Quantity' }
    & Pick<Quantity, 'amount' | 'unit'>
  ), product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type ListingHeader_WantedListing_Fragment = (
  { __typename?: 'WantedListing' }
  & Pick<WantedListing, 'isSold' | 'cutOrSize' | 'id' | 'isPublished'>
  & { price: (
    { __typename?: 'PricePerUnit' }
    & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
  ), quantity: (
    { __typename?: 'Quantity' }
    & Pick<Quantity, 'amount' | 'unit'>
  ), product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type ListingHeader_WholeSaleListing_Fragment = (
  { __typename?: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'unit' | 'cutOrSize' | 'id' | 'isPublished'>
  & { wholesalePrice: (
    { __typename?: 'WholesalePricePerUnit' }
    & Pick<WholesalePricePerUnit, 'amount' | 'currency' | 'unit' | 'isHidden'>
  ), product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

export type ListingHeaderFragment = ListingHeader_TransportationListing_Fragment | ListingHeader_ForSaleListing_Fragment | ListingHeader_WantedListing_Fragment | ListingHeader_WholeSaleListing_Fragment;

type ListingListItem_TransportationListing_Fragment = (
  { __typename: 'TransportationListing' }
  & Pick<TransportationListing, 'estimatedTravelDistance' | 'id'>
  & { quantity: (
    { __typename?: 'Quantity' }
    & Pick<Quantity, 'amount' | 'unit'>
  ), pickupAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'city' | 'state'>
  ), deliveryAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'city' | 'state'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )>, product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
  & ListingPoster_TransportationListing_Fragment
);

type ListingListItem_ForSaleListing_Fragment = (
  { __typename: 'ForSaleListing' }
  & Pick<ForSaleListing, 'cutOrSize' | 'id'>
  & { price: (
    { __typename?: 'PricePerUnit' }
    & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
  ), quantity: (
    { __typename?: 'Quantity' }
    & Pick<Quantity, 'amount' | 'unit'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )>, product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
  & ListingPoster_ForSaleListing_Fragment
);

type ListingListItem_WantedListing_Fragment = (
  { __typename: 'WantedListing' }
  & Pick<WantedListing, 'cutOrSize' | 'id'>
  & { price: (
    { __typename?: 'PricePerUnit' }
    & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
  ), quantity: (
    { __typename?: 'Quantity' }
    & Pick<Quantity, 'amount' | 'unit'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )>, product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
  & ListingPoster_WantedListing_Fragment
);

type ListingListItem_WholeSaleListing_Fragment = (
  { __typename: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'cutOrSize' | 'id'>
  & { wholesalePrice: (
    { __typename?: 'WholesalePricePerUnit' }
    & Pick<WholesalePricePerUnit, 'amount' | 'currency' | 'unit' | 'isHidden'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )>, product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
  & ListingPoster_WholeSaleListing_Fragment
);

export type ListingListItemFragment = ListingListItem_TransportationListing_Fragment | ListingListItem_ForSaleListing_Fragment | ListingListItem_WantedListing_Fragment | ListingListItem_WholeSaleListing_Fragment;

type ListingListSliderItem_TransportationListing_Fragment = (
  { __typename: 'TransportationListing' }
  & Pick<TransportationListing, 'estimatedTravelDistance' | 'id'>
  & { quantity: (
    { __typename?: 'Quantity' }
    & Pick<Quantity, 'amount' | 'unit'>
  ), pickupAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'city' | 'state'>
  ), deliveryAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'city' | 'state'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )>, product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
  & ListingPoster_TransportationListing_Fragment
);

type ListingListSliderItem_ForSaleListing_Fragment = (
  { __typename: 'ForSaleListing' }
  & Pick<ForSaleListing, 'cutOrSize' | 'id'>
  & { price: (
    { __typename?: 'PricePerUnit' }
    & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
  ), quantity: (
    { __typename?: 'Quantity' }
    & Pick<Quantity, 'amount' | 'unit'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )>, product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
  & ListingPoster_ForSaleListing_Fragment
);

type ListingListSliderItem_WantedListing_Fragment = (
  { __typename: 'WantedListing' }
  & Pick<WantedListing, 'cutOrSize' | 'id'>
  & { price: (
    { __typename?: 'PricePerUnit' }
    & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
  ), quantity: (
    { __typename?: 'Quantity' }
    & Pick<Quantity, 'amount' | 'unit'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )>, product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
  & ListingPoster_WantedListing_Fragment
);

type ListingListSliderItem_WholeSaleListing_Fragment = (
  { __typename: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'cutOrSize' | 'id'>
  & { wholesalePrice: (
    { __typename?: 'WholesalePricePerUnit' }
    & Pick<WholesalePricePerUnit, 'amount' | 'currency' | 'unit' | 'isHidden'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )>, product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
  & ListingPoster_WholeSaleListing_Fragment
);

export type ListingListSliderItemFragment = ListingListSliderItem_TransportationListing_Fragment | ListingListSliderItem_ForSaleListing_Fragment | ListingListSliderItem_WantedListing_Fragment | ListingListSliderItem_WholeSaleListing_Fragment;

type ListingMapFragment_TransportationListing_ = (
  { __typename?: 'TransportationListing' }
  & Pick<TransportationListing, 'id'>
  & { pickupAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'city' | 'state'>
    & { location: (
      { __typename?: 'PointGeometry' }
      & Pick<PointGeometry, 'type' | 'coordinates'>
    ) }
  ), deliveryAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'city' | 'state'>
    & { location: (
      { __typename?: 'PointGeometry' }
      & Pick<PointGeometry, 'type' | 'coordinates'>
    ) }
  ), postedAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'city' | 'state' | 'postalCode'>
    & { location: (
      { __typename?: 'PointGeometry' }
      & Pick<PointGeometry, 'type' | 'coordinates'>
    ) }
  ) }
);

type ListingMapFragment_ForSaleListing_ = (
  { __typename?: 'ForSaleListing' }
  & Pick<ForSaleListing, 'id'>
  & { postedAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'city' | 'state' | 'postalCode'>
    & { location: (
      { __typename?: 'PointGeometry' }
      & Pick<PointGeometry, 'type' | 'coordinates'>
    ) }
  ) }
);

type ListingMapFragment_WantedListing_ = (
  { __typename?: 'WantedListing' }
  & Pick<WantedListing, 'id'>
  & { postedAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'city' | 'state' | 'postalCode'>
    & { location: (
      { __typename?: 'PointGeometry' }
      & Pick<PointGeometry, 'type' | 'coordinates'>
    ) }
  ) }
);

type ListingMapFragment_WholeSaleListing_ = (
  { __typename?: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'id'>
  & { postedAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'city' | 'state' | 'postalCode'>
    & { location: (
      { __typename?: 'PointGeometry' }
      & Pick<PointGeometry, 'type' | 'coordinates'>
    ) }
  ) }
);

export type ListingMapFragment = ListingMapFragment_TransportationListing_ | ListingMapFragment_ForSaleListing_ | ListingMapFragment_WantedListing_ | ListingMapFragment_WholeSaleListing_;

export type ListingAddressMapFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'id' | 'city' | 'state' | 'postalCode'>
  & { location: (
    { __typename?: 'PointGeometry' }
    & Pick<PointGeometry, 'type' | 'coordinates'>
  ) }
);

type ListingPoster_TransportationListing_Fragment = (
  { __typename: 'TransportationListing' }
  & Pick<TransportationListing, 'id'>
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id' | 'displayName' | 'averageRating' | 'businessName' | 'slug'>
    & { avatar: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type ListingPoster_ForSaleListing_Fragment = (
  { __typename: 'ForSaleListing' }
  & Pick<ForSaleListing, 'id'>
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id' | 'displayName' | 'averageRating' | 'businessName' | 'slug'>
    & { avatar: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type ListingPoster_WantedListing_Fragment = (
  { __typename: 'WantedListing' }
  & Pick<WantedListing, 'id'>
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id' | 'displayName' | 'averageRating' | 'businessName' | 'slug'>
    & { avatar: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type ListingPoster_WholeSaleListing_Fragment = (
  { __typename: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'id'>
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id' | 'displayName' | 'averageRating' | 'businessName' | 'slug'>
    & { avatar: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

export type ListingPosterFragment = ListingPoster_TransportationListing_Fragment | ListingPoster_ForSaleListing_Fragment | ListingPoster_WantedListing_Fragment | ListingPoster_WholeSaleListing_Fragment;

export type CommonOfferUserOfferCounterPartyFragment = (
  { __typename?: 'OfferUser' }
  & Pick<OfferUser, 'id' | 'displayName' | 'averageRating' | 'businessName' | 'slug'>
  & { avatar: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )> }
);

type CommonListingOfferCounterPartyFragment_TransportationListing_ = (
  { __typename: 'TransportationListing' }
  & Pick<TransportationListing, 'id'>
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id'>
  ) }
);

type CommonListingOfferCounterPartyFragment_ForSaleListing_ = (
  { __typename: 'ForSaleListing' }
  & Pick<ForSaleListing, 'id'>
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id'>
  ) }
);

type CommonListingOfferCounterPartyFragment_WantedListing_ = (
  { __typename: 'WantedListing' }
  & Pick<WantedListing, 'id'>
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id'>
  ) }
);

type CommonListingOfferCounterPartyFragment_WholeSaleListing_ = (
  { __typename: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'id'>
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id'>
  ) }
);

export type CommonListingOfferCounterPartyFragment = CommonListingOfferCounterPartyFragment_TransportationListing_ | CommonListingOfferCounterPartyFragment_ForSaleListing_ | CommonListingOfferCounterPartyFragment_WantedListing_ | CommonListingOfferCounterPartyFragment_WholeSaleListing_;

type OfferCounterPartyFragment_PerUnitOffer_ = (
  { __typename?: 'PerUnitOffer' }
  & Pick<PerUnitOffer, 'id'>
  & { listing: (
    { __typename?: 'ForSaleListing' }
    & CommonListingOfferCounterPartyFragment_ForSaleListing_
  ) | (
    { __typename?: 'WantedListing' }
    & CommonListingOfferCounterPartyFragment_WantedListing_
  ), fromUser: (
    { __typename?: 'OfferUser' }
    & CommonOfferUserOfferCounterPartyFragment
  ), toUser: (
    { __typename?: 'OfferUser' }
    & CommonOfferUserOfferCounterPartyFragment
  ), previousOffer: Maybe<(
    { __typename?: 'PerUnitOffer' }
    & Pick<PerUnitOffer, 'id'>
  ) | (
    { __typename?: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id'>
  )> }
);

type OfferCounterPartyFragment_FullPriceOffer_ = (
  { __typename?: 'FullPriceOffer' }
  & Pick<FullPriceOffer, 'id'>
  & { listing: (
    { __typename?: 'TransportationListing' }
    & CommonListingOfferCounterPartyFragment_TransportationListing_
  ), fromUser: (
    { __typename?: 'OfferUser' }
    & CommonOfferUserOfferCounterPartyFragment
  ), toUser: (
    { __typename?: 'OfferUser' }
    & CommonOfferUserOfferCounterPartyFragment
  ), previousOffer: Maybe<(
    { __typename?: 'PerUnitOffer' }
    & Pick<PerUnitOffer, 'id'>
  ) | (
    { __typename?: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id'>
  )> }
);

export type OfferCounterPartyFragment = OfferCounterPartyFragment_PerUnitOffer_ | OfferCounterPartyFragment_FullPriceOffer_;

type CommonOfferDetailsFragment_PerUnitOffer_ = (
  { __typename?: 'PerUnitOffer' }
  & Pick<PerUnitOffer, 'id' | 'notes'>
  & { previousOffer: Maybe<(
    { __typename?: 'PerUnitOffer' }
    & Pick<PerUnitOffer, 'id'>
  ) | (
    { __typename?: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id'>
  )>, fromUser: (
    { __typename?: 'OfferUser' }
    & Pick<OfferUser, 'id' | 'displayName' | 'averageRating' | 'businessName' | 'slug'>
    & { avatar: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
  & OfferListingCreatorFragment_PerUnitOffer_
  & OfferCounterPartyFragment_PerUnitOffer_
);

type CommonOfferDetailsFragment_FullPriceOffer_ = (
  { __typename?: 'FullPriceOffer' }
  & Pick<FullPriceOffer, 'id' | 'notes'>
  & { previousOffer: Maybe<(
    { __typename?: 'PerUnitOffer' }
    & Pick<PerUnitOffer, 'id'>
  ) | (
    { __typename?: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id'>
  )>, fromUser: (
    { __typename?: 'OfferUser' }
    & Pick<OfferUser, 'id' | 'displayName' | 'averageRating' | 'businessName' | 'slug'>
    & { avatar: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
  & OfferListingCreatorFragment_FullPriceOffer_
  & OfferCounterPartyFragment_FullPriceOffer_
);

export type CommonOfferDetailsFragment = CommonOfferDetailsFragment_PerUnitOffer_ | CommonOfferDetailsFragment_FullPriceOffer_;

type CommonListingOfferDetailsFragment_TransportationListing_ = (
  { __typename?: 'TransportationListing' }
  & Pick<TransportationListing, 'id'>
  & { postedAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'city' | 'state'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )> }
);

type CommonListingOfferDetailsFragment_ForSaleListing_ = (
  { __typename?: 'ForSaleListing' }
  & Pick<ForSaleListing, 'id'>
  & { postedAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'city' | 'state'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )> }
);

type CommonListingOfferDetailsFragment_WantedListing_ = (
  { __typename?: 'WantedListing' }
  & Pick<WantedListing, 'id'>
  & { postedAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'city' | 'state'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )> }
);

type CommonListingOfferDetailsFragment_WholeSaleListing_ = (
  { __typename?: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'id'>
  & { postedAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'city' | 'state'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )> }
);

export type CommonListingOfferDetailsFragment = CommonListingOfferDetailsFragment_TransportationListing_ | CommonListingOfferDetailsFragment_ForSaleListing_ | CommonListingOfferDetailsFragment_WantedListing_ | CommonListingOfferDetailsFragment_WholeSaleListing_;

export type PerUnitOfferDetailsFragment = (
  { __typename?: 'PerUnitOffer' }
  & { price: (
    { __typename?: 'PricePerUnit' }
    & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
  ), totalPrice: (
    { __typename?: 'Price' }
    & Pick<Price, 'amount' | 'currency'>
  ), listing: (
    { __typename?: 'ForSaleListing' }
    & Pick<ForSaleListing, 'description' | 'terms'>
    & { quantity: (
      { __typename?: 'Quantity' }
      & Pick<Quantity, 'amount' | 'unit'>
    ), availability: (
      { __typename?: 'ListingAvailability' }
      & Pick<ListingAvailability, 'rangeStart' | 'rangeEnd' | 'year'>
    ) }
    & CommonListingOfferDetailsFragment_ForSaleListing_
  ) | (
    { __typename?: 'WantedListing' }
    & Pick<WantedListing, 'description' | 'terms'>
    & { quantity: (
      { __typename?: 'Quantity' }
      & Pick<Quantity, 'amount' | 'unit'>
    ), availability: (
      { __typename?: 'ListingAvailability' }
      & Pick<ListingAvailability, 'rangeStart' | 'rangeEnd' | 'year'>
    ) }
    & CommonListingOfferDetailsFragment_WantedListing_
  ) }
  & CommonOfferDetailsFragment_PerUnitOffer_
);

export type FullPriceOfferDetailsFragment = (
  { __typename?: 'FullPriceOffer' }
  & { price: (
    { __typename?: 'Price' }
    & Pick<Price, 'amount' | 'currency'>
  ), listing: (
    { __typename?: 'TransportationListing' }
    & Pick<TransportationListing, 'pickupDateTime' | 'moveByDateTime' | 'deliverByDateTime' | 'productPackagingAndStorage' | 'equipmentRequirements' | 'description' | 'terms' | 'estimatedTravelDistance'>
    & { quantity: (
      { __typename?: 'Quantity' }
      & Pick<Quantity, 'amount' | 'unit'>
    ), pickupAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'city' | 'state'>
    ), deliveryAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'city' | 'state'>
    ) }
    & CommonListingOfferDetailsFragment_TransportationListing_
  ) }
  & CommonOfferDetailsFragment_FullPriceOffer_
);

type CommonListingOfferHeaderFragment_TransportationListing_ = (
  { __typename?: 'TransportationListing' }
  & Pick<TransportationListing, 'id' | 'isPublished'>
  & { product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type CommonListingOfferHeaderFragment_ForSaleListing_ = (
  { __typename?: 'ForSaleListing' }
  & Pick<ForSaleListing, 'id' | 'isPublished'>
  & { product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type CommonListingOfferHeaderFragment_WantedListing_ = (
  { __typename?: 'WantedListing' }
  & Pick<WantedListing, 'id' | 'isPublished'>
  & { product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type CommonListingOfferHeaderFragment_WholeSaleListing_ = (
  { __typename?: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'id' | 'isPublished'>
  & { product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

export type CommonListingOfferHeaderFragment = CommonListingOfferHeaderFragment_TransportationListing_ | CommonListingOfferHeaderFragment_ForSaleListing_ | CommonListingOfferHeaderFragment_WantedListing_ | CommonListingOfferHeaderFragment_WholeSaleListing_;

export type PerUnitOfferHeaderFragment = (
  { __typename?: 'PerUnitOffer' }
  & Pick<PerUnitOffer, 'id'>
  & { previousOffer: Maybe<(
    { __typename?: 'PerUnitOffer' }
    & Pick<PerUnitOffer, 'id'>
  ) | (
    { __typename?: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id'>
  )>, listing: (
    { __typename?: 'ForSaleListing' }
    & Pick<ForSaleListing, 'isSold' | 'cutOrSize'>
    & { price: (
      { __typename?: 'PricePerUnit' }
      & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
    ), quantity: (
      { __typename?: 'Quantity' }
      & Pick<Quantity, 'amount' | 'unit'>
    ) }
    & CommonListingOfferHeaderFragment_ForSaleListing_
  ) | (
    { __typename?: 'WantedListing' }
    & Pick<WantedListing, 'isSold' | 'cutOrSize'>
    & { price: (
      { __typename?: 'PricePerUnit' }
      & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
    ), quantity: (
      { __typename?: 'Quantity' }
      & Pick<Quantity, 'amount' | 'unit'>
    ) }
    & CommonListingOfferHeaderFragment_WantedListing_
  ) }
);

export type FullPriceOfferHeaderFragment = (
  { __typename?: 'FullPriceOffer' }
  & Pick<FullPriceOffer, 'id'>
  & { previousOffer: Maybe<(
    { __typename?: 'PerUnitOffer' }
    & Pick<PerUnitOffer, 'id'>
  ) | (
    { __typename?: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id'>
  )>, listing: (
    { __typename?: 'TransportationListing' }
    & Pick<TransportationListing, 'isSold'>
    & { quantity: (
      { __typename?: 'Quantity' }
      & Pick<Quantity, 'amount' | 'unit'>
    ) }
    & CommonListingOfferHeaderFragment_TransportationListing_
  ) }
);

export type CommonOfferUserOfferListCounterPartyFragment = (
  { __typename?: 'OfferUser' }
  & Pick<OfferUser, 'id' | 'displayName'>
);

type CommonListingOfferListCounterPartyFragment_TransportationListing_ = (
  { __typename: 'TransportationListing' }
  & Pick<TransportationListing, 'id'>
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id'>
  ) }
);

type CommonListingOfferListCounterPartyFragment_ForSaleListing_ = (
  { __typename: 'ForSaleListing' }
  & Pick<ForSaleListing, 'id'>
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id'>
  ) }
);

type CommonListingOfferListCounterPartyFragment_WantedListing_ = (
  { __typename: 'WantedListing' }
  & Pick<WantedListing, 'id'>
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id'>
  ) }
);

type CommonListingOfferListCounterPartyFragment_WholeSaleListing_ = (
  { __typename: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'id'>
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id'>
  ) }
);

export type CommonListingOfferListCounterPartyFragment = CommonListingOfferListCounterPartyFragment_TransportationListing_ | CommonListingOfferListCounterPartyFragment_ForSaleListing_ | CommonListingOfferListCounterPartyFragment_WantedListing_ | CommonListingOfferListCounterPartyFragment_WholeSaleListing_;

type OfferListCounterPartyFragment_PerUnitOffer_ = (
  { __typename?: 'PerUnitOffer' }
  & Pick<PerUnitOffer, 'id'>
  & { listing: (
    { __typename?: 'ForSaleListing' }
    & CommonListingOfferListCounterPartyFragment_ForSaleListing_
  ) | (
    { __typename?: 'WantedListing' }
    & CommonListingOfferListCounterPartyFragment_WantedListing_
  ), fromUser: (
    { __typename?: 'OfferUser' }
    & CommonOfferUserOfferListCounterPartyFragment
  ), toUser: (
    { __typename?: 'OfferUser' }
    & CommonOfferUserOfferListCounterPartyFragment
  ) }
);

type OfferListCounterPartyFragment_FullPriceOffer_ = (
  { __typename?: 'FullPriceOffer' }
  & Pick<FullPriceOffer, 'id'>
  & { listing: (
    { __typename?: 'TransportationListing' }
    & CommonListingOfferListCounterPartyFragment_TransportationListing_
  ), fromUser: (
    { __typename?: 'OfferUser' }
    & CommonOfferUserOfferListCounterPartyFragment
  ), toUser: (
    { __typename?: 'OfferUser' }
    & CommonOfferUserOfferListCounterPartyFragment
  ) }
);

export type OfferListCounterPartyFragment = OfferListCounterPartyFragment_PerUnitOffer_ | OfferListCounterPartyFragment_FullPriceOffer_;

type CommonListingOfferListSliderFragment_TransportationListing_ = (
  { __typename?: 'TransportationListing' }
  & Pick<TransportationListing, 'id'>
  & { product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type CommonListingOfferListSliderFragment_ForSaleListing_ = (
  { __typename?: 'ForSaleListing' }
  & Pick<ForSaleListing, 'id'>
  & { product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type CommonListingOfferListSliderFragment_WantedListing_ = (
  { __typename?: 'WantedListing' }
  & Pick<WantedListing, 'id'>
  & { product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type CommonListingOfferListSliderFragment_WholeSaleListing_ = (
  { __typename?: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'id'>
  & { product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

export type CommonListingOfferListSliderFragment = CommonListingOfferListSliderFragment_TransportationListing_ | CommonListingOfferListSliderFragment_ForSaleListing_ | CommonListingOfferListSliderFragment_WantedListing_ | CommonListingOfferListSliderFragment_WholeSaleListing_;

type OfferListSliderItemFragment_PerUnitOffer_ = (
  { __typename?: 'PerUnitOffer' }
  & Pick<PerUnitOffer, 'id' | 'stage'>
  & { price: (
    { __typename?: 'PricePerUnit' }
    & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
  ), listing: (
    { __typename?: 'ForSaleListing' }
    & Pick<ForSaleListing, 'cutOrSize'>
    & { price: (
      { __typename?: 'PricePerUnit' }
      & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
    ), quantity: (
      { __typename?: 'Quantity' }
      & Pick<Quantity, 'amount' | 'unit'>
    ) }
    & CommonListingOfferListSliderFragment_ForSaleListing_
  ) | (
    { __typename?: 'WantedListing' }
    & Pick<WantedListing, 'cutOrSize'>
    & { price: (
      { __typename?: 'PricePerUnit' }
      & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
    ), quantity: (
      { __typename?: 'Quantity' }
      & Pick<Quantity, 'amount' | 'unit'>
    ) }
    & CommonListingOfferListSliderFragment_WantedListing_
  ), previousOffer: Maybe<(
    { __typename?: 'PerUnitOffer' }
    & Pick<PerUnitOffer, 'id'>
  ) | (
    { __typename?: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id'>
  )> }
  & OfferListCounterPartyFragment_PerUnitOffer_
);

type OfferListSliderItemFragment_FullPriceOffer_ = (
  { __typename?: 'FullPriceOffer' }
  & Pick<FullPriceOffer, 'id' | 'stage'>
  & { price: (
    { __typename?: 'Price' }
    & Pick<Price, 'amount' | 'currency'>
  ), listing: (
    { __typename?: 'TransportationListing' }
    & { quantity: (
      { __typename?: 'Quantity' }
      & Pick<Quantity, 'amount' | 'unit'>
    ) }
    & CommonListingOfferListSliderFragment_TransportationListing_
  ), previousOffer: Maybe<(
    { __typename?: 'PerUnitOffer' }
    & Pick<PerUnitOffer, 'id'>
  ) | (
    { __typename?: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id'>
  )> }
  & OfferListCounterPartyFragment_FullPriceOffer_
);

export type OfferListSliderItemFragment = OfferListSliderItemFragment_PerUnitOffer_ | OfferListSliderItemFragment_FullPriceOffer_;

type CommonListingOfferListingCreatorFragment_TransportationListing_ = (
  { __typename: 'TransportationListing' }
  & Pick<TransportationListing, 'id'>
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id' | 'displayName' | 'averageRating' | 'businessName' | 'slug'>
    & { avatar: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type CommonListingOfferListingCreatorFragment_ForSaleListing_ = (
  { __typename: 'ForSaleListing' }
  & Pick<ForSaleListing, 'id'>
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id' | 'displayName' | 'averageRating' | 'businessName' | 'slug'>
    & { avatar: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type CommonListingOfferListingCreatorFragment_WantedListing_ = (
  { __typename: 'WantedListing' }
  & Pick<WantedListing, 'id'>
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id' | 'displayName' | 'averageRating' | 'businessName' | 'slug'>
    & { avatar: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type CommonListingOfferListingCreatorFragment_WholeSaleListing_ = (
  { __typename: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'id'>
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id' | 'displayName' | 'averageRating' | 'businessName' | 'slug'>
    & { avatar: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

export type CommonListingOfferListingCreatorFragment = CommonListingOfferListingCreatorFragment_TransportationListing_ | CommonListingOfferListingCreatorFragment_ForSaleListing_ | CommonListingOfferListingCreatorFragment_WantedListing_ | CommonListingOfferListingCreatorFragment_WholeSaleListing_;

type OfferListingCreatorFragment_PerUnitOffer_ = (
  { __typename?: 'PerUnitOffer' }
  & { listing: (
    { __typename?: 'ForSaleListing' }
    & CommonListingOfferListingCreatorFragment_ForSaleListing_
  ) | (
    { __typename?: 'WantedListing' }
    & CommonListingOfferListingCreatorFragment_WantedListing_
  ) }
);

type OfferListingCreatorFragment_FullPriceOffer_ = (
  { __typename?: 'FullPriceOffer' }
  & { listing: (
    { __typename?: 'TransportationListing' }
    & CommonListingOfferListingCreatorFragment_TransportationListing_
  ) }
);

export type OfferListingCreatorFragment = OfferListingCreatorFragment_PerUnitOffer_ | OfferListingCreatorFragment_FullPriceOffer_;

type OfferTitleFragment_PerUnitOffer_ = (
  { __typename?: 'PerUnitOffer' }
  & Pick<PerUnitOffer, 'id'>
  & { listing: (
    { __typename: 'ForSaleListing' }
    & Pick<ForSaleListing, 'id'>
  ) | (
    { __typename: 'WantedListing' }
    & Pick<WantedListing, 'id'>
  ) }
);

type OfferTitleFragment_FullPriceOffer_ = (
  { __typename?: 'FullPriceOffer' }
  & Pick<FullPriceOffer, 'id'>
  & { listing: (
    { __typename: 'TransportationListing' }
    & Pick<TransportationListing, 'id'>
  ) }
);

export type OfferTitleFragment = OfferTitleFragment_PerUnitOffer_ | OfferTitleFragment_FullPriceOffer_;

export type CommonOrderUserOrderDetailsFulfillmentFragment = (
  { __typename?: 'OrderUser' }
  & Pick<OrderUser, 'id' | 'firstName' | 'lastName' | 'displayName' | 'averageRating' | 'businessName' | 'slug'>
  & { avatar: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )>, billingAddress: (
    { __typename?: 'PublicAddress' }
    & Pick<PublicAddress, 'id' | 'street' | 'city' | 'state' | 'postalCode'>
  ) }
);

export type OrderDetailsFulfillmentFragment = (
  { __typename?: 'FulfillmentOrder' }
  & Pick<FulfillmentOrder, 'id' | 'createdAt' | 'notes' | 'terms'>
  & { offer: (
    { __typename?: 'PerUnitOffer' }
    & Pick<PerUnitOffer, 'id'>
    & { listing: (
      { __typename?: 'ForSaleListing' }
      & Pick<ForSaleListing, 'id'>
      & { tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name'>
      )> }
    ) | (
      { __typename?: 'WantedListing' }
      & Pick<WantedListing, 'id'>
      & { tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name'>
      )> }
    ) }
  ), quantity: (
    { __typename?: 'Quantity' }
    & Pick<Quantity, 'amount' | 'unit'>
  ), price: (
    { __typename?: 'PricePerUnit' }
    & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
  ), totalPrice: (
    { __typename?: 'Price' }
    & Pick<Price, 'amount' | 'currency'>
  ), buyer: (
    { __typename?: 'OrderUser' }
    & CommonOrderUserOrderDetailsFulfillmentFragment
  ), seller: (
    { __typename?: 'OrderUser' }
    & CommonOrderUserOrderDetailsFulfillmentFragment
  ) }
);

export type CommonOrderUserOrderDetailsShipmentFragment = (
  { __typename?: 'OrderUser' }
  & Pick<OrderUser, 'id' | 'firstName' | 'lastName' | 'displayName' | 'averageRating' | 'businessName' | 'slug'>
  & { avatar: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )>, billingAddress: (
    { __typename?: 'PublicAddress' }
    & Pick<PublicAddress, 'id' | 'street' | 'city' | 'state' | 'postalCode'>
  ) }
);

export type OrderDetailsShipmentFragment = (
  { __typename?: 'ShipmentOrder' }
  & Pick<ShipmentOrder, 'id' | 'createdAt' | 'notes' | 'terms'>
  & { offer: (
    { __typename?: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id'>
    & { listing: (
      { __typename?: 'TransportationListing' }
      & Pick<TransportationListing, 'id' | 'pickupDateTime' | 'moveByDateTime' | 'deliverByDateTime' | 'productPackagingAndStorage' | 'equipmentRequirements' | 'description' | 'terms' | 'estimatedTravelDistance'>
      & { tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name'>
      )>, pickupAddress: (
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'street' | 'city' | 'state' | 'postalCode'>
      ), deliveryAddress: (
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'street' | 'city' | 'state' | 'postalCode'>
      ) }
    ) }
  ), quantity: (
    { __typename?: 'Quantity' }
    & Pick<Quantity, 'amount' | 'unit'>
  ), price: (
    { __typename?: 'Price' }
    & Pick<Price, 'amount' | 'currency'>
  ), transporter: (
    { __typename?: 'OrderUser' }
    & CommonOrderUserOrderDetailsShipmentFragment
  ), requester: (
    { __typename?: 'OrderUser' }
    & CommonOrderUserOrderDetailsShipmentFragment
  ) }
);

export type OrderItemEditActionFormFragment = (
  { __typename?: 'WholeSaleOrderItem' }
  & Pick<WholeSaleOrderItem, 'id'>
  & { listing: (
    { __typename?: 'WholeSaleListing' }
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'name'>
    ) }
  ), price: (
    { __typename?: 'WholesalePricePerUnit' }
    & Pick<WholesalePricePerUnit, 'amount' | 'unit'>
  ), quantity: (
    { __typename?: 'Quantity' }
    & Pick<Quantity, 'amount' | 'unit'>
  ) }
);

export type OrderDetailsWholeSaleItemFragment = (
  { __typename?: 'WholeSaleOrderItem' }
  & Pick<WholeSaleOrderItem, 'id' | 'entitlements' | 'notes'>
  & { order: (
    { __typename?: 'WholeSaleOrder' }
    & Pick<WholeSaleOrder, 'id' | 'deliveredAt'>
  ), listing: (
    { __typename?: 'WholeSaleListing' }
    & Pick<WholeSaleListing, 'id' | 'itemId' | 'cutOrSize'>
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'type'>
      & { image: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )> }
    ), tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )> }
  ), quantity: (
    { __typename?: 'Quantity' }
    & Pick<Quantity, 'amount' | 'unit'>
  ), price: (
    { __typename?: 'WholesalePricePerUnit' }
    & Pick<WholesalePricePerUnit, 'amount' | 'currency' | 'unit' | 'isVariable' | 'isHidden'>
  ), totalPrice: (
    { __typename?: 'WholesalePrice' }
    & Pick<WholesalePrice, 'amount' | 'currency'>
  ) }
);

export type CommonOrderUserOrderDetailsWholeSaleFragment = (
  { __typename?: 'OrderUser' }
  & Pick<OrderUser, 'id' | 'firstName' | 'lastName' | 'displayName' | 'averageRating' | 'businessName' | 'slug'>
  & { avatar: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )>, billingAddress: (
    { __typename?: 'PublicAddress' }
    & Pick<PublicAddress, 'id' | 'street' | 'city' | 'state' | 'postalCode'>
  ) }
);

export type OrderDetailsWholeSaleFragment = (
  { __typename?: 'WholeSaleOrder' }
  & Pick<WholeSaleOrder, 'id' | 'notes' | 'terms' | 'createdAt'>
  & { items: Array<(
    { __typename?: 'WholeSaleOrderItem' }
    & Pick<WholeSaleOrderItem, 'id'>
    & OrderDetailsWholeSaleItemFragment
  )>, buyer: (
    { __typename?: 'OrderUser' }
    & CommonOrderUserOrderDetailsWholeSaleFragment
  ), seller: (
    { __typename?: 'OrderUser' }
    & CommonOrderUserOrderDetailsWholeSaleFragment
  ) }
);

type OrderDetails_FulfillmentOrder_Fragment = (
  { __typename?: 'FulfillmentOrder' }
  & Pick<FulfillmentOrder, 'id'>
  & OrderDetailsFulfillmentFragment
);

type OrderDetails_ShipmentOrder_Fragment = (
  { __typename?: 'ShipmentOrder' }
  & Pick<ShipmentOrder, 'id'>
  & OrderDetailsShipmentFragment
);

type OrderDetails_WholeSaleOrder_Fragment = (
  { __typename?: 'WholeSaleOrder' }
  & Pick<WholeSaleOrder, 'id'>
  & OrderDetailsWholeSaleFragment
);

export type OrderDetailsFragment = OrderDetails_FulfillmentOrder_Fragment | OrderDetails_ShipmentOrder_Fragment | OrderDetails_WholeSaleOrder_Fragment;

type OrderEditFragment_FulfillmentOrder_ = (
  { __typename?: 'FulfillmentOrder' }
  & Pick<FulfillmentOrder, 'id' | 'notes' | 'terms'>
);

type OrderEditFragment_ShipmentOrder_ = (
  { __typename?: 'ShipmentOrder' }
  & Pick<ShipmentOrder, 'id' | 'notes' | 'terms'>
);

type OrderEditFragment_WholeSaleOrder_ = (
  { __typename?: 'WholeSaleOrder' }
  & Pick<WholeSaleOrder, 'id' | 'notes' | 'terms'>
);

export type OrderEditFragment = OrderEditFragment_FulfillmentOrder_ | OrderEditFragment_ShipmentOrder_ | OrderEditFragment_WholeSaleOrder_;

type OrderFulfillActionFragment_FulfillmentOrder_ = (
  { __typename?: 'FulfillmentOrder' }
  & Pick<FulfillmentOrder, 'id'>
);

type OrderFulfillActionFragment_ShipmentOrder_ = (
  { __typename?: 'ShipmentOrder' }
  & Pick<ShipmentOrder, 'id'>
);

type OrderFulfillActionFragment_WholeSaleOrder_ = (
  { __typename?: 'WholeSaleOrder' }
  & Pick<WholeSaleOrder, 'id'>
);

export type OrderFulfillActionFragment = OrderFulfillActionFragment_FulfillmentOrder_ | OrderFulfillActionFragment_ShipmentOrder_ | OrderFulfillActionFragment_WholeSaleOrder_;

export type OrderFulfillActionFormDwollaCustomerQueryVariables = Exact<{ [key: string]: never; }>;


export type OrderFulfillActionFormDwollaCustomerQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { dwollaCustomer: Maybe<(
      { __typename?: 'DwollaCustomer' }
      & Pick<DwollaCustomer, 'id'>
      & { entitlements: Maybe<(
        { __typename?: 'DwollaCustomerEntitlements' }
        & Pick<DwollaCustomerEntitlements, 'canReceivePayments'>
      )> }
    )> }
  )> }
);

export type OrderHeaderWholeSaleFragment = (
  { __typename?: 'WholeSaleOrder' }
  & Pick<WholeSaleOrder, 'id' | 'shortId' | 'deliveredAt' | 'paid'>
  & { wholesaleTotalPrice: (
    { __typename?: 'WholesalePrice' }
    & Pick<WholesalePrice, 'amount' | 'currency' | 'isHidden'>
  ), items: Array<(
    { __typename?: 'WholeSaleOrderItem' }
    & Pick<WholeSaleOrderItem, 'id'>
  )> }
);

type CommonListingOrderHeaderFragment_TransportationListing_ = (
  { __typename?: 'TransportationListing' }
  & Pick<TransportationListing, 'id'>
  & { product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type CommonListingOrderHeaderFragment_ForSaleListing_ = (
  { __typename?: 'ForSaleListing' }
  & Pick<ForSaleListing, 'id'>
  & { product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type CommonListingOrderHeaderFragment_WantedListing_ = (
  { __typename?: 'WantedListing' }
  & Pick<WantedListing, 'id'>
  & { product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type CommonListingOrderHeaderFragment_WholeSaleListing_ = (
  { __typename?: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'id'>
  & { product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

export type CommonListingOrderHeaderFragment = CommonListingOrderHeaderFragment_TransportationListing_ | CommonListingOrderHeaderFragment_ForSaleListing_ | CommonListingOrderHeaderFragment_WantedListing_ | CommonListingOrderHeaderFragment_WholeSaleListing_;

type OrderHeader_FulfillmentOrder_Fragment = (
  { __typename: 'FulfillmentOrder' }
  & Pick<FulfillmentOrder, 'id' | 'shortId' | 'deliveredAt' | 'paid'>
  & { quantity: (
    { __typename?: 'Quantity' }
    & Pick<Quantity, 'amount' | 'unit'>
  ), price: (
    { __typename?: 'PricePerUnit' }
    & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
  ), offer: (
    { __typename?: 'PerUnitOffer' }
    & Pick<PerUnitOffer, 'id'>
    & { listing: (
      { __typename?: 'ForSaleListing' }
      & Pick<ForSaleListing, 'cutOrSize'>
      & CommonListingOrderHeaderFragment_ForSaleListing_
    ) | (
      { __typename?: 'WantedListing' }
      & Pick<WantedListing, 'cutOrSize'>
      & CommonListingOrderHeaderFragment_WantedListing_
    ) }
  ) }
);

type OrderHeader_ShipmentOrder_Fragment = (
  { __typename: 'ShipmentOrder' }
  & Pick<ShipmentOrder, 'id' | 'shortId' | 'deliveredAt' | 'paid'>
  & { quantity: (
    { __typename?: 'Quantity' }
    & Pick<Quantity, 'amount' | 'unit'>
  ), price: (
    { __typename?: 'Price' }
    & Pick<Price, 'amount' | 'currency'>
  ), offer: (
    { __typename?: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id'>
    & { listing: (
      { __typename?: 'TransportationListing' }
      & CommonListingOrderHeaderFragment_TransportationListing_
    ) }
  ) }
);

type OrderHeader_WholeSaleOrder_Fragment = (
  { __typename: 'WholeSaleOrder' }
  & Pick<WholeSaleOrder, 'id' | 'shortId' | 'deliveredAt' | 'paid'>
  & OrderHeaderWholeSaleFragment
);

export type OrderHeaderFragment = OrderHeader_FulfillmentOrder_Fragment | OrderHeader_ShipmentOrder_Fragment | OrderHeader_WholeSaleOrder_Fragment;

type OrderLabelFragment_FulfillmentOrder_ = (
  { __typename: 'FulfillmentOrder' }
  & Pick<FulfillmentOrder, 'id'>
);

type OrderLabelFragment_ShipmentOrder_ = (
  { __typename: 'ShipmentOrder' }
  & Pick<ShipmentOrder, 'id'>
);

type OrderLabelFragment_WholeSaleOrder_ = (
  { __typename: 'WholeSaleOrder' }
  & Pick<WholeSaleOrder, 'id'>
);

export type OrderLabelFragment = OrderLabelFragment_FulfillmentOrder_ | OrderLabelFragment_ShipmentOrder_ | OrderLabelFragment_WholeSaleOrder_;

type OrderLink_FulfillmentOrder_Fragment = (
  { __typename?: 'FulfillmentOrder' }
  & Pick<FulfillmentOrder, 'id'>
);

type OrderLink_ShipmentOrder_Fragment = (
  { __typename?: 'ShipmentOrder' }
  & Pick<ShipmentOrder, 'id'>
);

type OrderLink_WholeSaleOrder_Fragment = (
  { __typename?: 'WholeSaleOrder' }
  & Pick<WholeSaleOrder, 'id'>
);

export type OrderLinkFragment = OrderLink_FulfillmentOrder_Fragment | OrderLink_ShipmentOrder_Fragment | OrderLink_WholeSaleOrder_Fragment;

export type CommonOrderUserOrderListSliderItemFulfillmentFragment = (
  { __typename?: 'OrderUser' }
  & Pick<OrderUser, 'id' | 'displayName'>
);

export type OrderListSliderItemFulfillmentFragment = (
  { __typename?: 'FulfillmentOrder' }
  & Pick<FulfillmentOrder, 'id' | 'deliveredAt' | 'paid'>
  & { buyer: (
    { __typename?: 'OrderUser' }
    & CommonOrderUserOrderListSliderItemFulfillmentFragment
  ), seller: (
    { __typename?: 'OrderUser' }
    & CommonOrderUserOrderListSliderItemFulfillmentFragment
  ), offer: (
    { __typename?: 'PerUnitOffer' }
    & Pick<PerUnitOffer, 'id' | 'stage'>
    & { price: (
      { __typename?: 'PricePerUnit' }
      & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
    ), listing: (
      { __typename?: 'ForSaleListing' }
      & Pick<ForSaleListing, 'id' | 'cutOrSize'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'type'>
        & { image: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )> }
      ), quantity: (
        { __typename?: 'Quantity' }
        & Pick<Quantity, 'amount' | 'unit'>
      ) }
    ) | (
      { __typename?: 'WantedListing' }
      & Pick<WantedListing, 'id' | 'cutOrSize'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'type'>
        & { image: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )> }
      ), quantity: (
        { __typename?: 'Quantity' }
        & Pick<Quantity, 'amount' | 'unit'>
      ) }
    ) }
  ) }
  & OrderLabelFragment_FulfillmentOrder_
);

export type CommonOrderUserOrderListSliderItemShipmentFragment = (
  { __typename?: 'OrderUser' }
  & Pick<OrderUser, 'id' | 'displayName'>
);

export type OrderListSliderItemShipmentFragment = (
  { __typename?: 'ShipmentOrder' }
  & Pick<ShipmentOrder, 'id' | 'deliveredAt' | 'paid'>
  & { requester: (
    { __typename?: 'OrderUser' }
    & CommonOrderUserOrderListSliderItemShipmentFragment
  ), transporter: (
    { __typename?: 'OrderUser' }
    & CommonOrderUserOrderListSliderItemShipmentFragment
  ), offer: (
    { __typename?: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id' | 'stage'>
    & { price: (
      { __typename?: 'Price' }
      & Pick<Price, 'amount' | 'currency'>
    ), listing: (
      { __typename?: 'TransportationListing' }
      & Pick<TransportationListing, 'id'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'type'>
        & { image: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )> }
      ), quantity: (
        { __typename?: 'Quantity' }
        & Pick<Quantity, 'amount' | 'unit'>
      ) }
    ) }
  ) }
  & OrderLabelFragment_ShipmentOrder_
);

export type CommonOrderUserOrderListSliderItemWholeSaleFragment = (
  { __typename?: 'OrderUser' }
  & Pick<OrderUser, 'id' | 'displayName'>
);

export type OrderListSliderItemWholeSaleFragment = (
  { __typename?: 'WholeSaleOrder' }
  & Pick<WholeSaleOrder, 'id' | 'deliveredAt' | 'paid'>
  & { buyer: (
    { __typename?: 'OrderUser' }
    & CommonOrderUserOrderListSliderItemWholeSaleFragment
  ), seller: (
    { __typename?: 'OrderUser' }
    & CommonOrderUserOrderListSliderItemWholeSaleFragment
  ), wholesaleTotalPrice: (
    { __typename?: 'WholesalePrice' }
    & Pick<WholesalePrice, 'amount' | 'currency'>
  ), items: Array<(
    { __typename?: 'WholeSaleOrderItem' }
    & Pick<WholeSaleOrderItem, 'id'>
    & { listing: (
      { __typename?: 'WholeSaleListing' }
      & Pick<WholeSaleListing, 'id'>
      & { product: (
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'type'>
        & { image: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'url'>
        )> }
      ), wholesalePrice: (
        { __typename?: 'WholesalePricePerUnit' }
        & Pick<WholesalePricePerUnit, 'amount' | 'currency' | 'unit'>
      ) }
    ) }
  )> }
  & OrderLabelFragment_WholeSaleOrder_
);

type OrderListSliderItemFragment_FulfillmentOrder_ = (
  { __typename?: 'FulfillmentOrder' }
  & OrderListSliderItemFulfillmentFragment
);

type OrderListSliderItemFragment_ShipmentOrder_ = (
  { __typename?: 'ShipmentOrder' }
  & OrderListSliderItemShipmentFragment
);

type OrderListSliderItemFragment_WholeSaleOrder_ = (
  { __typename?: 'WholeSaleOrder' }
  & OrderListSliderItemWholeSaleFragment
);

export type OrderListSliderItemFragment = OrderListSliderItemFragment_FulfillmentOrder_ | OrderListSliderItemFragment_ShipmentOrder_ | OrderListSliderItemFragment_WholeSaleOrder_;

export type OrderMakePaymentActionFormDwollaFundingSourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type OrderMakePaymentActionFormDwollaFundingSourcesQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & { dwollaCustomer: Maybe<(
      { __typename?: 'DwollaCustomer' }
      & Pick<DwollaCustomer, 'id'>
      & { fundingSources: Array<(
        { __typename?: 'DwollaBankFundingSource' }
        & Pick<DwollaBankFundingSource, 'id' | 'name'>
      ) | (
        { __typename?: 'DwollaBalanceFundingSource' }
        & Pick<DwollaBalanceFundingSource, 'id' | 'name'>
        & { balance: (
          { __typename?: 'DwollaBalance' }
          & Pick<DwollaBalance, 'value' | 'currency'>
        ) }
      )> }
    )> }
  )> }
);

export type OrderMakePaymentActionDwollaFundingSourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type OrderMakePaymentActionDwollaFundingSourcesQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & { dwollaCustomer: Maybe<(
      { __typename?: 'DwollaCustomer' }
      & Pick<DwollaCustomer, 'id'>
      & { fundingSources: Array<(
        { __typename?: 'DwollaBankFundingSource' }
        & Pick<DwollaBankFundingSource, 'id'>
      ) | (
        { __typename?: 'DwollaBalanceFundingSource' }
        & Pick<DwollaBalanceFundingSource, 'id'>
      )> }
    )> }
  )> }
);

type PublicUserDetailsFragment_Merchant_ = (
  { __typename?: 'Merchant' }
  & Pick<Merchant, 'id' | 'averageRating'>
  & { billingAddress: (
    { __typename?: 'PublicAddress' }
    & Pick<PublicAddress, 'id' | 'city' | 'state'>
  ) }
  & PublicUserLockupFragment_Merchant_
  & PublicUserMetricsFragment_Merchant_
);

type PublicUserDetailsFragment_CartUser_ = (
  { __typename?: 'CartUser' }
  & Pick<CartUser, 'id' | 'averageRating'>
  & { billingAddress: (
    { __typename?: 'PublicAddress' }
    & Pick<PublicAddress, 'id' | 'city' | 'state'>
  ) }
  & PublicUserLockupFragment_CartUser_
  & PublicUserMetricsFragment_CartUser_
);

type PublicUserDetailsFragment_ListingUser_ = (
  { __typename?: 'ListingUser' }
  & Pick<ListingUser, 'id' | 'averageRating'>
  & { billingAddress: (
    { __typename?: 'PublicAddress' }
    & Pick<PublicAddress, 'id' | 'city' | 'state'>
  ) }
  & PublicUserLockupFragment_ListingUser_
  & PublicUserMetricsFragment_ListingUser_
);

type PublicUserDetailsFragment_OfferUser_ = (
  { __typename?: 'OfferUser' }
  & Pick<OfferUser, 'id' | 'averageRating'>
  & { billingAddress: (
    { __typename?: 'PublicAddress' }
    & Pick<PublicAddress, 'id' | 'city' | 'state'>
  ) }
  & PublicUserLockupFragment_OfferUser_
  & PublicUserMetricsFragment_OfferUser_
);

type PublicUserDetailsFragment_OrderUser_ = (
  { __typename?: 'OrderUser' }
  & Pick<OrderUser, 'id' | 'averageRating'>
  & { billingAddress: (
    { __typename?: 'PublicAddress' }
    & Pick<PublicAddress, 'id' | 'city' | 'state'>
  ) }
  & PublicUserLockupFragment_OrderUser_
  & PublicUserMetricsFragment_OrderUser_
);

type PublicUserDetailsFragment_ChatUser_ = (
  { __typename?: 'ChatUser' }
  & Pick<ChatUser, 'id' | 'averageRating'>
  & { billingAddress: (
    { __typename?: 'PublicAddress' }
    & Pick<PublicAddress, 'id' | 'city' | 'state'>
  ) }
  & PublicUserLockupFragment_ChatUser_
  & PublicUserMetricsFragment_ChatUser_
);

export type PublicUserDetailsFragment = PublicUserDetailsFragment_Merchant_ | PublicUserDetailsFragment_CartUser_ | PublicUserDetailsFragment_ListingUser_ | PublicUserDetailsFragment_OfferUser_ | PublicUserDetailsFragment_OrderUser_ | PublicUserDetailsFragment_ChatUser_;

type PublicUserLockupFragment_Merchant_ = (
  { __typename?: 'Merchant' }
  & Pick<Merchant, 'displayName'>
  & { avatar: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )> }
);

type PublicUserLockupFragment_CartUser_ = (
  { __typename?: 'CartUser' }
  & Pick<CartUser, 'displayName'>
  & { avatar: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )> }
);

type PublicUserLockupFragment_ListingUser_ = (
  { __typename?: 'ListingUser' }
  & Pick<ListingUser, 'displayName'>
  & { avatar: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )> }
);

type PublicUserLockupFragment_OfferUser_ = (
  { __typename?: 'OfferUser' }
  & Pick<OfferUser, 'displayName'>
  & { avatar: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )> }
);

type PublicUserLockupFragment_OrderUser_ = (
  { __typename?: 'OrderUser' }
  & Pick<OrderUser, 'displayName'>
  & { avatar: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )> }
);

type PublicUserLockupFragment_ChatUser_ = (
  { __typename?: 'ChatUser' }
  & Pick<ChatUser, 'displayName'>
  & { avatar: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )> }
);

export type PublicUserLockupFragment = PublicUserLockupFragment_Merchant_ | PublicUserLockupFragment_CartUser_ | PublicUserLockupFragment_ListingUser_ | PublicUserLockupFragment_OfferUser_ | PublicUserLockupFragment_OrderUser_ | PublicUserLockupFragment_ChatUser_;

type PublicUserMetricsFragment_Merchant_ = (
  { __typename?: 'Merchant' }
  & Pick<Merchant, 'activeListings' | 'ordersFulfilled' | 'averageRating'>
  & { billingAddress: (
    { __typename?: 'PublicAddress' }
    & Pick<PublicAddress, 'id' | 'city' | 'state'>
  ) }
);

type PublicUserMetricsFragment_CartUser_ = (
  { __typename?: 'CartUser' }
  & Pick<CartUser, 'activeListings' | 'ordersFulfilled' | 'averageRating'>
  & { billingAddress: (
    { __typename?: 'PublicAddress' }
    & Pick<PublicAddress, 'id' | 'city' | 'state'>
  ) }
);

type PublicUserMetricsFragment_ListingUser_ = (
  { __typename?: 'ListingUser' }
  & Pick<ListingUser, 'activeListings' | 'ordersFulfilled' | 'averageRating'>
  & { billingAddress: (
    { __typename?: 'PublicAddress' }
    & Pick<PublicAddress, 'id' | 'city' | 'state'>
  ) }
);

type PublicUserMetricsFragment_OfferUser_ = (
  { __typename?: 'OfferUser' }
  & Pick<OfferUser, 'activeListings' | 'ordersFulfilled' | 'averageRating'>
  & { billingAddress: (
    { __typename?: 'PublicAddress' }
    & Pick<PublicAddress, 'id' | 'city' | 'state'>
  ) }
);

type PublicUserMetricsFragment_OrderUser_ = (
  { __typename?: 'OrderUser' }
  & Pick<OrderUser, 'activeListings' | 'ordersFulfilled' | 'averageRating'>
  & { billingAddress: (
    { __typename?: 'PublicAddress' }
    & Pick<PublicAddress, 'id' | 'city' | 'state'>
  ) }
);

type PublicUserMetricsFragment_ChatUser_ = (
  { __typename?: 'ChatUser' }
  & Pick<ChatUser, 'activeListings' | 'ordersFulfilled' | 'averageRating'>
  & { billingAddress: (
    { __typename?: 'PublicAddress' }
    & Pick<PublicAddress, 'id' | 'city' | 'state'>
  ) }
);

export type PublicUserMetricsFragment = PublicUserMetricsFragment_Merchant_ | PublicUserMetricsFragment_CartUser_ | PublicUserMetricsFragment_ListingUser_ | PublicUserMetricsFragment_OfferUser_ | PublicUserMetricsFragment_OrderUser_ | PublicUserMetricsFragment_ChatUser_;

type RatingsListFragment_Merchant_ = (
  { __typename?: 'Merchant' }
  & { ratings: (
    { __typename?: 'RatingResults' }
    & { results: Array<(
      { __typename?: 'Rating' }
      & Pick<Rating, 'id' | 'score' | 'comment'>
    )> }
  ) }
);

type RatingsListFragment_CartUser_ = (
  { __typename?: 'CartUser' }
  & { ratings: (
    { __typename?: 'RatingResults' }
    & { results: Array<(
      { __typename?: 'Rating' }
      & Pick<Rating, 'id' | 'score' | 'comment'>
    )> }
  ) }
);

type RatingsListFragment_ListingUser_ = (
  { __typename?: 'ListingUser' }
  & { ratings: (
    { __typename?: 'RatingResults' }
    & { results: Array<(
      { __typename?: 'Rating' }
      & Pick<Rating, 'id' | 'score' | 'comment'>
    )> }
  ) }
);

type RatingsListFragment_OfferUser_ = (
  { __typename?: 'OfferUser' }
  & { ratings: (
    { __typename?: 'RatingResults' }
    & { results: Array<(
      { __typename?: 'Rating' }
      & Pick<Rating, 'id' | 'score' | 'comment'>
    )> }
  ) }
);

type RatingsListFragment_OrderUser_ = (
  { __typename?: 'OrderUser' }
  & { ratings: (
    { __typename?: 'RatingResults' }
    & { results: Array<(
      { __typename?: 'Rating' }
      & Pick<Rating, 'id' | 'score' | 'comment'>
    )> }
  ) }
);

type RatingsListFragment_ChatUser_ = (
  { __typename?: 'ChatUser' }
  & { ratings: (
    { __typename?: 'RatingResults' }
    & { results: Array<(
      { __typename?: 'Rating' }
      & Pick<Rating, 'id' | 'score' | 'comment'>
    )> }
  ) }
);

export type RatingsListFragment = RatingsListFragment_Merchant_ | RatingsListFragment_CartUser_ | RatingsListFragment_ListingUser_ | RatingsListFragment_OfferUser_ | RatingsListFragment_OrderUser_ | RatingsListFragment_ChatUser_;

export type AccountAvatarFragment = (
  { __typename?: 'User' }
  & { avatar: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )> }
);

export type UserListFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'displayName' | 'createdAt' | 'email' | 'phone' | 'operationId' | 'businessName' | 'approvedAt' | 'deletedAt'>
);

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInfoInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUserInfo: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & AccountUpdateFormFragment
  ) }
);

export type AccountInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountInfoQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & AccountBankingLinksFragment
    & AccountUpdateFormFragment
    & AccountAvatarFragment
  )> }
);

export type MyOrdersQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type MyOrdersQuery = (
  { __typename?: 'Query' }
  & { orders: (
    { __typename?: 'OrdersConnection' }
    & Pick<OrdersConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'FulfillmentOrder' }
      & Pick<FulfillmentOrder, 'id'>
      & AccountOrdersListFragment_FulfillmentOrder_
    ) | (
      { __typename?: 'ShipmentOrder' }
      & Pick<ShipmentOrder, 'id'>
      & AccountOrdersListFragment_ShipmentOrder_
    ) | (
      { __typename?: 'WholeSaleOrder' }
      & Pick<WholeSaleOrder, 'id'>
      & AccountOrdersListFragment_WholeSaleOrder_
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ) }
  ) }
);

export type ArchiveOrderMutationVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type ArchiveOrderMutation = (
  { __typename?: 'Mutation' }
  & { archiveOrder: Maybe<(
    { __typename?: 'FulfillmentOrder' }
    & Pick<FulfillmentOrder, 'id'>
    & AccountOrdersListFragment_FulfillmentOrder_
  ) | (
    { __typename?: 'ShipmentOrder' }
    & Pick<ShipmentOrder, 'id'>
    & AccountOrdersListFragment_ShipmentOrder_
  ) | (
    { __typename?: 'WholeSaleOrder' }
    & Pick<WholeSaleOrder, 'id'>
    & AccountOrdersListFragment_WholeSaleOrder_
  )> }
);

export type UnarchiveOrderMutationVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type UnarchiveOrderMutation = (
  { __typename?: 'Mutation' }
  & { unarchiveOrder: Maybe<(
    { __typename?: 'FulfillmentOrder' }
    & Pick<FulfillmentOrder, 'id'>
    & AccountOrdersListFragment_FulfillmentOrder_
  ) | (
    { __typename?: 'ShipmentOrder' }
    & Pick<ShipmentOrder, 'id'>
    & AccountOrdersListFragment_ShipmentOrder_
  ) | (
    { __typename?: 'WholeSaleOrder' }
    & Pick<WholeSaleOrder, 'id'>
    & AccountOrdersListFragment_WholeSaleOrder_
  )> }
);

export type VerifyEmailUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyEmailUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'verifyEmailUser'>
);

export type AddForSaleListingMutationVariables = Exact<{
  input: AddForSaleListingInput;
}>;


export type AddForSaleListingMutation = (
  { __typename?: 'Mutation' }
  & { addForSaleListing: (
    { __typename: 'ForSaleListing' }
    & Pick<ForSaleListing, 'id' | 'estimatedHarvestDate' | 'deliveryAvailability' | 'cutOrSize'>
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'type'>
    ), price: (
      { __typename?: 'PricePerUnit' }
      & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
    ), quantity: (
      { __typename?: 'Quantity' }
      & Pick<Quantity, 'amount' | 'unit'>
    ), availability: (
      { __typename?: 'ListingAvailability' }
      & Pick<ListingAvailability, 'estimated' | 'rangeStart' | 'rangeEnd'>
    ) }
  ) }
);

export type AddWantedListingMutationVariables = Exact<{
  input: AddWantedListingInput;
}>;


export type AddWantedListingMutation = (
  { __typename?: 'Mutation' }
  & { addWantedListing: (
    { __typename: 'WantedListing' }
    & Pick<WantedListing, 'id' | 'cutOrSize'>
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'type'>
    ), price: (
      { __typename?: 'PricePerUnit' }
      & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
    ), quantity: (
      { __typename?: 'Quantity' }
      & Pick<Quantity, 'amount' | 'unit'>
    ), availability: (
      { __typename?: 'ListingAvailability' }
      & Pick<ListingAvailability, 'estimated' | 'rangeStart' | 'rangeEnd'>
    ) }
  ) }
);

export type AddTransportationListingMutationVariables = Exact<{
  input: AddTransportationListingInput;
}>;


export type AddTransportationListingMutation = (
  { __typename?: 'Mutation' }
  & { addTransportationListing: (
    { __typename: 'TransportationListing' }
    & Pick<TransportationListing, 'id'>
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'type'>
    ), quantity: (
      { __typename?: 'Quantity' }
      & Pick<Quantity, 'amount' | 'unit'>
    ) }
  ) }
);

export type AddWholeSaleListingMutationVariables = Exact<{
  input: AddWholeSaleListingInput;
}>;


export type AddWholeSaleListingMutation = (
  { __typename?: 'Mutation' }
  & { addWholeSaleListing: (
    { __typename: 'WholeSaleListing' }
    & Pick<WholeSaleListing, 'id' | 'deliveryAvailability' | 'cutOrSize' | 'itemId'>
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'type'>
    ), wholesalePrice: (
      { __typename?: 'WholesalePricePerUnit' }
      & Pick<WholesalePricePerUnit, 'amount' | 'currency' | 'unit' | 'isVariable' | 'isHidden'>
    ) }
  ) }
);

export type GetBusinessDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBusinessDetailsQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'defaultTerms'>
  )> }
);

export type ProductsForListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsForListingQuery = (
  { __typename?: 'Query' }
  & { products: (
    { __typename?: 'ProductsPayload' }
    & Pick<ProductsPayload, 'count'>
    & { results: Array<(
      { __typename?: 'Product' }
      & Pick<Product, 'id'>
      & AddListingFormProductFragment
    )> }
  ) }
);

export type CurrentUserEntitlementsQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserEntitlementsQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'entitlements'>
  )> }
);

export type UndoAddListingMutationVariables = Exact<{
  input: UnpublishListingInput;
}>;


export type UndoAddListingMutation = (
  { __typename?: 'Mutation' }
  & { unpublishListing: (
    { __typename?: 'TransportationListing' }
    & Pick<TransportationListing, 'id'>
  ) | (
    { __typename?: 'ForSaleListing' }
    & Pick<ForSaleListing, 'id'>
  ) | (
    { __typename?: 'WantedListing' }
    & Pick<WantedListing, 'id'>
  ) | (
    { __typename?: 'WholeSaleListing' }
    & Pick<WholeSaleListing, 'id'>
  ) }
);

export type AdminChatChannelByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AdminChatChannelByIdQuery = (
  { __typename?: 'Query' }
  & { adminChatChannelById: (
    { __typename?: 'ChatChannel' }
    & Pick<ChatChannel, 'user1' | 'user2' | 'secretKey'>
  ) }
);

export type AdminChatChannelFragment = (
  { __typename?: 'ChatChannel' }
  & Pick<ChatChannel, 'id' | 'user1' | 'user2'>
  & { user1Deco: Maybe<(
    { __typename?: 'ChatUser' }
    & Pick<ChatUser, 'id' | 'displayName'>
    & { avatar: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  )>, user2Deco: Maybe<(
    { __typename?: 'ChatUser' }
    & Pick<ChatUser, 'id' | 'displayName'>
    & { avatar: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  )> }
);

export type AdminChatChannelsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminChatChannelsQuery = (
  { __typename?: 'Query' }
  & { adminHelpChatChannels: Array<(
    { __typename?: 'ChatChannel' }
    & Pick<ChatChannel, 'id'>
    & AdminChatChannelFragment
  )> }
);

export type GetUserByIdQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserByIdQuery = (
  { __typename?: 'Query' }
  & { userById: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'firstName' | 'lastName' | 'email' | 'businessName'>
    & { avatar: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  )> }
);

export type CartContainerFragment = (
  { __typename?: 'Cart' }
  & Pick<Cart, 'id' | 'entitlements'>
  & { fromUser: (
    { __typename?: 'CartUser' }
    & Pick<CartUser, 'id' | 'businessName' | 'displayName'>
  ), toUser: (
    { __typename?: 'CartUser' }
    & Pick<CartUser, 'id' | 'businessName' | 'displayName'>
  ), order: Maybe<(
    { __typename?: 'WholeSaleOrder' }
    & Pick<WholeSaleOrder, 'id'>
  )> }
  & CartHeaderFragment
  & CartDetailsFragment
  & CartRequestCheckoutActionFragment
);

export type CartQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CartQuery = (
  { __typename?: 'Query' }
  & { cart: (
    { __typename?: 'Cart' }
    & CartContainerFragment
  ) }
);

export type RemoveFromCartMutationVariables = Exact<{
  input: RemoveFromCartInput;
}>;


export type RemoveFromCartMutation = (
  { __typename?: 'Mutation' }
  & { removeFromCart: (
    { __typename?: 'Cart' }
    & CartContainerFragment
  ) }
);

export type RequestCartCheckoutMutationVariables = Exact<{
  input: RequestCartCheckoutInput;
}>;


export type RequestCartCheckoutMutation = (
  { __typename?: 'Mutation' }
  & { requestCartCheckout: (
    { __typename?: 'Cart' }
    & CartContainerFragment
  ) }
);

export type AcceptCartCheckoutMutationVariables = Exact<{
  input: AcceptCartCheckoutInput;
}>;


export type AcceptCartCheckoutMutation = (
  { __typename?: 'Mutation' }
  & { acceptCartCheckout: (
    { __typename?: 'Cart' }
    & CartContainerFragment
  ) }
);

export type DeclineCartCheckoutMutationVariables = Exact<{
  input: DeclineCartCheckoutInput;
}>;


export type DeclineCartCheckoutMutation = (
  { __typename?: 'Mutation' }
  & { declineCartCheckout: (
    { __typename?: 'Cart' }
    & CartContainerFragment
  ) }
);

export type CartForChatQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CartForChatQuery = (
  { __typename?: 'Query' }
  & { cart: (
    { __typename?: 'Cart' }
    & Pick<Cart, 'id'>
    & { fromUser: (
      { __typename?: 'CartUser' }
      & Pick<CartUser, 'id'>
      & PublicUserDetailsFragment_CartUser_
    ), toUser: (
      { __typename?: 'CartUser' }
      & Pick<CartUser, 'id'>
      & PublicUserDetailsFragment_CartUser_
    ) }
  ) }
);

export type CartRatingsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CartRatingsQuery = (
  { __typename?: 'Query' }
  & { cart: (
    { __typename?: 'Cart' }
    & Pick<Cart, 'id'>
    & { toUser: (
      { __typename?: 'CartUser' }
      & Pick<CartUser, 'id'>
      & PublicUserLockupFragment_CartUser_
      & RatingsListFragment_CartUser_
    ), fromUser: (
      { __typename?: 'CartUser' }
      & Pick<CartUser, 'id'>
      & PublicUserLockupFragment_CartUser_
      & RatingsListFragment_CartUser_
    ) }
  ) }
);

export type CartsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  toUserIdOrSlug?: Maybe<Scalars['String']>;
}>;


export type CartsQuery = (
  { __typename?: 'Query' }
  & { carts: (
    { __typename?: 'CartsConnection' }
    & Pick<CartsConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id'>
      & CartListSliderItemFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type CategoriesFilterQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesFilterQuery = (
  { __typename?: 'Query' }
  & { categories: (
    { __typename?: 'CategoriesPayload' }
    & Pick<CategoriesPayload, 'count'>
    & { results: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    )> }
  ) }
);

export type AdminChatChannelQueryVariables = Exact<{
  user1: Scalars['String'];
  user2: Scalars['String'];
  context?: Maybe<ChatContextInput>;
}>;


export type AdminChatChannelQuery = (
  { __typename?: 'Query' }
  & { chatChannel: (
    { __typename?: 'ChatChannel' }
    & Pick<ChatChannel, 'id' | 'secretKey'>
  ) }
);

export type NoAuthHelpChatChannelQueryVariables = Exact<{ [key: string]: never; }>;


export type NoAuthHelpChatChannelQuery = (
  { __typename?: 'Query' }
  & { noAuthHelpChatChannel: (
    { __typename?: 'NoAuthChatChannelAndIdentifier' }
    & Pick<NoAuthChatChannelAndIdentifier, 'id'>
    & { chatChannel: (
      { __typename?: 'ChatChannel' }
      & Pick<ChatChannel, 'id' | 'secretKey'>
    ) }
  ) }
);

export type ChatChannelQueryVariables = Exact<{
  user1: Scalars['String'];
  user2: Scalars['String'];
  context?: Maybe<ChatContextInput>;
}>;


export type ChatChannelQuery = (
  { __typename?: 'Query' }
  & { chatChannel: (
    { __typename?: 'ChatChannel' }
    & Pick<ChatChannel, 'id' | 'secretKey' | 'user1' | 'user2'>
    & { user1Deco: Maybe<(
      { __typename?: 'ChatUser' }
      & Pick<ChatUser, 'id'>
      & PublicUserDetailsFragment_ChatUser_
    )>, user2Deco: Maybe<(
      { __typename?: 'ChatUser' }
      & Pick<ChatUser, 'id'>
      & PublicUserDetailsFragment_ChatUser_
    )> }
  ) }
);

export type SetChatChannelActiveMutationVariables = Exact<{
  chatChannelId: Scalars['ID'];
}>;


export type SetChatChannelActiveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setChatChannelActive'>
);

export type ChatChannelByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ChatChannelByIdQuery = (
  { __typename?: 'Query' }
  & { chatChannelById: (
    { __typename?: 'ChatChannel' }
    & Pick<ChatChannel, 'id' | 'user1' | 'user2' | 'secretKey'>
    & { user1Deco: Maybe<(
      { __typename?: 'ChatUser' }
      & Pick<ChatUser, 'id'>
      & PublicUserDetailsFragment_ChatUser_
    )>, user2Deco: Maybe<(
      { __typename?: 'ChatUser' }
      & Pick<ChatUser, 'id'>
      & PublicUserDetailsFragment_ChatUser_
    )> }
  ) }
);

export type UpdateDwollaInfoMutationVariables = Exact<{
  input: UpdateUserInfoInput;
}>;


export type UpdateDwollaInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateUserInfo: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & DwollaAccountFormFragment
  ) }
);

export type DwollaAccountInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type DwollaAccountInfoQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & DwollaAccountFormFragment
  )> }
);

export type CreateDwollaIAVTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateDwollaIAVTokenMutation = (
  { __typename?: 'Mutation' }
  & { createDwollaIAVToken: (
    { __typename?: 'CreateDwollaIAVTokenPayload' }
    & Pick<CreateDwollaIAVTokenPayload, 'token'>
  ) }
);

export type DwollaAddBeneficialOwnerMutationVariables = Exact<{
  input: AddDwollaBeneficialOwnerInput;
}>;


export type DwollaAddBeneficialOwnerMutation = (
  { __typename?: 'Mutation' }
  & { dwollaAddBeneficialOwner: (
    { __typename?: 'DwollaBeneficialOwner' }
    & Pick<DwollaBeneficialOwner, 'id'>
  ) }
);

export type DwollaDocumentInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type DwollaDocumentInfoQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { dwollaCustomer: Maybe<(
      { __typename?: 'DwollaCustomer' }
      & Pick<DwollaCustomer, 'id' | 'status'>
      & DwollaDocumentUploadFragment
    )> }
  )> }
);

export type DwollaCustomerDocumentUploadMutationVariables = Exact<{
  input: DwollaCustomerDocumentUploadInput;
}>;


export type DwollaCustomerDocumentUploadMutation = (
  { __typename?: 'Mutation' }
  & { dwollaCustomerDocumentUpload: (
    { __typename?: 'DwollaCustomerDocument' }
    & Pick<DwollaCustomerDocument, 'id' | 'status'>
  ) }
);

export type InitiateTransferMutationVariables = Exact<{
  input: InitiateDwollaTransferInput;
}>;


export type InitiateTransferMutation = (
  { __typename?: 'Mutation' }
  & { initiateDwollaTransfer: (
    { __typename?: 'InitiateDwollaTransferPayload' }
    & Pick<InitiateDwollaTransferPayload, 'id' | 'status'>
    & { amount: (
      { __typename?: 'DwollaTransferAmount' }
      & Pick<DwollaTransferAmount, 'value' | 'currency'>
    ) }
  ) }
);

export type MakeTransferDwollaFundingSourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type MakeTransferDwollaFundingSourcesQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & { dwollaCustomer: Maybe<(
      { __typename?: 'DwollaCustomer' }
      & Pick<DwollaCustomer, 'id'>
      & { fundingSources: Array<(
        { __typename?: 'DwollaBankFundingSource' }
        & Pick<DwollaBankFundingSource, 'id' | 'name'>
      ) | (
        { __typename?: 'DwollaBalanceFundingSource' }
        & Pick<DwollaBalanceFundingSource, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type DwollaFundingSourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type DwollaFundingSourcesQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & { dwollaCustomer: Maybe<(
      { __typename?: 'DwollaCustomer' }
      & Pick<DwollaCustomer, 'id'>
      & { fundingSources: Array<(
        { __typename?: 'DwollaBankFundingSource' }
        & Pick<DwollaBankFundingSource, 'id' | 'name'>
      ) | (
        { __typename?: 'DwollaBalanceFundingSource' }
        & Pick<DwollaBalanceFundingSource, 'id' | 'name'>
        & { balance: (
          { __typename?: 'DwollaBalance' }
          & Pick<DwollaBalance, 'value' | 'currency'>
        ) }
      )> }
    )> }
  )> }
);

export type DwollaRemoveFundingSourceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DwollaRemoveFundingSourceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeDwollaFundingSource'>
);

export type UploadMyAvatarMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadMyAvatarMutation = (
  { __typename?: 'Mutation' }
  & { uploadAvatar: (
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  ) }
);

export type UserAvatarQueryVariables = Exact<{ [key: string]: never; }>;


export type UserAvatarQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & AccountAvatarFragment
  )> }
);

export type FixedPriceOffersQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  toUserIdOrSlug?: Maybe<Scalars['String']>;
}>;


export type FixedPriceOffersQuery = (
  { __typename?: 'Query' }
  & { carts: (
    { __typename?: 'CartsConnection' }
    & Pick<CartsConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Cart' }
      & Pick<Cart, 'id'>
      & CartListSliderItemFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type SubmitForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SubmitForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createForgotPasswordRequest'>
);

export type HomeSaleListingsQueryVariables = Exact<{
  text?: Maybe<Scalars['String']>;
  categoryIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  includeTagIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  proximity?: Maybe<Scalars['Int']>;
  postedByIdOrSlug?: Maybe<Scalars['String']>;
  availabilityStart?: Maybe<Scalars['Date']>;
  availabilityEnd?: Maybe<Scalars['Date']>;
  after?: Maybe<Scalars['String']>;
  types?: Maybe<Array<ListingType> | ListingType>;
}>;


export type HomeSaleListingsQuery = (
  { __typename?: 'Query' }
  & { listings: (
    { __typename?: 'ListingsConnection' }
    & Pick<ListingsConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'TransportationListing' }
      & Pick<TransportationListing, 'id'>
      & ListingListSliderItem_TransportationListing_Fragment
    ) | (
      { __typename?: 'ForSaleListing' }
      & Pick<ForSaleListing, 'id'>
      & ListingListSliderItem_ForSaleListing_Fragment
    ) | (
      { __typename?: 'WantedListing' }
      & Pick<WantedListing, 'id'>
      & ListingListSliderItem_WantedListing_Fragment
    ) | (
      { __typename?: 'WholeSaleListing' }
      & Pick<WholeSaleListing, 'id'>
      & ListingListSliderItem_WholeSaleListing_Fragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type HomeTransportationListingsQueryVariables = Exact<{
  text?: Maybe<Scalars['String']>;
  categoryIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  includeTagIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  proximity?: Maybe<Scalars['Int']>;
  postedByIdOrSlug?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type HomeTransportationListingsQuery = (
  { __typename?: 'Query' }
  & { listings: (
    { __typename?: 'ListingsConnection' }
    & Pick<ListingsConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'TransportationListing' }
      & Pick<TransportationListing, 'id'>
      & ListingListSliderItem_TransportationListing_Fragment
    ) | (
      { __typename?: 'ForSaleListing' }
      & Pick<ForSaleListing, 'id'>
      & ListingListSliderItem_ForSaleListing_Fragment
    ) | (
      { __typename?: 'WantedListing' }
      & Pick<WantedListing, 'id'>
      & ListingListSliderItem_WantedListing_Fragment
    ) | (
      { __typename?: 'WholeSaleListing' }
      & Pick<WholeSaleListing, 'id'>
      & ListingListSliderItem_WholeSaleListing_Fragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type HomeWantedListingsQueryVariables = Exact<{
  text?: Maybe<Scalars['String']>;
  categoryIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  includeTagIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  proximity?: Maybe<Scalars['Int']>;
  postedByIdOrSlug?: Maybe<Scalars['String']>;
  availabilityStart?: Maybe<Scalars['Date']>;
  availabilityEnd?: Maybe<Scalars['Date']>;
  after?: Maybe<Scalars['String']>;
}>;


export type HomeWantedListingsQuery = (
  { __typename?: 'Query' }
  & { listings: (
    { __typename?: 'ListingsConnection' }
    & Pick<ListingsConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'TransportationListing' }
      & Pick<TransportationListing, 'id'>
      & ListingListSliderItem_TransportationListing_Fragment
    ) | (
      { __typename?: 'ForSaleListing' }
      & Pick<ForSaleListing, 'id'>
      & ListingListSliderItem_ForSaleListing_Fragment
    ) | (
      { __typename?: 'WantedListing' }
      & Pick<WantedListing, 'id'>
      & ListingListSliderItem_WantedListing_Fragment
    ) | (
      { __typename?: 'WholeSaleListing' }
      & Pick<WholeSaleListing, 'id'>
      & ListingListSliderItem_WholeSaleListing_Fragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type ListingAddToCartFormFragment = (
  { __typename?: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'id' | 'unit'>
  & { wholesalePrice: (
    { __typename?: 'WholesalePricePerUnit' }
    & Pick<WholesalePricePerUnit, 'amount'>
  ) }
);

export type AddListingToCartMutationVariables = Exact<{
  input: AddListingToCartInput;
}>;


export type AddListingToCartMutation = (
  { __typename?: 'Mutation' }
  & { addListingToCart: (
    { __typename?: 'Cart' }
    & Pick<Cart, 'id'>
  ) }
);

export type ListingAddToCartFragment = (
  { __typename?: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'id' | 'entitlements'>
  & ListingAddToCartFormFragment
);

export type ListingChatListQueryVariables = Exact<{
  listingId?: Maybe<Scalars['String']>;
}>;


export type ListingChatListQuery = (
  { __typename?: 'Query' }
  & { chatChannelsByContext: Array<(
    { __typename?: 'ChatChannel' }
    & Pick<ChatChannel, 'id'>
    & { user1Deco: Maybe<(
      { __typename?: 'ChatUser' }
      & Pick<ChatUser, 'id' | 'displayName'>
      & { avatar: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )> }
    )>, user2Deco: Maybe<(
      { __typename?: 'ChatUser' }
      & Pick<ChatUser, 'id' | 'displayName'>
      & { avatar: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )> }
    )> }
  )> }
);

type ListingMakeOfferForm_TransportationListing_Fragment = (
  { __typename?: 'TransportationListing' }
  & Pick<TransportationListing, 'id'>
);

type ListingMakeOfferForm_ForSaleListing_Fragment = (
  { __typename?: 'ForSaleListing' }
  & Pick<ForSaleListing, 'id'>
  & { price: (
    { __typename?: 'PricePerUnit' }
    & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
  ) }
);

type ListingMakeOfferForm_WantedListing_Fragment = (
  { __typename?: 'WantedListing' }
  & Pick<WantedListing, 'id'>
  & { price: (
    { __typename?: 'PricePerUnit' }
    & Pick<PricePerUnit, 'amount' | 'currency' | 'unit'>
  ) }
);

type ListingMakeOfferForm_WholeSaleListing_Fragment = (
  { __typename?: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'id'>
);

export type ListingMakeOfferFormFragment = ListingMakeOfferForm_TransportationListing_Fragment | ListingMakeOfferForm_ForSaleListing_Fragment | ListingMakeOfferForm_WantedListing_Fragment | ListingMakeOfferForm_WholeSaleListing_Fragment;

export type MakeOfferMutationVariables = Exact<{
  input: MakeOfferInput;
}>;


export type MakeOfferMutation = (
  { __typename?: 'Mutation' }
  & { makeOffer: Maybe<(
    { __typename?: 'PerUnitOffer' }
    & Pick<PerUnitOffer, 'id'>
  ) | (
    { __typename?: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id'>
  )> }
);

export type MakeFullPriceOfferMutationVariables = Exact<{
  input: MakeFullPriceOfferInput;
}>;


export type MakeFullPriceOfferMutation = (
  { __typename?: 'Mutation' }
  & { makeFullPriceOffer: Maybe<(
    { __typename?: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id'>
  )> }
);

type ListingMakeOfferFragment_TransportationListing_ = (
  { __typename: 'TransportationListing' }
  & Pick<TransportationListing, 'id' | 'entitlements'>
  & { openOffer: Maybe<(
    { __typename?: 'PerUnitOffer' }
    & Pick<PerUnitOffer, 'id'>
  ) | (
    { __typename?: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id'>
  )> }
  & ListingMakeOfferForm_TransportationListing_Fragment
);

type ListingMakeOfferFragment_ForSaleListing_ = (
  { __typename: 'ForSaleListing' }
  & Pick<ForSaleListing, 'id' | 'entitlements'>
  & { openOffer: Maybe<(
    { __typename?: 'PerUnitOffer' }
    & Pick<PerUnitOffer, 'id'>
  ) | (
    { __typename?: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id'>
  )> }
  & ListingMakeOfferForm_ForSaleListing_Fragment
);

type ListingMakeOfferFragment_WantedListing_ = (
  { __typename: 'WantedListing' }
  & Pick<WantedListing, 'id' | 'entitlements'>
  & { openOffer: Maybe<(
    { __typename?: 'PerUnitOffer' }
    & Pick<PerUnitOffer, 'id'>
  ) | (
    { __typename?: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id'>
  )> }
  & ListingMakeOfferForm_WantedListing_Fragment
);

type ListingMakeOfferFragment_WholeSaleListing_ = (
  { __typename: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'id' | 'entitlements'>
  & ListingMakeOfferForm_WholeSaleListing_Fragment
);

export type ListingMakeOfferFragment = ListingMakeOfferFragment_TransportationListing_ | ListingMakeOfferFragment_ForSaleListing_ | ListingMakeOfferFragment_WantedListing_ | ListingMakeOfferFragment_WholeSaleListing_;

export type ListingDetailsForUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ListingDetailsForUserQuery = (
  { __typename?: 'Query' }
  & { listing: (
    { __typename?: 'TransportationListing' }
    & Pick<TransportationListing, 'id'>
    & { postedBy: (
      { __typename?: 'ListingUser' }
      & Pick<ListingUser, 'id'>
      & PublicUserDetailsFragment_ListingUser_
    ) }
  ) | (
    { __typename?: 'ForSaleListing' }
    & Pick<ForSaleListing, 'id'>
    & { postedBy: (
      { __typename?: 'ListingUser' }
      & Pick<ListingUser, 'id'>
      & PublicUserDetailsFragment_ListingUser_
    ) }
  ) | (
    { __typename?: 'WantedListing' }
    & Pick<WantedListing, 'id'>
    & { postedBy: (
      { __typename?: 'ListingUser' }
      & Pick<ListingUser, 'id'>
      & PublicUserDetailsFragment_ListingUser_
    ) }
  ) | (
    { __typename?: 'WholeSaleListing' }
    & Pick<WholeSaleListing, 'id'>
    & { postedBy: (
      { __typename?: 'ListingUser' }
      & Pick<ListingUser, 'id'>
      & PublicUserDetailsFragment_ListingUser_
    ) }
  ) }
);

export type ListingAddressFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'id'>
  & ListingAddressMapFragment
);

type ListingFragment_TransportationListing_ = (
  { __typename?: 'TransportationListing' }
  & Pick<TransportationListing, 'id' | 'entitlements'>
  & { pickupAddress: (
    { __typename?: 'Address' }
    & ListingAddressFragment
  ), deliveryAddress: (
    { __typename?: 'Address' }
    & ListingAddressFragment
  ), postedAddress: (
    { __typename?: 'Address' }
    & ListingAddressFragment
  ) }
  & ListingHeader_TransportationListing_Fragment
  & ListingDetails_TransportationListing_Fragment
  & ListingMakeOfferFragment_TransportationListing_
  & ListingMapFragment_TransportationListing_
);

type ListingFragment_ForSaleListing_ = (
  { __typename?: 'ForSaleListing' }
  & Pick<ForSaleListing, 'id' | 'entitlements'>
  & { postedAddress: (
    { __typename?: 'Address' }
    & ListingAddressFragment
  ) }
  & ListingHeader_ForSaleListing_Fragment
  & ListingDetails_ForSaleListing_Fragment
  & ListingMakeOfferFragment_ForSaleListing_
  & ListingMapFragment_ForSaleListing_
);

type ListingFragment_WantedListing_ = (
  { __typename?: 'WantedListing' }
  & Pick<WantedListing, 'id' | 'entitlements'>
  & { postedAddress: (
    { __typename?: 'Address' }
    & ListingAddressFragment
  ) }
  & ListingHeader_WantedListing_Fragment
  & ListingDetails_WantedListing_Fragment
  & ListingMakeOfferFragment_WantedListing_
  & ListingMapFragment_WantedListing_
);

type ListingFragment_WholeSaleListing_ = (
  { __typename?: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'id' | 'entitlements'>
  & { postedAddress: (
    { __typename?: 'Address' }
    & ListingAddressFragment
  ) }
  & ListingHeader_WholeSaleListing_Fragment
  & ListingDetails_WholeSaleListing_Fragment
  & ListingAddToCartFragment
  & ListingMakeOfferFragment_WholeSaleListing_
  & ListingMapFragment_WholeSaleListing_
);

export type ListingFragment = ListingFragment_TransportationListing_ | ListingFragment_ForSaleListing_ | ListingFragment_WantedListing_ | ListingFragment_WholeSaleListing_;

export type ListingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ListingQuery = (
  { __typename?: 'Query' }
  & { listing: (
    { __typename?: 'TransportationListing' }
    & ListingFragment_TransportationListing_
  ) | (
    { __typename?: 'ForSaleListing' }
    & ListingFragment_ForSaleListing_
  ) | (
    { __typename?: 'WantedListing' }
    & ListingFragment_WantedListing_
  ) | (
    { __typename?: 'WholeSaleListing' }
    & ListingFragment_WholeSaleListing_
  ) }
);

export type UnpublishListingMutationVariables = Exact<{
  input: UnpublishListingInput;
}>;


export type UnpublishListingMutation = (
  { __typename?: 'Mutation' }
  & { unpublishListing: (
    { __typename?: 'TransportationListing' }
    & ListingFragment_TransportationListing_
  ) | (
    { __typename?: 'ForSaleListing' }
    & ListingFragment_ForSaleListing_
  ) | (
    { __typename?: 'WantedListing' }
    & ListingFragment_WantedListing_
  ) | (
    { __typename?: 'WholeSaleListing' }
    & ListingFragment_WholeSaleListing_
  ) }
);

export type UpdateListingPriceMutationVariables = Exact<{
  input: UpdateWholeSaleListingInput;
}>;


export type UpdateListingPriceMutation = (
  { __typename?: 'Mutation' }
  & { updateWholeSaleListing: (
    { __typename?: 'WholeSaleListing' }
    & ListingFragment_WholeSaleListing_
  ) }
);

type ListingChatFragment_TransportationListing_ = (
  { __typename: 'TransportationListing' }
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id'>
  ) }
);

type ListingChatFragment_ForSaleListing_ = (
  { __typename: 'ForSaleListing' }
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id'>
  ) }
);

type ListingChatFragment_WantedListing_ = (
  { __typename: 'WantedListing' }
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id'>
  ) }
);

type ListingChatFragment_WholeSaleListing_ = (
  { __typename: 'WholeSaleListing' }
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id'>
  ) }
);

export type ListingChatFragment = ListingChatFragment_TransportationListing_ | ListingChatFragment_ForSaleListing_ | ListingChatFragment_WantedListing_ | ListingChatFragment_WholeSaleListing_;

export type ListingForChatQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ListingForChatQuery = (
  { __typename?: 'Query' }
  & { listing: (
    { __typename?: 'TransportationListing' }
    & { postedBy: (
      { __typename?: 'ListingUser' }
      & Pick<ListingUser, 'id'>
      & PublicUserDetailsFragment_ListingUser_
    ) }
    & ListingChatFragment_TransportationListing_
  ) | (
    { __typename?: 'ForSaleListing' }
    & { postedBy: (
      { __typename?: 'ListingUser' }
      & Pick<ListingUser, 'id'>
      & PublicUserDetailsFragment_ListingUser_
    ) }
    & ListingChatFragment_ForSaleListing_
  ) | (
    { __typename?: 'WantedListing' }
    & { postedBy: (
      { __typename?: 'ListingUser' }
      & Pick<ListingUser, 'id'>
      & PublicUserDetailsFragment_ListingUser_
    ) }
    & ListingChatFragment_WantedListing_
  ) | (
    { __typename?: 'WholeSaleListing' }
    & { postedBy: (
      { __typename?: 'ListingUser' }
      & Pick<ListingUser, 'id'>
      & PublicUserDetailsFragment_ListingUser_
    ) }
    & ListingChatFragment_WholeSaleListing_
  ) }
);

export type GetListingRatingsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetListingRatingsQuery = (
  { __typename?: 'Query' }
  & { listing: (
    { __typename?: 'TransportationListing' }
    & Pick<TransportationListing, 'id'>
    & { postedBy: (
      { __typename?: 'ListingUser' }
      & Pick<ListingUser, 'id'>
      & PublicUserLockupFragment_ListingUser_
      & RatingsListFragment_ListingUser_
    ) }
  ) | (
    { __typename?: 'ForSaleListing' }
    & Pick<ForSaleListing, 'id'>
    & { postedBy: (
      { __typename?: 'ListingUser' }
      & Pick<ListingUser, 'id'>
      & PublicUserLockupFragment_ListingUser_
      & RatingsListFragment_ListingUser_
    ) }
  ) | (
    { __typename?: 'WantedListing' }
    & Pick<WantedListing, 'id'>
    & { postedBy: (
      { __typename?: 'ListingUser' }
      & Pick<ListingUser, 'id'>
      & PublicUserLockupFragment_ListingUser_
      & RatingsListFragment_ListingUser_
    ) }
  ) | (
    { __typename?: 'WholeSaleListing' }
    & Pick<WholeSaleListing, 'id'>
    & { postedBy: (
      { __typename?: 'ListingUser' }
      & Pick<ListingUser, 'id'>
      & PublicUserLockupFragment_ListingUser_
      & RatingsListFragment_ListingUser_
    ) }
  ) }
);

export type MerchantAliasFragment = (
  { __typename?: 'Merchant' }
  & Pick<Merchant, 'id' | 'slug'>
);

export type UpdateMerchantSlugMutationVariables = Exact<{
  input: UpdateUserInfoInput;
}>;


export type UpdateMerchantSlugMutation = (
  { __typename?: 'Mutation' }
  & { updateUserInfo: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'slug'>
  ) }
);

export type MerchantBannerFragment = (
  { __typename?: 'Merchant' }
  & Pick<Merchant, 'id'>
  & { merchantBanner: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'url'>
  )> }
);

export type UpdateMerchantBannerMutationVariables = Exact<{
  input: UpdateUserInfoInput;
}>;


export type UpdateMerchantBannerMutation = (
  { __typename?: 'Mutation' }
  & { updateUserInfo: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { merchantBanner: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

export type MerchantDescriptionFragment = (
  { __typename?: 'Merchant' }
  & Pick<Merchant, 'id' | 'businessDescription'>
);

export type UpdateMerchantDescriptionMutationVariables = Exact<{
  input: UpdateUserInfoInput;
}>;


export type UpdateMerchantDescriptionMutation = (
  { __typename?: 'Mutation' }
  & { updateUserInfo: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'businessDescription'>
  ) }
);

export type MerchantTermsFragment = (
  { __typename?: 'Merchant' }
  & Pick<Merchant, 'id' | 'terms'>
);

export type MerchantTradeNameFragment = (
  { __typename?: 'Merchant' }
  & Pick<Merchant, 'id' | 'businessTradeName' | 'businessName'>
);

export type UpdateMerchantTradeNameMutationVariables = Exact<{
  input: UpdateUserInfoInput;
}>;


export type UpdateMerchantTradeNameMutation = (
  { __typename?: 'Mutation' }
  & { updateUserInfo: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'businessTradeName'>
  ) }
);

export type MerchantQueryVariables = Exact<{
  idOrSlug?: Maybe<Scalars['String']>;
}>;


export type MerchantQuery = (
  { __typename?: 'Query' }
  & { merchant: (
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'businessName' | 'averageRating'>
    & { avatar: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
    & MerchantAliasFragment
    & MerchantBannerFragment
    & MerchantDescriptionFragment
    & MerchantTermsFragment
    & MerchantTradeNameFragment
  ) }
);

export type MerchantChatDrawerQueryVariables = Exact<{
  idOrSlug: Scalars['String'];
}>;


export type MerchantChatDrawerQuery = (
  { __typename?: 'Query' }
  & { merchant: (
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id'>
    & PublicUserDetailsFragment_Merchant_
  ) }
);

export type MerchantChatListQueryVariables = Exact<{
  merchantId?: Maybe<Scalars['String']>;
}>;


export type MerchantChatListQuery = (
  { __typename?: 'Query' }
  & { chatChannelsByContext: Array<(
    { __typename?: 'ChatChannel' }
    & Pick<ChatChannel, 'id'>
    & { user1Deco: Maybe<(
      { __typename?: 'ChatUser' }
      & Pick<ChatUser, 'id' | 'displayName'>
      & { avatar: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )> }
    )>, user2Deco: Maybe<(
      { __typename?: 'ChatUser' }
      & Pick<ChatUser, 'id' | 'displayName'>
      & { avatar: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )> }
    )> }
  )> }
);

type NotificationListingFragment_TransportationListing_ = (
  { __typename: 'TransportationListing' }
  & Pick<TransportationListing, 'id'>
  & { product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type NotificationListingFragment_ForSaleListing_ = (
  { __typename: 'ForSaleListing' }
  & Pick<ForSaleListing, 'id'>
  & { product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type NotificationListingFragment_WantedListing_ = (
  { __typename: 'WantedListing' }
  & Pick<WantedListing, 'id'>
  & { product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

type NotificationListingFragment_WholeSaleListing_ = (
  { __typename: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'id'>
  & { product: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'type'>
    & { image: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'url'>
    )> }
  ) }
);

export type NotificationListingFragment = NotificationListingFragment_TransportationListing_ | NotificationListingFragment_ForSaleListing_ | NotificationListingFragment_WantedListing_ | NotificationListingFragment_WholeSaleListing_;

type NotificationOfferFragment_PerUnitOffer_ = (
  { __typename?: 'PerUnitOffer' }
  & Pick<PerUnitOffer, 'id'>
  & { listing: (
    { __typename?: 'ForSaleListing' }
    & NotificationListingFragment_ForSaleListing_
  ) | (
    { __typename?: 'WantedListing' }
    & NotificationListingFragment_WantedListing_
  ) }
);

type NotificationOfferFragment_FullPriceOffer_ = (
  { __typename?: 'FullPriceOffer' }
  & Pick<FullPriceOffer, 'id'>
  & { listing: (
    { __typename?: 'TransportationListing' }
    & NotificationListingFragment_TransportationListing_
  ) }
);

export type NotificationOfferFragment = NotificationOfferFragment_PerUnitOffer_ | NotificationOfferFragment_FullPriceOffer_;

type NotificationOrderFragment_FulfillmentOrder_ = (
  { __typename?: 'FulfillmentOrder' }
  & Pick<FulfillmentOrder, 'id'>
  & { offer: (
    { __typename?: 'PerUnitOffer' }
    & NotificationOfferFragment_PerUnitOffer_
  ) }
);

type NotificationOrderFragment_ShipmentOrder_ = (
  { __typename?: 'ShipmentOrder' }
  & Pick<ShipmentOrder, 'id'>
  & { offer: (
    { __typename?: 'FullPriceOffer' }
    & NotificationOfferFragment_FullPriceOffer_
  ) }
);

type NotificationOrderFragment_WholeSaleOrder_ = (
  { __typename?: 'WholeSaleOrder' }
  & Pick<WholeSaleOrder, 'id'>
);

export type NotificationOrderFragment = NotificationOrderFragment_FulfillmentOrder_ | NotificationOrderFragment_ShipmentOrder_ | NotificationOrderFragment_WholeSaleOrder_;

export type NotificationMerchantFragment = (
  { __typename?: 'Merchant' }
  & Pick<Merchant, 'id' | 'displayName' | 'businessName' | 'businessDescription'>
);

export type NotificationDrawerFragment = (
  { __typename?: 'DecoratedChatChannels' }
  & { chatChannel: (
    { __typename?: 'ChatChannel' }
    & Pick<ChatChannel, 'id' | 'user1' | 'user2' | 'contextId' | 'contextType'>
    & { user1Deco: Maybe<(
      { __typename?: 'ChatUser' }
      & Pick<ChatUser, 'id' | 'displayName'>
      & { avatar: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )> }
    )>, user2Deco: Maybe<(
      { __typename?: 'ChatUser' }
      & Pick<ChatUser, 'id' | 'displayName'>
      & { avatar: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'url'>
      )> }
    )> }
  ), contextDecoration: Maybe<(
    { __typename: 'WantedListing' }
    & Pick<WantedListing, 'id'>
    & NotificationListingFragment_WantedListing_
  ) | (
    { __typename: 'ForSaleListing' }
    & Pick<ForSaleListing, 'id'>
    & NotificationListingFragment_ForSaleListing_
  ) | (
    { __typename: 'TransportationListing' }
    & Pick<TransportationListing, 'id'>
    & NotificationListingFragment_TransportationListing_
  ) | (
    { __typename: 'WholeSaleListing' }
    & Pick<WholeSaleListing, 'id'>
    & NotificationListingFragment_WholeSaleListing_
  ) | (
    { __typename: 'PerUnitOffer' }
    & Pick<PerUnitOffer, 'id'>
    & NotificationOfferFragment_PerUnitOffer_
  ) | (
    { __typename: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id'>
    & NotificationOfferFragment_FullPriceOffer_
  ) | (
    { __typename: 'FulfillmentOrder' }
    & Pick<FulfillmentOrder, 'id'>
    & NotificationOrderFragment_FulfillmentOrder_
  ) | (
    { __typename: 'ShipmentOrder' }
    & Pick<ShipmentOrder, 'id'>
    & NotificationOrderFragment_ShipmentOrder_
  ) | (
    { __typename: 'Merchant' }
    & Pick<Merchant, 'id'>
    & NotificationMerchantFragment
  )> }
);

export type DecoratedChatChannelsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DecoratedChatChannelsByIdsQuery = (
  { __typename?: 'Query' }
  & { decoratedChatChannelsByIds: Array<(
    { __typename?: 'DecoratedChatChannels' }
    & NotificationDrawerFragment
  )> }
);

type OfferActionsFragment_PerUnitOffer_ = (
  { __typename?: 'PerUnitOffer' }
  & Pick<PerUnitOffer, 'id' | 'entitlements'>
  & { order: Maybe<(
    { __typename?: 'FulfillmentOrder' }
    & Pick<FulfillmentOrder, 'id'>
    & OrderLink_FulfillmentOrder_Fragment
  ) | (
    { __typename?: 'ShipmentOrder' }
    & Pick<ShipmentOrder, 'id'>
    & OrderLink_ShipmentOrder_Fragment
  ) | (
    { __typename?: 'WholeSaleOrder' }
    & Pick<WholeSaleOrder, 'id'>
    & OrderLink_WholeSaleOrder_Fragment
  )> }
);

type OfferActionsFragment_FullPriceOffer_ = (
  { __typename?: 'FullPriceOffer' }
  & Pick<FullPriceOffer, 'id' | 'entitlements'>
  & { order: Maybe<(
    { __typename?: 'FulfillmentOrder' }
    & Pick<FulfillmentOrder, 'id'>
    & OrderLink_FulfillmentOrder_Fragment
  ) | (
    { __typename?: 'ShipmentOrder' }
    & Pick<ShipmentOrder, 'id'>
    & OrderLink_ShipmentOrder_Fragment
  ) | (
    { __typename?: 'WholeSaleOrder' }
    & Pick<WholeSaleOrder, 'id'>
    & OrderLink_WholeSaleOrder_Fragment
  )> }
);

export type OfferActionsFragment = OfferActionsFragment_PerUnitOffer_ | OfferActionsFragment_FullPriceOffer_;

export type OfferListingAddressFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'id'>
  & ListingAddressMapFragment
);

export type FullPriceOfferContainerFragment = (
  { __typename?: 'FullPriceOffer' }
  & Pick<FullPriceOffer, 'id' | 'stage'>
  & { listing: (
    { __typename?: 'TransportationListing' }
    & Pick<TransportationListing, 'id'>
    & { pickupAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id'>
      & OfferListingAddressFragment
    ), deliveryAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id'>
      & OfferListingAddressFragment
    ), postedAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id'>
      & OfferListingAddressFragment
    ) }
  ) }
  & FullPriceOfferHeaderFragment
  & FullPriceOfferDetailsFragment
  & OfferActionsFragment_FullPriceOffer_
);

type OfferContainer_PerUnitOffer_Fragment = (
  { __typename?: 'PerUnitOffer' }
  & Pick<PerUnitOffer, 'id' | 'stage'>
  & { listing: (
    { __typename: 'ForSaleListing' }
    & Pick<ForSaleListing, 'id'>
    & { postedAddress: (
      { __typename?: 'Address' }
      & OfferListingAddressFragment
    ) }
  ) | (
    { __typename: 'WantedListing' }
    & Pick<WantedListing, 'id'>
    & { postedAddress: (
      { __typename?: 'Address' }
      & OfferListingAddressFragment
    ) }
  ) }
  & OfferTitleFragment_PerUnitOffer_
  & PerUnitOfferDetailsFragment
  & PerUnitOfferHeaderFragment
  & OfferActionsFragment_PerUnitOffer_
);

type OfferContainer_FullPriceOffer_Fragment = (
  { __typename?: 'FullPriceOffer' }
  & Pick<FullPriceOffer, 'id' | 'stage'>
  & { listing: (
    { __typename?: 'TransportationListing' }
    & Pick<TransportationListing, 'id'>
    & { pickupAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id'>
      & OfferListingAddressFragment
    ), deliveryAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id'>
      & OfferListingAddressFragment
    ), postedAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id'>
      & OfferListingAddressFragment
    ) }
    & ListingMapFragment_TransportationListing_
  ) }
  & OfferTitleFragment_FullPriceOffer_
  & FullPriceOfferDetailsFragment
  & FullPriceOfferHeaderFragment
  & OfferActionsFragment_FullPriceOffer_
);

export type OfferContainerFragment = OfferContainer_PerUnitOffer_Fragment | OfferContainer_FullPriceOffer_Fragment;

export type OfferQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OfferQuery = (
  { __typename?: 'Query' }
  & { offer: (
    { __typename?: 'PerUnitOffer' }
    & OfferContainer_PerUnitOffer_Fragment
  ) | (
    { __typename?: 'FullPriceOffer' }
    & OfferContainer_FullPriceOffer_Fragment
  ) }
);

export type AcceptOfferMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AcceptOfferMutation = (
  { __typename?: 'Mutation' }
  & { acceptOffer: Maybe<(
    { __typename?: 'PerUnitOffer' }
    & OfferContainer_PerUnitOffer_Fragment
  ) | (
    { __typename?: 'FullPriceOffer' }
    & OfferContainer_FullPriceOffer_Fragment
  )> }
);

export type DeclineOfferMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeclineOfferMutation = (
  { __typename?: 'Mutation' }
  & { declineOffer: Maybe<(
    { __typename?: 'PerUnitOffer' }
    & OfferContainer_PerUnitOffer_Fragment
  ) | (
    { __typename?: 'FullPriceOffer' }
    & OfferContainer_FullPriceOffer_Fragment
  )> }
);

export type CancelOfferMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CancelOfferMutation = (
  { __typename?: 'Mutation' }
  & { cancelOffer: Maybe<(
    { __typename?: 'PerUnitOffer' }
    & OfferContainer_PerUnitOffer_Fragment
  ) | (
    { __typename?: 'FullPriceOffer' }
    & OfferContainer_FullPriceOffer_Fragment
  )> }
);

export type CounterOfferMutationVariables = Exact<{
  input: CounterOfferInput;
}>;


export type CounterOfferMutation = (
  { __typename?: 'Mutation' }
  & { counterOffer: Maybe<(
    { __typename?: 'PerUnitOffer' }
    & OfferContainer_PerUnitOffer_Fragment
  ) | (
    { __typename?: 'FullPriceOffer' }
    & OfferContainer_FullPriceOffer_Fragment
  )> }
);

export type CounterFullPriceOfferMutationVariables = Exact<{
  input: CounterFullPriceOfferInput;
}>;


export type CounterFullPriceOfferMutation = (
  { __typename?: 'Mutation' }
  & { counterFullPriceOffer: Maybe<(
    { __typename?: 'FullPriceOffer' }
    & FullPriceOfferContainerFragment
  )> }
);

type OfferChatDrawerListingFragment_TransportationListing_ = (
  { __typename?: 'TransportationListing' }
  & Pick<TransportationListing, 'id'>
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id'>
  ) }
);

type OfferChatDrawerListingFragment_ForSaleListing_ = (
  { __typename?: 'ForSaleListing' }
  & Pick<ForSaleListing, 'id'>
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id'>
  ) }
);

type OfferChatDrawerListingFragment_WantedListing_ = (
  { __typename?: 'WantedListing' }
  & Pick<WantedListing, 'id'>
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id'>
  ) }
);

type OfferChatDrawerListingFragment_WholeSaleListing_ = (
  { __typename?: 'WholeSaleListing' }
  & Pick<WholeSaleListing, 'id'>
  & { postedBy: (
    { __typename?: 'ListingUser' }
    & Pick<ListingUser, 'id'>
  ) }
);

export type OfferChatDrawerListingFragment = OfferChatDrawerListingFragment_TransportationListing_ | OfferChatDrawerListingFragment_ForSaleListing_ | OfferChatDrawerListingFragment_WantedListing_ | OfferChatDrawerListingFragment_WholeSaleListing_;

export type OfferForChatQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OfferForChatQuery = (
  { __typename?: 'Query' }
  & { offer: (
    { __typename?: 'PerUnitOffer' }
    & Pick<PerUnitOffer, 'id'>
    & { listing: (
      { __typename?: 'ForSaleListing' }
      & OfferChatDrawerListingFragment_ForSaleListing_
    ) | (
      { __typename?: 'WantedListing' }
      & OfferChatDrawerListingFragment_WantedListing_
    ), fromUser: (
      { __typename?: 'OfferUser' }
      & Pick<OfferUser, 'id'>
      & PublicUserDetailsFragment_OfferUser_
    ), toUser: (
      { __typename?: 'OfferUser' }
      & Pick<OfferUser, 'id'>
      & PublicUserDetailsFragment_OfferUser_
    ) }
  ) | (
    { __typename?: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id'>
    & { listing: (
      { __typename?: 'TransportationListing' }
      & OfferChatDrawerListingFragment_TransportationListing_
    ), fromUser: (
      { __typename?: 'OfferUser' }
      & Pick<OfferUser, 'id'>
      & PublicUserDetailsFragment_OfferUser_
    ), toUser: (
      { __typename?: 'OfferUser' }
      & Pick<OfferUser, 'id'>
      & PublicUserDetailsFragment_OfferUser_
    ) }
  ) }
);

export type OfferRatingsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OfferRatingsQuery = (
  { __typename?: 'Query' }
  & { offer: (
    { __typename?: 'PerUnitOffer' }
    & Pick<PerUnitOffer, 'id'>
    & { toUser: (
      { __typename?: 'OfferUser' }
      & Pick<OfferUser, 'id'>
      & PublicUserLockupFragment_OfferUser_
      & RatingsListFragment_OfferUser_
    ), fromUser: (
      { __typename?: 'OfferUser' }
      & Pick<OfferUser, 'id'>
      & PublicUserLockupFragment_OfferUser_
      & RatingsListFragment_OfferUser_
    ) }
  ) | (
    { __typename?: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id'>
    & { toUser: (
      { __typename?: 'OfferUser' }
      & Pick<OfferUser, 'id'>
      & PublicUserLockupFragment_OfferUser_
      & RatingsListFragment_OfferUser_
    ), fromUser: (
      { __typename?: 'OfferUser' }
      & Pick<OfferUser, 'id'>
      & PublicUserLockupFragment_OfferUser_
      & RatingsListFragment_OfferUser_
    ) }
  ) }
);

export type OffersQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  proximity?: Maybe<Scalars['Int']>;
  counterpartyIdOrSlug?: Maybe<Scalars['String']>;
  availabilityStart?: Maybe<Scalars['Date']>;
  availabilityEnd?: Maybe<Scalars['Date']>;
}>;


export type OffersQuery = (
  { __typename?: 'Query' }
  & { offers: (
    { __typename?: 'OffersConnection' }
    & Pick<OffersConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'PerUnitOffer' }
      & Pick<PerUnitOffer, 'id'>
      & OfferListSliderItemFragment_PerUnitOffer_
    ) | (
      { __typename?: 'FullPriceOffer' }
      & Pick<FullPriceOffer, 'id'>
      & OfferListSliderItemFragment_FullPriceOffer_
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type OrderListingAddressFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'id'>
  & ListingAddressMapFragment
);

type OrderContainer_FulfillmentOrder_Fragment = (
  { __typename?: 'FulfillmentOrder' }
  & Pick<FulfillmentOrder, 'id' | 'entitlements'>
  & { offer: (
    { __typename?: 'PerUnitOffer' }
    & Pick<PerUnitOffer, 'id'>
    & { listing: (
      { __typename?: 'ForSaleListing' }
      & { postedAddress: (
        { __typename?: 'Address' }
        & OrderListingAddressFragment
      ) }
      & ListingMapFragment_ForSaleListing_
    ) | (
      { __typename?: 'WantedListing' }
      & { postedAddress: (
        { __typename?: 'Address' }
        & OrderListingAddressFragment
      ) }
      & ListingMapFragment_WantedListing_
    ) }
  ) }
  & OrderLabelFragment_FulfillmentOrder_
  & OrderHeader_FulfillmentOrder_Fragment
  & OrderDetails_FulfillmentOrder_Fragment
  & OrderFulfillActionFragment_FulfillmentOrder_
);

type OrderContainer_ShipmentOrder_Fragment = (
  { __typename?: 'ShipmentOrder' }
  & Pick<ShipmentOrder, 'id' | 'entitlements'>
  & { offer: (
    { __typename?: 'FullPriceOffer' }
    & Pick<FullPriceOffer, 'id'>
    & { listing: (
      { __typename?: 'TransportationListing' }
      & { pickupAddress: (
        { __typename?: 'Address' }
        & OrderListingAddressFragment
      ), deliveryAddress: (
        { __typename?: 'Address' }
        & OrderListingAddressFragment
      ), postedAddress: (
        { __typename?: 'Address' }
        & OrderListingAddressFragment
      ) }
    ) }
  ) }
  & OrderLabelFragment_ShipmentOrder_
  & OrderHeader_ShipmentOrder_Fragment
  & OrderDetails_ShipmentOrder_Fragment
  & OrderFulfillActionFragment_ShipmentOrder_
);

type OrderContainer_WholeSaleOrder_Fragment = (
  { __typename?: 'WholeSaleOrder' }
  & Pick<WholeSaleOrder, 'id' | 'entitlements'>
  & OrderLabelFragment_WholeSaleOrder_
  & OrderHeader_WholeSaleOrder_Fragment
  & OrderDetails_WholeSaleOrder_Fragment
  & OrderFulfillActionFragment_WholeSaleOrder_
);

export type OrderContainerFragment = OrderContainer_FulfillmentOrder_Fragment | OrderContainer_ShipmentOrder_Fragment | OrderContainer_WholeSaleOrder_Fragment;

export type OrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderQuery = (
  { __typename?: 'Query' }
  & { order: (
    { __typename?: 'FulfillmentOrder' }
    & OrderContainer_FulfillmentOrder_Fragment
  ) | (
    { __typename?: 'ShipmentOrder' }
    & OrderContainer_ShipmentOrder_Fragment
  ) | (
    { __typename?: 'WholeSaleOrder' }
    & OrderContainer_WholeSaleOrder_Fragment
  ) }
);

export type EditOrderMutationVariables = Exact<{
  input: UpdateOrderInput;
}>;


export type EditOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateOrder: (
    { __typename?: 'FulfillmentOrder' }
    & OrderEditFragment_FulfillmentOrder_
  ) | (
    { __typename?: 'ShipmentOrder' }
    & OrderEditFragment_ShipmentOrder_
  ) | (
    { __typename?: 'WholeSaleOrder' }
    & OrderEditFragment_WholeSaleOrder_
  ) }
);

export type FulfillOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FulfillOrderMutation = (
  { __typename?: 'Mutation' }
  & { fulfillOrder: Maybe<(
    { __typename?: 'FulfillmentOrder' }
    & OrderContainer_FulfillmentOrder_Fragment
  ) | (
    { __typename?: 'ShipmentOrder' }
    & OrderContainer_ShipmentOrder_Fragment
  ) | (
    { __typename?: 'WholeSaleOrder' }
    & OrderContainer_WholeSaleOrder_Fragment
  )> }
);

export type MakePaymentMutationVariables = Exact<{
  orderId: Scalars['ID'];
  fundingSourceId: Scalars['ID'];
}>;


export type MakePaymentMutation = (
  { __typename?: 'Mutation' }
  & { makePayment: (
    { __typename?: 'Transaction' }
    & { order: (
      { __typename?: 'FulfillmentOrder' }
      & OrderContainer_FulfillmentOrder_Fragment
    ) | (
      { __typename?: 'ShipmentOrder' }
      & OrderContainer_ShipmentOrder_Fragment
    ) | (
      { __typename?: 'WholeSaleOrder' }
      & OrderContainer_WholeSaleOrder_Fragment
    ) }
  ) }
);

export type MakePaymentOffPlatformMutationVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type MakePaymentOffPlatformMutation = (
  { __typename?: 'Mutation' }
  & { makePaymentOffPlatform: (
    { __typename?: 'Transaction' }
    & { order: (
      { __typename?: 'FulfillmentOrder' }
      & OrderContainer_FulfillmentOrder_Fragment
    ) | (
      { __typename?: 'ShipmentOrder' }
      & OrderContainer_ShipmentOrder_Fragment
    ) | (
      { __typename?: 'WholeSaleOrder' }
      & OrderContainer_WholeSaleOrder_Fragment
    ) }
  ) }
);

export type ConfirmOffPlatformPaymentMutationVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type ConfirmOffPlatformPaymentMutation = (
  { __typename?: 'Mutation' }
  & { confirmOffPlatformPayment: (
    { __typename?: 'Transaction' }
    & { order: (
      { __typename?: 'FulfillmentOrder' }
      & OrderContainer_FulfillmentOrder_Fragment
    ) | (
      { __typename?: 'ShipmentOrder' }
      & OrderContainer_ShipmentOrder_Fragment
    ) | (
      { __typename?: 'WholeSaleOrder' }
      & OrderContainer_WholeSaleOrder_Fragment
    ) }
  ) }
);

export type OrderForChatQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderForChatQuery = (
  { __typename?: 'Query' }
  & { order: (
    { __typename: 'FulfillmentOrder' }
    & Pick<FulfillmentOrder, 'id'>
    & { buyer: (
      { __typename?: 'OrderUser' }
      & Pick<OrderUser, 'id'>
      & PublicUserDetailsFragment_OrderUser_
    ), seller: (
      { __typename?: 'OrderUser' }
      & Pick<OrderUser, 'id'>
      & PublicUserDetailsFragment_OrderUser_
    ) }
  ) | (
    { __typename: 'ShipmentOrder' }
    & Pick<ShipmentOrder, 'id'>
    & { requester: (
      { __typename?: 'OrderUser' }
      & Pick<OrderUser, 'id'>
      & PublicUserDetailsFragment_OrderUser_
    ), transporter: (
      { __typename?: 'OrderUser' }
      & Pick<OrderUser, 'id'>
      & PublicUserDetailsFragment_OrderUser_
    ) }
  ) | (
    { __typename: 'WholeSaleOrder' }
    & Pick<WholeSaleOrder, 'id'>
    & { buyer: (
      { __typename?: 'OrderUser' }
      & Pick<OrderUser, 'id'>
      & PublicUserDetailsFragment_OrderUser_
    ), seller: (
      { __typename?: 'OrderUser' }
      & Pick<OrderUser, 'id'>
      & PublicUserDetailsFragment_OrderUser_
    ) }
  ) }
);

export type OrderItemEditContainerFragment = (
  { __typename?: 'WholeSaleOrderItem' }
  & Pick<WholeSaleOrderItem, 'id'>
  & OrderItemEditActionFormFragment
);

export type OrderItemQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderItemQuery = (
  { __typename?: 'Query' }
  & { orderItem: (
    { __typename?: 'WholeSaleOrderItem' }
    & OrderItemEditContainerFragment
  ) }
);

export type UpdateOrderItemMutationVariables = Exact<{
  input: UpdateOrderItemInput;
}>;


export type UpdateOrderItemMutation = (
  { __typename?: 'Mutation' }
  & { updateOrderItem: (
    { __typename?: 'WholeSaleOrderItem' }
    & OrderItemEditContainerFragment
  ) }
);

export type OrderRatingsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderRatingsQuery = (
  { __typename?: 'Query' }
  & { order: (
    { __typename: 'FulfillmentOrder' }
    & Pick<FulfillmentOrder, 'id'>
    & { buyer: (
      { __typename?: 'OrderUser' }
      & Pick<OrderUser, 'id'>
      & PublicUserLockupFragment_OrderUser_
      & RatingsListFragment_OrderUser_
    ), seller: (
      { __typename?: 'OrderUser' }
      & Pick<OrderUser, 'id'>
      & PublicUserLockupFragment_OrderUser_
      & RatingsListFragment_OrderUser_
    ) }
  ) | (
    { __typename: 'ShipmentOrder' }
    & Pick<ShipmentOrder, 'id'>
    & { transporter: (
      { __typename?: 'OrderUser' }
      & Pick<OrderUser, 'id'>
      & PublicUserLockupFragment_OrderUser_
      & RatingsListFragment_OrderUser_
    ), requester: (
      { __typename?: 'OrderUser' }
      & Pick<OrderUser, 'id'>
      & PublicUserLockupFragment_OrderUser_
      & RatingsListFragment_OrderUser_
    ) }
  ) | (
    { __typename: 'WholeSaleOrder' }
    & Pick<WholeSaleOrder, 'id'>
    & { buyer: (
      { __typename?: 'OrderUser' }
      & Pick<OrderUser, 'id'>
      & PublicUserLockupFragment_OrderUser_
      & RatingsListFragment_OrderUser_
    ), seller: (
      { __typename?: 'OrderUser' }
      & Pick<OrderUser, 'id'>
      & PublicUserLockupFragment_OrderUser_
      & RatingsListFragment_OrderUser_
    ) }
  ) }
);

export type OrdersQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
}>;


export type OrdersQuery = (
  { __typename?: 'Query' }
  & { orders: (
    { __typename?: 'OrdersConnection' }
    & Pick<OrdersConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'FulfillmentOrder' }
      & Pick<FulfillmentOrder, 'id'>
      & OrderListSliderItemFragment_FulfillmentOrder_
    ) | (
      { __typename?: 'ShipmentOrder' }
      & Pick<ShipmentOrder, 'id'>
      & OrderListSliderItemFragment_ShipmentOrder_
    ) | (
      { __typename?: 'WholeSaleOrder' }
      & Pick<WholeSaleOrder, 'id'>
      & OrderListSliderItemFragment_WholeSaleOrder_
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  ) }
);

export type OrderForRatingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderForRatingQuery = (
  { __typename?: 'Query' }
  & { order: (
    { __typename?: 'FulfillmentOrder' }
    & Pick<FulfillmentOrder, 'id'>
    & { buyer: (
      { __typename?: 'OrderUser' }
      & Pick<OrderUser, 'id' | 'displayName'>
    ), seller: (
      { __typename?: 'OrderUser' }
      & Pick<OrderUser, 'id' | 'displayName'>
    ), rating: Maybe<(
      { __typename?: 'Rating' }
      & Pick<Rating, 'id' | 'score' | 'comment'>
    )> }
  ) | (
    { __typename?: 'ShipmentOrder' }
    & Pick<ShipmentOrder, 'id'>
    & { transporter: (
      { __typename?: 'OrderUser' }
      & Pick<OrderUser, 'id' | 'displayName'>
    ), requester: (
      { __typename?: 'OrderUser' }
      & Pick<OrderUser, 'id' | 'displayName'>
    ), rating: Maybe<(
      { __typename?: 'Rating' }
      & Pick<Rating, 'id' | 'score' | 'comment'>
    )> }
  ) | (
    { __typename?: 'WholeSaleOrder' }
    & Pick<WholeSaleOrder, 'id'>
    & { rating: Maybe<(
      { __typename?: 'Rating' }
      & Pick<Rating, 'id' | 'score' | 'comment'>
    )> }
  ) }
);

export type SetRatingMutationVariables = Exact<{
  input: RatingInput;
}>;


export type SetRatingMutation = (
  { __typename?: 'Mutation' }
  & { setRating: Maybe<(
    { __typename?: 'Rating' }
    & Pick<Rating, 'id'>
  )> }
);

export type SubmitResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  resetPasswordKey: Scalars['String'];
}>;


export type SubmitResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type RegisterUserMutationVariables = Exact<{
  input: RegisterUserInput;
}>;


export type RegisterUserMutation = (
  { __typename?: 'Mutation' }
  & { registerUser: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type UsersQueryVariables = Exact<{
  sortField?: Maybe<UserSortFieldEnum>;
  sortDirection?: Maybe<UserSortDirectionEnum>;
  searchQuery?: Maybe<Scalars['String']>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'UsersPayload' }
    & Pick<UsersPayload, 'count'>
    & { results: Array<(
      { __typename?: 'User' }
      & UserListFragment
    )> }
  ) }
);

export type ApproveUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ApproveUserMutation = (
  { __typename?: 'Mutation' }
  & { approveUser: (
    { __typename?: 'User' }
    & UserListFragment
  ) }
);

export type LoginAsUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LoginAsUserMutation = (
  { __typename?: 'Mutation' }
  & { loginAs: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'displayName' | 'email' | 'roles' | 'entitlements'>
    & { impersonatedBy: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'displayName' | 'email' | 'roles'>
    )> }
  )> }
);

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type HomeListingTypesTotalQueryVariables = Exact<{ [key: string]: never; }>;


export type HomeListingTypesTotalQuery = (
  { __typename?: 'Query' }
  & { sale: (
    { __typename?: 'ListingsConnection' }
    & Pick<ListingsConnection, 'totalCount'>
  ), wanted: (
    { __typename?: 'ListingsConnection' }
    & Pick<ListingsConnection, 'totalCount'>
  ), transportation: (
    { __typename?: 'ListingsConnection' }
    & Pick<ListingsConnection, 'totalCount'>
  ) }
);


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "DwollaFundingSource": [
      "DwollaBankFundingSource",
      "DwollaBalanceFundingSource"
    ],
    "WholesalePriceValue": [
      "WholesalePrice",
      "WholesalePricePerUnit"
    ],
    "Listing": [
      "TransportationListing",
      "ForSaleListing",
      "WantedListing",
      "WholeSaleListing"
    ],
    "PerUnitOfferListing": [
      "ForSaleListing",
      "WantedListing"
    ],
    "Offer": [
      "PerUnitOffer",
      "FullPriceOffer"
    ],
    "Order": [
      "FulfillmentOrder",
      "ShipmentOrder",
      "WholeSaleOrder"
    ],
    "PriceValue": [
      "Price",
      "PricePerUnit",
      "CartPrice",
      "CartItemPrice",
      "WholesalePrice",
      "WholesalePricePerUnit"
    ],
    "UnitPriceValue": [
      "PricePerUnit",
      "CartItemPrice",
      "WholesalePricePerUnit"
    ],
    "PublicUser": [
      "Merchant",
      "CartUser",
      "ListingUser",
      "OfferUser",
      "OrderUser",
      "ChatUser"
    ],
    "ChatContextDecoration": [
      "WantedListing",
      "ForSaleListing",
      "TransportationListing",
      "WholeSaleListing",
      "PerUnitOffer",
      "FullPriceOffer",
      "FulfillmentOrder",
      "ShipmentOrder",
      "Merchant"
    ]
  }
};
      export default result;
    