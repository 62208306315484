import { gql, useQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { navigate, useLocation } from '@reach/router';
import AddListingCard from 'components/AddListing/Card';
import Collection from 'components/Collection';
import CollectionGrid from 'components/Collection/Grid';
import CollectionGridItem from 'components/Collection/GridItem';
import CollectionHeader from 'components/Collection/Header';
import CollectionList from 'components/Collection/List';
import CollectionListItem from 'components/Collection/ListItem';
import CollectionSeeMore from 'components/Collection/SeeMore';
import CollectionSlider from 'components/Collection/Slider';
import CollectionSliderItem from 'components/Collection/SliderItem';
import CollectionViewModeSelector, {
  sanitizeViewMode,
} from 'components/Collection/ViewModeSelector';
import ListingListItem from 'components/Listing/ListItem';
import ListingListSliderItem from 'components/Listing/ListSliderItem';
import { ListingListSliderItemFragment } from 'components/Listing/ListSliderItem/fragments';
import Loading from 'components/Loading';
import type {
  HomeSaleListingsQuery,
  HomeSaleListingsQueryVariables,
} from 'generated-types';
import { ListingType } from 'generated-types';
import React, { useCallback, useState } from 'react';
import cleanUrl from 'utils/clean-url';
import { addQueryParam } from 'utils/query-params';

const SALE_LISTINGS = gql`
  query HomeSaleListingsQuery(
    $text: String
    $categoryIds: [ID!]
    $includeTagIds: [ID!]
    $proximity: Int
    $postedByIdOrSlug: String
    $availabilityStart: Date
    $availabilityEnd: Date
    $after: String
    $types: [ListingType!]
  ) {
    listings(
      query: {
        types: $types
        text: $text
        categoryIds: $categoryIds
        includeTagIds: $includeTagIds
        postedByIdOrSlug: $postedByIdOrSlug
      }
      first: 10
      after: $after
      filter: {
        proximity: $proximity
        availabilityStart: $availabilityStart
        availabilityEnd: $availabilityEnd
      }
    ) {
      nodes {
        id
        ...ListingListSliderItem
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${ListingListSliderItemFragment}
`;

type Props = {
  text?: string;
  categoryIds?: string[];
  includeTagIds?: string[];
  proximity?: number;
  postedByIdOrSlug?: string;
  showEmpty?: boolean;
  availabilityRange?: {
    start?: string;
    end?: string;
  };
  types?: ListingType[];
  /**
   * Use horizontal list rendering
   */
  listSlider?: boolean;
};

const HomeSaleListingsContainer: React.FC<Props> = ({
  text,
  categoryIds,
  includeTagIds,
  proximity,
  postedByIdOrSlug,
  showEmpty,
  availabilityRange,
  types,
  listSlider,
}) => {
  const { search } = useLocation();
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);

  const { loading, error, data, fetchMore } = useQuery<
    HomeSaleListingsQuery,
    HomeSaleListingsQueryVariables
  >(SALE_LISTINGS, {
    variables: {
      text,
      categoryIds,
      includeTagIds,
      proximity,
      postedByIdOrSlug,
      availabilityStart: availabilityRange?.start,
      availabilityEnd: availabilityRange?.end,
      types: types ?? [ListingType.FOR_SALE, ListingType.WHOLE_SALE],
    },
  });

  const loadMoreHandler = useCallback(() => {
    if (!data) {
      throw new Error('Trying to load more without current data');
    }
    if (fetchMoreLoading) {
      throw new Error(
        'Trying to load next page before current page finishes loading',
      );
    }

    setFetchMoreLoading(true);
    fetchMore({
      variables: {
        after: data.listings.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setFetchMoreLoading(false);
        if (!fetchMoreResult) return prev;
        return {
          listings: {
            __typename: prev.listings.__typename,
            nodes: [...prev.listings.nodes, ...fetchMoreResult.listings.nodes],
            pageInfo: fetchMoreResult.listings.pageInfo,
            totalCount: prev.listings.totalCount,
          },
        };
      },
    });
  }, [fetchMore, fetchMoreLoading, data]);

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <Typography color="error">Unable to load Sale Listings.</Typography>;
  }

  if (data.listings.totalCount === 0 && !showEmpty) {
    return null;
  }

  if (listSlider) {
    return (
      <Collection>
        <CollectionHeader>
          Buy
          {data.listings.totalCount > 5 && (
            <CollectionSeeMore
              to={cleanUrl('listings/for-sale', new URLSearchParams(search))}
            >
              <Typography color="primary">See all items for sale</Typography>
            </CollectionSeeMore>
          )}
        </CollectionHeader>
        <CollectionSlider
          onLoadMore={loadMoreHandler}
          hasMore={data.listings.pageInfo.hasNextPage}
          loading={fetchMoreLoading}
        >
          {data.listings.nodes.map((item) => (
            <CollectionSliderItem key={item.id}>
              <ListingListSliderItem listing={item} />
            </CollectionSliderItem>
          ))}
          {data.listings.totalCount === 0 && <AddListingCard />}
        </CollectionSlider>
      </Collection>
    );
  }

  const mode = sanitizeViewMode(new URLSearchParams(search).get('viewMode'));

  return (
    <Collection>
      <CollectionHeader>
        Buy ({data.listings.totalCount}){' '}
        <CollectionViewModeSelector
          mode={mode}
          onChange={(mode) => {
            navigate(cleanUrl('', addQueryParam('viewMode', mode)));
          }}
        />
      </CollectionHeader>

      {mode === 'grid' && (
        <CollectionGrid
          loading={fetchMoreLoading}
          hasMore={data.listings.pageInfo.hasNextPage}
          onLoadMore={loadMoreHandler}
        >
          {data.listings.nodes.map((item) => (
            <CollectionGridItem key={item.id}>
              <ListingListSliderItem listing={item} />
            </CollectionGridItem>
          ))}
          {data.listings.totalCount === 0 && <AddListingCard />}
        </CollectionGrid>
      )}
      {mode === 'list' && (
        <CollectionList
          loading={fetchMoreLoading}
          hasMore={data.listings.pageInfo.hasNextPage}
          onLoadMore={loadMoreHandler}
        >
          {data.listings.nodes.map((item) => (
            <CollectionListItem key={item.id}>
              <ListingListItem listing={item} />
            </CollectionListItem>
          ))}
          {data.listings.totalCount === 0 && <AddListingCard />}
        </CollectionList>
      )}
    </Collection>
  );
};

export default HomeSaleListingsContainer;
