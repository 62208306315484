import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { navigate } from '@reach/router';
import { Auth } from 'contexts/Auth';
import { ChatNotifications } from 'contexts/Firebase/ChatNotifications';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import cleanUrl from 'utils/clean-url';

const StyledButton = styled(Button)`
  color: #00ff00;
`;

const ChatToast: React.FC = () => {
  const { notifications } = useContext(ChatNotifications);
  const [isToastShown, setIsToastShown] = useState(false);
  const [currentCount, setCurrentCount] = useState(0);

  useEffect(() => {
    if (!notifications) {
      return;
    }
    if (notifications.size > 0 && notifications.size >= currentCount) {
      setIsToastShown(true);
    }
    setCurrentCount(notifications.size);
    // We do not want to add `currentCount` as a dependency. This is because
    // I only want to trigger notifications for new messages. If I leave the
    // `currentCount` in, then the useEffect will detect when I update
    // the current count and I only want it watching for new `notifications`.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  const handleClose = useCallback(() => {
    setIsToastShown(false);
  }, [setIsToastShown]);

  const toastLinkClick = useCallback(() => {
    const messageFolder = notifications?.docs?.[0]?.data()?.messageFolder;
    const searchParams = new URLSearchParams();
    searchParams.set('notification', messageFolder);
    navigate(cleanUrl('', searchParams, 'chat'));

    setIsToastShown(false);
  }, [setIsToastShown, notifications]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isToastShown}
      onClose={handleClose}
      message="New Message received!"
      action={
        <React.Fragment>
          <StyledButton color="primary" onClick={toastLinkClick}>
            See message
          </StyledButton>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};

const ChatToastWrapper: React.FC = () => {
  const { user } = useContext(Auth);
  if (!user) {
    return null;
  }

  return <ChatToast />;
};

export default ChatToastWrapper;
