import { Link } from '@reach/router';
import React from 'react';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

type Props = {
  to: string;
};

const ListSliderSeeMore: React.FC<Props> = (props) => {
  return <StyledLink {...props} />;
};

export default ListSliderSeeMore;
