import React from 'react';
import styled from 'styled-components';
import Links from './Links';
import logo from './logo.svg';
import MobileLinks from './MobileLinks';

export const height = '68px';

const StyledFooter = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.95);
  height: ${height};
  padding: 0 30px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  /* hide logo on small screens */
  ${(p) => p.theme.breakpoints.down('sm')} {
    & .logo {
      display: none;
    }
  }
`;

const StyledLinks = styled(Links)`
  /* hide menu on small screens */
  ${(p) => p.theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const StyledMobileLinks = styled(MobileLinks)`
  width: 100%;

  /* hide menu on medium screens */
  ${(p) => p.theme.breakpoints.up('md')} {
    display: none;
  }
`;

const Footer: React.FC = () => {
  return (
    <StyledFooter>
      <img src={logo} alt="" className="logo" />
      <StyledLinks />
      <StyledMobileLinks />
    </StyledFooter>
  );
};

export default Footer;
