import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';

const StyledList = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 1rem;
  row-gap: 1rem;
  padding: 1rem;
  margin: 0;
  article {
    width: 100%;
    min-width: 18rem;
  }
  @media (max-width: 1220px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

const StyledLoading = styled.li`
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  padding: 0;
`;

type Props = {
  onLoadMore?: () => void;
  hasMore?: boolean;
  loading?: boolean;
  children: React.ReactNode;
};

export default function CollectionGrid(props: Props): JSX.Element {
  const { onLoadMore, hasMore, loading, children, ...rest } = props;

  return (
    <StyledList {...rest}>
      {children}
      {hasMore && (
        <StyledLoading>
          {!loading && (
            <Waypoint
              onEnter={() => {
                onLoadMore?.();
              }}
            />
          )}
          <CircularProgress />
        </StyledLoading>
      )}
    </StyledList>
  );
}
