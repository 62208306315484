import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { StylesProvider } from '@material-ui/styles';
import { navigate, Router } from '@reach/router';
import { createUploadLink } from 'apollo-upload-client';
import Loading from 'components/Loading';
import AdminChatToast from 'containers/Chat/AdminChatToast';
import ChatToast from 'containers/Chat/ChatToast';
import AuthProvider from 'contexts/Auth';
import FirebaseProvider from 'contexts/Firebase';
import AdminChatNotificationsProvider from 'contexts/Firebase/AdminChatNotifications';
import ChatNotificationsProvider from 'contexts/Firebase/ChatNotifications';
import ToastsProvider from 'contexts/Toasts';
import generatedTypes from 'generated-types';
import React, { lazy, Suspense } from 'react';
import FullStory from 'react-fullstory';
import Drawers from 'routes/Drawers';
import Home from 'routes/Home';
import { ThemeProvider } from 'styled-components';
import theme from './theme';

const uri = process.env.REACT_APP_API_URI;
const fsOrg = process.env.REACT_APP_FULLSTORY_ORG;

const client = new ApolloClient({
  link: from([
    onError(({ graphQLErrors, networkError }) => {
      // check for unauthenticated errors and redirect the user to login if needed
      if (
        graphQLErrors &&
        graphQLErrors.find((e) => e.extensions?.code === 'UNAUTHENTICATED')
      ) {
        const { pathname, search, hash } = window.location;

        const currentSearchParams = new URLSearchParams(search);

        if (currentSearchParams.has('login')) {
          // redirect already happened... do nothing
          return;
        }

        // clear the store to remove any user sensitive information
        // wait for sensitive information to finish resetting before any
        // direct, this prevents new requests to be triggered by the redirected
        // page which can cause issues with cache handling.
        client.resetStore().then(() => {
          // because we are redirecting to the login page, we don't want to
          // keep the current URL, so we start with a clean state of
          // searchParams
          const searchParams = new URLSearchParams();
          searchParams.set('login', '');
          searchParams.set('t', pathname + search + hash);

          navigate(`/?${searchParams}${hash}`);
        });

        return;
      }

      // default onError implementation as in:
      // https://www.apollographql.com/docs/react/data/error-handling/#usage
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );
        });
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    }),
    createUploadLink({ uri, credentials: 'include' }),
  ]),
  cache: new InMemoryCache({
    possibleTypes: generatedTypes.possibleTypes,
    typePolicies: {
      Cart: {
        fields: {
          entitlements: { merge: false },
          totalPrice: { merge: false },
        },
      },
      Listing: {
        fields: {
          entitlements: { merge: false },
        },
      },
      Offer: {
        fields: {
          entitlements: { merge: false },
        },
      },
      Order: {
        fields: {
          entitlements: { merge: false },
        },
      },
      FulfillmentOrder: {
        fields: {
          entitlements: { merge: false },
        },
      },
      ShipmentOrder: {
        fields: {
          entitlements: { merge: false },
        },
      },
      WholeSaleOrder: {
        fields: {
          entitlements: { merge: false },
        },
      },
      WholeSaleOrderItem: {
        fields: {
          price: { merge: false },
        },
      },
    },
  }),

  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      // more information on this:
      // https://www.apollographql.com/docs/react/data/queries/#options
      // and the reason why it was introduced:
      // https://github.com/apollographql/apollo-client/pull/6712
      nextFetchPolicy: 'cache-first',
    },
  },
});

/**
 * Lazy routes
 */
const Account = lazy(() => import('routes/Account'));
const Admin = lazy(() => import('routes/Admin'));
const Listings = lazy(() => import('routes/Listings'));
const Merchant = lazy(() => import('routes/Merchant'));
const NotFound = lazy(() => import('routes/NotFound'));
const Orders = lazy(() => import('routes/Orders'));
const Privacy = lazy(() => import('routes/Privacy'));
const Terms = lazy(() => import('routes/Terms'));

/**
 * Main app booting... 🚀
 */
const App: React.FC = () => {
  return (
    <>
      {fsOrg && <FullStory org={fsOrg} />}
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst={true}>
          <MuiThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <CssBaseline />
              <Suspense fallback={<Loading fullPage={true} />}>
                <ToastsProvider>
                  <ApolloProvider client={client}>
                    <AuthProvider>
                      <FirebaseProvider>
                        <ChatNotificationsProvider>
                          <AdminChatNotificationsProvider>
                            <ChatToast />
                            <AdminChatToast />
                            <Router>
                              <NotFound default={true} />
                              <Home path="/" />
                              <Terms path="terms" />
                              <Privacy path="privacy" />
                              <Merchant path="merchants/*" />
                              <Admin path="admin/*" />
                              <Account path="account/*" />
                              <Listings path="listings/:group" />
                              <Orders path="orders/*" />
                            </Router>
                            {/* Separate router for drawers to open/close based on queryParams */}
                            <Router primary={false}>
                              <Drawers path="/*" />
                            </Router>
                          </AdminChatNotificationsProvider>
                        </ChatNotificationsProvider>
                      </FirebaseProvider>
                    </AuthProvider>
                  </ApolloProvider>
                </ToastsProvider>
              </Suspense>
            </LocalizationProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
