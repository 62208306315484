import type { OrderLabelFragment } from 'generated-types';
import React from 'react';

type Props = {
  order: OrderLabelFragment;
};

const orderTypeLabelMap: Record<OrderLabelFragment['__typename'], string> = {
  WholeSaleOrder: 'Sales Order (Wholesale)',
  FulfillmentOrder: 'Sales Order (Bulk)',
  ShipmentOrder: 'Shipping Order',
};

export default function OrderLabel({ order }: Props): JSX.Element {
  return <>{orderTypeLabelMap[order.__typename]}</>;
}
