import { gql } from '@apollo/client';
import { OfferListCounterPartyFragment } from '../ListCounterParty/fragments';

const CommonListingOfferListSliderFragment = gql`
  fragment CommonListingOfferListSliderFragment on Listing {
    id

    product {
      id
      name
      type
      image {
        url
      }
    }
  }
`;

export const OfferListSliderItemFragment = gql`
  fragment OfferListSliderItemFragment on Offer {
    id
    stage

    previousOffer {
      id
    }

    ... on PerUnitOffer {
      price {
        amount
        currency
        unit
      }

      listing {
        ...CommonListingOfferListSliderFragment

        ... on ForSaleListing {
          price {
            amount
            currency
            unit
          }
          quantity {
            amount
            unit
          }
          cutOrSize
        }

        ... on WantedListing {
          price {
            amount
            currency
            unit
          }
          quantity {
            amount
            unit
          }
          cutOrSize
        }
      }
    }

    ... on FullPriceOffer {
      price {
        amount
        currency
      }

      listing {
        ...CommonListingOfferListSliderFragment

        ... on TransportationListing {
          quantity {
            amount
            unit
          }
        }
      }
    }

    ...OfferListCounterPartyFragment
  }
  ${CommonListingOfferListSliderFragment}
  ${OfferListCounterPartyFragment}
`;
