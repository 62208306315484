import { gql } from '@apollo/client';

const CommonOfferUserOfferListCounterPartyFragment = gql`
  fragment CommonOfferUserOfferListCounterPartyFragment on OfferUser {
    id
    displayName
  }
`;

const CommonListingOfferListCounterPartyFragment = gql`
  fragment CommonListingOfferListCounterPartyFragment on Listing {
    __typename
    id

    postedBy {
      id
    }
  }
`;

export const OfferListCounterPartyFragment = gql`
  fragment OfferListCounterPartyFragment on Offer {
    id

    fromUser {
      ...CommonOfferUserOfferListCounterPartyFragment
    }
    toUser {
      ...CommonOfferUserOfferListCounterPartyFragment
    }

    ... on PerUnitOffer {
      listing {
        ...CommonListingOfferListCounterPartyFragment
      }
    }
    ... on FullPriceOffer {
      listing {
        ...CommonListingOfferListCounterPartyFragment
      }
    }
  }
  ${CommonOfferUserOfferListCounterPartyFragment}
  ${CommonListingOfferListCounterPartyFragment}
`;
