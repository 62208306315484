import { Avatar, Chip, Typography } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { Link } from '@reach/router';
import ListSliderTile from 'components/ListSlider/Tile';
import { Auth } from 'contexts/Auth';
import type { CartListSliderItemFragment } from 'generated-types';
import { CartStage } from 'generated-types';
import React, { useContext } from 'react';
import styled from 'styled-components';
import cleanUrl from 'utils/clean-url';
import { currencyFormatter } from 'utils/units';

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 13px;
  color: white;
  letter-spacing: -0.4px;
  text-decoration: none;
  border-radius: 6px;
  line-height: 0.5em;
  &::before {
    content: '';
    position: absolute;
    bottom: 0.625em;
    left: 0.625em;
    height: 0.625em;
    width: 0.625em;
    border-radius: 1em;
    display: block;
  }
`;

const StyledListItemContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const StyledListItemDescription = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
`;

const StyledListItemTitle = styled.div`
  white-space: nowrap;
  width: 100%;
  align-self: flex-start;
  letter-spacing: -0.4px;
  & h3 {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledListItemPrice = styled.p`
  ${(p) => p.theme.typography.h4}
  letter-spacing: -0.4px;
  margin: ${(p) => p.theme.spacing(1, 0, 0, 0)};
`;

const StyledAvatarGroup = styled(AvatarGroup)`
  align-self: flex-start;
  & .avatar {
    font-size: 0.75rem;
    width: ${(p) => p.theme.spacing(3)}px;
    height: ${(p) => p.theme.spacing(3)}px;
  }
`;

type Props = {
  cart: CartListSliderItemFragment;
};

const CartListSliderItem: React.FC<Props> = (props) => {
  const { cart } = props;
  const { items } = cart;

  const { user } = useContext(Auth);

  const { search, hash } = window.location;
  const searchParams = new URLSearchParams(search);
  searchParams.set('cart', cart.id);

  // we should always be authenticated at this point...
  if (!user) {
    throw new Error('In CartListSliderItem rendering and not Authenticated');
  }

  return (
    <ListSliderTile backgroundImage="https://images.unsplash.com/photo-1557821552-17105176677c">
      <StyledLink to={cleanUrl('', searchParams, hash)}>
        <StyledAvatarGroup spacing={4} max={6} classes={{ avatar: 'avatar' }}>
          {items.map((item) => (
            <Avatar
              key={item.id}
              alt={item.listing.product.name}
              src={item.listing.product.image?.url ?? ''}
            />
          ))}
        </StyledAvatarGroup>
        <StyledListItemContent>
          {cart.stage === CartStage.DECLINED && (
            <Chip
              label="Declined"
              color="primary"
              style={{ backgroundColor: '#7c0101' }}
              size="small"
            />
          )}

          <StyledListItemDescription>
            <Typography variant="subtitle1">
              {user.id === cart.toUser.id ? (
                <>
                  Cart from{' '}
                  {cart.fromUser.businessName ?? cart.fromUser.displayName}
                </>
              ) : (
                <>
                  Cart for {cart.toUser.businessName ?? cart.toUser.displayName}
                </>
              )}
            </Typography>
            <StyledListItemTitle>
              <Typography variant="h3">
                Products in cart {items.length}
              </Typography>
            </StyledListItemTitle>

            <StyledListItemPrice>
              {cart.totalPrice.amount !== null
                ? currencyFormatter(
                    cart.totalPrice.amount,
                    cart.totalPrice.currency,
                  )
                : '(Price hidden)'}
            </StyledListItemPrice>
          </StyledListItemDescription>
        </StyledListItemContent>
      </StyledLink>
    </ListSliderTile>
  );
};

export default CartListSliderItem;
