import DevConfig from 'configs/Firebase/dev';
import ProductionConfig from 'configs/Firebase/production';
import StagingConfig from 'configs/Firebase/staging';

const env = process.env.REACT_APP_ENV;
let config = {};

switch (env) {
  case 'production':
    config = ProductionConfig;
    break;
  case 'staging':
    config = StagingConfig;
    break;
  case 'development':
    config = DevConfig;
    break;
}

export default config;
