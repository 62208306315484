import { Avatar } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { Link } from '@reach/router';
import ListingPoster from 'components/Listing/Poster';
import { Auth } from 'contexts/Auth';
import type { ListingListSliderItemFragment } from 'generated-types';
import React, { useContext } from 'react';
import styled from 'styled-components';
import cleanUrl from 'utils/clean-url';
import { cutsAndSizesLabels } from 'utils/cutsAndSizes';
import { getTransportImage } from 'utils/getTransportationImage';
import { currencyPerUnitFormatter, unitFormatter } from 'utils/units';

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0;
  color: black;
  text-decoration: none;
  align-items: center;
`;
const StyledLinkIntro = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding: 0;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
`;

const StyledAvatar = styled(Avatar)`
  width: ${(p) => p.theme.spacing(7)}px;
  height: ${(p) => p.theme.spacing(7)}px;
  background: rgba(0, 0, 0, 0.1);
  margin-right: 1rem;
  @media (max-width: 768px) {
    width: ${(p) => p.theme.spacing(4)}px;
    height: ${(p) => p.theme.spacing(4)}px;
  }
`;

const StyledListItemType = styled.span`
  ${(p) => p.theme.typography.subtitle2}
  line-height: 1;
  margin: 0;
  @media (max-width: 768px) {
    ${(p) => p.theme.typography.h4}
    font-weight: 400;
  }
`;

const PostedBy = styled.span`
  ${(p) => p.theme.typography.subtitle2}
  line-height: 1;
  margin: 0;
  @media (max-width: 768px) {
    ${(p) => p.theme.typography.h4}
    font-weight: 400;
  }
`;

const StyledListProduct = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  width: 18rem;
  @media (max-width: 768px) {
    width: 8rem;
  }
`;

const StyledListItemProductType = styled.h3`
  ${(p) => p.theme.typography.subtitle2}
  line-height: 1;
  margin: 0;
  white-space: nowrap;
  width: 100%;
  align-self: flex-start;
  @media (max-width: 768px) {
    ${(p) => p.theme.typography.h4}
    font-weight: 400;
  }
`;

const StyledListItemProductName = styled.h2`
  ${(p) => p.theme.typography.h3}
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  align-self: flex-start;
  @media (max-width: 768px) {
    ${(p) => p.theme.typography.h4}
  }
`;
const StyledListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 400;
  @media (max-width: 768px) {
    justify-content: flex-end;
  }
`;

const StyledListItemPrice = styled.span`
  ${(p) => p.theme.typography.subtitle2}
  margin: 0;
  line-height: 1;
  @media (max-width: 768px) {
    ${(p) => p.theme.typography.h4}
    font-weight: 400;
  }
`;

const StyledListItemPriceType = styled.span`
  ${(p) => p.theme.typography.subtitle2}
  line-height: 1;
  margin: 0;
  @media (max-width: 768px) {
    ${(p) => p.theme.typography.h4}
  }
`;

const StyledLinkFooter = styled.span`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
`;

const StyledListTags = styled.div`
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-width: 18rem;
  gap: 0.25rem;
  color: ${(p) => p.theme.palette.background.default};
  margin: 0 0.25rem 0 0;
  @media (max-width: 768px) {
    min-width: 4.25rem;
  }
`;

const listingMap = {
  ForSaleListing: 'is selling',
  WantedListing: 'wants',
  TransportationListing: 'needs transport for',
  WholeSaleListing: 'is selling',
};

type Props = {
  listing: ListingListSliderItemFragment;
};

const ListingListItem: React.FC<Props> = (props) => {
  const { user } = useContext(Auth);
  const { listing } = props;
  const { product } = listing;
  const { search, hash } = window.location;
  const searchParams = new URLSearchParams(search);
  searchParams.set('listing', listing.id);

  let backgroundImage;

  if (listing.__typename === 'TransportationListing') {
    backgroundImage = getTransportImage(listing.id);
  } else {
    backgroundImage = product.image ? product.image.url : 'none';
  }

  return (
    <StyledLink to={cleanUrl('', searchParams, hash)}>
      <StyledAvatar src={backgroundImage} />
      <StyledListProduct>
        <StyledListItemProductType>{product.type}</StyledListItemProductType>
        <StyledListItemProductName>{product.name}</StyledListItemProductName>
      </StyledListProduct>
      <StyledLinkIntro>
        <StyledListItem>
          <div>
            <ListingPoster listing={listing}>
              {({ user }) => <PostedBy>{user.displayName}</PostedBy>}
            </ListingPoster>{' '}
            <StyledListItemType>
              {listingMap[listing.__typename]}
            </StyledListItemType>{' '}
            <StyledListItemPrice>
              {(listing.__typename === 'ForSaleListing' ||
                listing.__typename === 'WantedListing' ||
                listing.__typename === 'WholeSaleListing') &&
                listing.cutOrSize &&
                cutsAndSizesLabels[listing.cutOrSize]}
              {(listing.__typename === 'ForSaleListing' ||
                listing.__typename === 'WantedListing' ||
                listing.__typename === 'TransportationListing') &&
                ` ${unitFormatter(
                  listing.quantity.amount,
                  listing.quantity.unit,
                )}`}
              {(listing.__typename === 'ForSaleListing' ||
                listing.__typename === 'WantedListing') &&
                ` at ${currencyPerUnitFormatter(
                  listing.price.amount,
                  listing.price.currency,
                  listing.price.unit,
                )}`}
              {listing.__typename === 'WholeSaleListing' &&
                (listing.wholesalePrice.amount
                  ? ` at ${currencyPerUnitFormatter(
                      listing.wholesalePrice.amount,
                      listing.wholesalePrice.currency,
                      listing.wholesalePrice.unit,
                    )}`
                  : '(Price hidden)')}
              {listing.__typename === 'TransportationListing' && (
                <span>
                  {' '}
                  transported{' '}
                  {Math.round(listing?.estimatedTravelDistance * 10) / 10} mi
                </span>
              )}
            </StyledListItemPrice>
          </div>
        </StyledListItem>
        <StyledLinkFooter>
          {(listing.__typename === 'ForSaleListing' ||
            listing.__typename === 'WantedListing' ||
            listing.__typename === 'WholeSaleListing') && (
            <StyledListItemPriceType>
              {listing.__typename === 'WholeSaleListing'
                ? 'Wholesale'
                : 'Negotiable'}
            </StyledListItemPriceType>
          )}
          {listing.__typename === 'TransportationListing' && (
            <StyledListItemPriceType>
              {listing?.pickupAddress?.city}, {listing?.pickupAddress?.state} to{' '}
              {listing?.deliveryAddress?.city},{' '}
              {listing?.deliveryAddress?.state}{' '}
            </StyledListItemPriceType>
          )}
          <StyledListTags>
            {listing.tags?.map((tag) => (
              <Chip
                key={tag.id}
                label={tag.name}
                variant="outlined"
                color="primary"
                size="small"
              />
            ))}
            {user?.id === listing.postedBy.id && (
              <Chip color="secondary" size="small" label="Your Listing" />
            )}
          </StyledListTags>
        </StyledLinkFooter>
      </StyledLinkIntro>
    </StyledLink>
  );
};

export default ListingListItem;
