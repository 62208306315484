import { gql } from '@apollo/client';

export const ListingPosterFragment = gql`
  fragment ListingPoster on Listing {
    __typename
    id

    postedBy {
      id
      displayName
      averageRating
      avatar {
        url
      }
      # TODO: move into Merchant
      businessName
      slug
    }
  }
`;
