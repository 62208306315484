import type { Unit } from 'generated-types';

type UnitLabel = {
  singular: string;
  plural: string;
};

type UnitLabels = {
  [unit in Unit]: UnitLabel;
};

export const unitLabels: UnitLabels = Object.freeze({
  // US ton
  TON: {
    singular: 'ton',
    plural: 'tons',
  },
  // Metric ton
  TONNE: {
    singular: 'metric ton',
    plural: 'metric tons',
  },
  BUSHEL: {
    singular: 'bushel',
    plural: 'bushels',
  },
  CWT: {
    singular: 'hundredweight (CWT)',
    plural: 'hundredweights',
  },
  LB: {
    singular: 'pound',
    plural: 'pounds',
  },
  KG: {
    singular: 'kilogram',
    plural: 'kilograms',
  },
  UNIT: {
    singular: 'unit',
    plural: 'units',
  },
  PECK: {
    singular: 'peck',
    plural: 'pecks',
  },
  DRY_GALLON: {
    singular: 'dry gallon',
    plural: 'dry gallons',
  },
  QUART: {
    singular: 'quart',
    plural: 'quarts',
  },
  PINT: {
    singular: 'pint',
    plural: 'pints',
  },
  GALLON: {
    singular: 'gallon',
    plural: 'gallons',
  },
  YARD: {
    singular: 'yard',
    plural: 'yards',
  },
  CASE: {
    singular: 'case',
    plural: 'cases',
  },
  BOX: {
    singular: 'box',
    plural: 'boxes',
  },
  BOTTLE: {
    singular: 'bottle',
    plural: 'bottles',
  },
  JAR: {
    singular: 'jar',
    plural: 'jars',
  },
  BAG: {
    singular: 'bag',
    plural: 'bags',
  },
  CONTAINER: {
    singular: 'container',
    plural: 'containers',
  },
  BUNCH: {
    singular: 'bunch',
    plural: 'bunches',
  },
});

export const unitFormatter = (amount: string, unit: Unit) => {
  const unitMap = unitLabels[unit];

  const amountValue = Number(amount);
  const unitString = amountValue === 1 ? unitMap.singular : unitMap.plural;

  return `${amountValue.toLocaleString()} ${unitString}`;
};

export const currencyFormatter = (amount: string, currency: string) =>
  Number(amount).toLocaleString(undefined, {
    style: 'currency',
    currency,
  });

export const currencyPerUnitFormatter = (
  amount: string,
  currency: string,
  unit: Unit,
) => {
  const unitMap = unitLabels[unit];

  return `${currencyFormatter(amount, currency)}/${unitMap.singular}`;
};
