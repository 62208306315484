import NotificationsIcon from '@material-ui/icons/Notifications';
import React from 'react';

type Props = {
  count: number;
};

const NotificationBell: React.FC<Props> = ({ count }) => (
  <>
    <NotificationsIcon />
    {count}
  </>
);

export default NotificationBell;
