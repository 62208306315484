import { Drawer, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Terms from 'components/Legal/Terms';
import DownChevron from 'components/Shared/DownChevron';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 1rem;
`;

const StyledDrawer = styled(Drawer)`
  & .paper {
    margin: 0;
    border-radius: 16px 16px 0 0;
    height: 38%;
    max-height: 466px;
    min-height: 288px;
    & > .MuiIconButton-root {
      position: absolute;
      top: 0;
      right: 0;
      height: 68px;
      width: 68px;
      padding: 0;
    }
    & h3 {
      line-height: 68px;
      height: 68px;
      text-align: center;
    }
    & p {
      margin: 0 26px;
    }
  }
`;

const Legal: React.FC = () => {
  const [state, setState] = React.useState({
    isOpen: false,
  });
  return (
    <>
      <Button color="primary" onClick={() => setState({ isOpen: true })}>
        Terms
      </Button>
      <StyledDrawer
        classes={{ paper: 'paper' }}
        anchor="bottom"
        open={state.isOpen}
        onClose={() => {
          setState({ isOpen: false });
        }}
      >
        <IconButton
          color="secondary"
          aria-label="close"
          onClick={() => setState({ isOpen: false })}
        >
          <DownChevron />
        </IconButton>
        <Typography variant="h3">Terms</Typography>
        <Container>
          <Terms />
        </Container>
      </StyledDrawer>
    </>
  );
};

export default Legal;
