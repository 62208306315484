import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import type {
  CurrentUserQuery,
  CurrentUserQueryVariables,
} from 'generated-types';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { FullStoryAPI } from 'react-fullstory';

type AuthContext = {
  isLoading: boolean;
  user: CurrentUserQuery['me'];
  login: (username: string, password: string) => void;
  logout: () => void;
};

const noop = () => {};

export const Auth = createContext<AuthContext>({
  isLoading: true,
  user: null,
  login: noop,
  logout: noop,
});

const CURRENT_USER = gql`
  query CurrentUserQuery {
    me {
      id
      firstName
      lastName
      displayName
      email
      roles
      entitlements
      impersonatedBy {
        id
        firstName
        lastName
        displayName
        email
        roles
      }
    }
  }
`;

const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      id
    }
  }
`;

const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

function setIdentity(user: CurrentUserQuery['me']) {
  if (user && user.impersonatedBy) {
    const { impersonatedBy } = user;

    const impersonatorDisplayName =
      impersonatedBy.displayName ||
      `${impersonatedBy.firstName} ${impersonatedBy.lastName}`;

    const userDisplayName =
      user.displayName || `${user.firstName} ${user.lastName}`;

    // https://help.fullstory.com/hc/en-us/articles/360020828113-FS-identify-Identifying-users
    FullStoryAPI('identify', `${impersonatedBy.id}::${user.id}`, {
      displayName: `${impersonatorDisplayName} as ${userDisplayName}`,
      email: `${impersonatedBy.email}::${user.email}`,
    });

    return;
  }

  if (user) {
    // https://help.fullstory.com/hc/en-us/articles/360020828113-FS-identify-Identifying-users
    FullStoryAPI('identify', user.id, {
      displayName: user.displayName || `${user.firstName} ${user.lastName}`,
      email: user.email,
    });

    return;
  }

  FullStoryAPI('identify', false);
}

const Provider: React.FC = (props) => {
  // TODO handle error when checking if the user is logged in
  // assuming that the user is not logged in, doesn't seem to be a good solution
  const { loading, data } = useQuery<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >(CURRENT_USER, { fetchPolicy: 'network-only' });
  const [loginUser] = useMutation(LOGIN, {
    refetchQueries: [{ query: CURRENT_USER }],
    awaitRefetchQueries: true,
  });
  const [logoutUser] = useMutation(LOGOUT);
  const client = useApolloClient();

  const login = useCallback(
    async (username?: string, password?: string) => {
      await loginUser({
        variables: { username, password },
      });
    },
    [loginUser],
  );

  const logout = useCallback(async () => {
    await logoutUser();

    // the provider should be at the top level of the App, so after a
    // successful logout we update the user (optimistic update of our context
    // state).

    // To be safe, we reset the store which apollo triggers a refetch of all
    // active queries in the system (and that includes the `me` query), and
    // thus if the user for some reason is not logged out yet (`me` query
    // return the user data from the session, then the context will update
    // again to the data in the `useEffect` code below.
    // https://www.apollographql.com/docs/react/networking/authentication/#reset-store-on-logout

    setContext((prevState) => ({
      ...prevState,
      user: null,
    }));

    await client.resetStore();
  }, [logoutUser, client]);

  const [context, setContext] = useState<AuthContext>({
    isLoading: loading,
    user: data?.me ?? null,
    login,
    logout,
  });

  useEffect(() => {
    if (!loading && data) {
      // only set identity if we aren't loading to avoid resetting identity
      setIdentity(data.me);
    }

    setContext((prevState) => ({
      ...prevState,
      isLoading: loading,
      user: data?.me ?? null,
    }));
  }, [loading, data]);

  return <Auth.Provider value={context}>{props.children}</Auth.Provider>;
};

export default Provider;
