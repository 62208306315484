import { gql } from '@apollo/client';
import { OrderLabelFragment } from 'components/Order/Label/fragments';

const CommonOrderUserOrderListSliderItemShipmentFragment = gql`
  fragment CommonOrderUserOrderListSliderItemShipmentFragment on OrderUser {
    id
    displayName
  }
`;

export const OrderListSliderItemShipmentFragment = gql`
  fragment OrderListSliderItemShipmentFragment on ShipmentOrder {
    id
    deliveredAt
    paid

    requester {
      ...CommonOrderUserOrderListSliderItemShipmentFragment
    }
    transporter {
      ...CommonOrderUserOrderListSliderItemShipmentFragment
    }

    offer {
      id
      stage

      price {
        amount
        currency
      }

      listing {
        id
        product {
          id
          name
          type
          image {
            url
          }
        }
        quantity {
          amount
          unit
        }
      }
    }
    ...OrderLabelFragment
  }
  ${CommonOrderUserOrderListSliderItemShipmentFragment}
  ${OrderLabelFragment}
`;
