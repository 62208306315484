import { gql, useQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import ListSlider from 'components/ListSlider';
import ListSliderItem from 'components/ListSlider/Item';
import Loading from 'components/Loading';
import OrderListSliderItem from 'components/Order/ListSliderItem';
import { OrderListSliderItemFragment } from 'components/Order/ListSliderItem/fragments';
import type { OrdersQuery, OrdersQueryVariables } from 'generated-types';
import React, { useCallback, useState } from 'react';

const ORDERS = gql`
  query OrdersQuery($after: String) {
    orders(first: 10, after: $after, query: { isArchived: false }) {
      nodes {
        id
        ...OrderListSliderItemFragment
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${OrderListSliderItemFragment}
`;

const OrdersContainer: React.FC = () => {
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const { loading, error, data, fetchMore } = useQuery<
    OrdersQuery,
    OrdersQueryVariables
  >(ORDERS);

  const loadMoreHandler = useCallback(() => {
    if (!data) {
      throw new Error('Trying to load more without current data');
    }
    if (fetchMoreLoading) {
      throw new Error(
        'Trying to load next page before current page finishes loading',
      );
    }

    setFetchMoreLoading(true);

    fetchMore({
      variables: {
        after: data.orders.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setFetchMoreLoading(false);

        if (!fetchMoreResult) return prev;

        return {
          orders: {
            __typename: prev.orders.__typename,
            nodes: [...prev.orders.nodes, ...fetchMoreResult.orders.nodes],
            totalCount: fetchMoreResult.orders.totalCount,
            pageInfo: fetchMoreResult.orders.pageInfo,
          },
        };
      },
    });
  }, [data, fetchMoreLoading, fetchMore, setFetchMoreLoading]);

  if (loading && !fetchMoreLoading) return <Loading />;

  if (error || !data) {
    return (
      <Typography color="error">
        Unable to load orders from the server. Please try again
      </Typography>
    );
  }

  if (data.orders.totalCount === 0) {
    // No orders
    return null;
  }

  return (
    <ListSlider
      title={`Orders (${data.orders.totalCount})`}
      onLoadMore={loadMoreHandler}
      hasMore={data.orders.pageInfo.hasNextPage}
    >
      {data.orders.nodes.map((item: any) => (
        <ListSliderItem key={item.id}>
          <OrderListSliderItem order={item} />
        </ListSliderItem>
      ))}
    </ListSlider>
  );
};

export default OrdersContainer;
