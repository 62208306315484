export default function cleanUrl(
  to: string,
  searchParams?: URLSearchParams,
  hash?: string,
) {
  const searchParamsString = searchParams?.toString();
  const withSearchParams = searchParamsString ? `?${searchParamsString}` : '';

  const withHash = hash ? `#${hash}` : '';

  return `${to}${withSearchParams}${withHash}`;
}
