import type { ReactElement } from 'react';

export enum HelpType {
  ACCOUNT = 'ACCOUNT',
  DWOLLA = 'DWOLLA',
  GENERAL = 'GENERAL',
  LISTING = 'LISTING',
  LOGIN = 'LOGIN',
  OFFER = 'OFFER',
  ORDER = 'ORDER',
  RATINGS = 'RATINGS',
  TOPICS = 'TOPICS',
}

export type HelpSection<TSectionEnum> = {
  section: TSectionEnum;
  heading: string;
  body: ReactElement;
};
