import { Button, Divider, Menu, MenuItem } from '@material-ui/core';
import { Link, navigate, useLocation } from '@reach/router';
import { Auth } from 'contexts/Auth';
import { UserEntitlement } from 'generated-types';
import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import cleanUrl from 'utils/clean-url';

const StyledMenu = styled(Menu)`
  & .paper {
    min-width: 178px;
  }
`;

const AccountLinks: React.FC = () => {
  const { user, isLoading, logout } = useContext(Auth);
  const [accountAnchorEl, setAccountAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const { search } = useLocation();

  const handleOpenAccount = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAccountAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAccountAnchorEl(null);
  }, []);

  if (isLoading || !user) {
    const loginSearchParams = new URLSearchParams(search);
    loginSearchParams.set('login', '');

    return (
      <Button
        component={Link}
        to={cleanUrl('', loginSearchParams)}
        color="primary"
      >
        Account
      </Button>
    );
  }

  return (
    <>
      <Button
        color="primary"
        aria-controls="account-menu"
        aria-haspopup="true"
        onClick={handleOpenAccount}
      >
        Account
      </Button>
      <StyledMenu
        id="account-menu"
        anchorEl={accountAnchorEl}
        keepMounted={true}
        open={Boolean(accountAnchorEl)}
        onClose={handleClose}
        elevation={2}
        classes={{ paper: 'paper' }}
      >
        {user.entitlements.includes(UserEntitlement.CAN_MANAGE_USERS) && [
          <MenuItem
            key="admin-users"
            onClick={() => {
              navigate('/admin/users');
            }}
          >
            Manage Users
          </MenuItem>,
          <Divider key="admin-menu-divider" />,
        ]}

        <MenuItem
          onClick={() => {
            navigate('/orders');
          }}
        >
          My Orders
        </MenuItem>

        <MenuItem
          onClick={() => {
            const { search } = window.location;
            const searchParams = new URLSearchParams(search);
            searchParams.set('account-details', '');

            navigate(cleanUrl('', searchParams));
          }}
        >
          Details
        </MenuItem>
        <MenuItem
          onClick={() => {
            logout();
            handleClose();
          }}
        >
          Logout
        </MenuItem>
      </StyledMenu>
    </>
  );
};

export default AccountLinks;
