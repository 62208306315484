import type { CutsAndSizes } from 'generated-types';

type CutsAndSizesLabels = {
  [cutOrSize in CutsAndSizes]: string;
};

export const cutsAndSizesLabels: CutsAndSizesLabels = Object.freeze({
  ROUND: 'Round',
  SIRLOIN: 'Sirloin',
  BRISKET: 'Brisket',
  FORE_SHANK: 'Fore Shank',
  CHUCK: 'Chuck',
  SHORT_LOIN: 'Short Loin',
  RIB: 'Rib',
  GROUND: 'Ground',
  CUBES: 'Cubes',
  TONGUE: 'Tongue',
  LIVER: 'Liver',
  WHOLE: 'Whole',
  BREASTS: 'Breasts',
  WINGS: 'Wings',
  LEGS: 'Legs',
  FILLET: 'Fillet',
  STEAK: 'Steak',
  HEADED_GUTTED: 'Headed & Gutted',
  EXTRA_COLOSSAL: 'Extra Colossal (U/10)',
  SUPER_COLOSSAL: 'Super Colossal (U/12)',
  COLOSSAL: 'Colossal (U/15)',
  EXTRA_JUMBO: 'Extra Jumbo (16/20)',
  JUMBO: 'Jumbo (21/25)',
  EXTRA_LARGE: 'Extra Large (26/30)',
  LARGE: 'Large (31/40)',
  MEDIUM: 'Medium (41/50)',
  SMALL: 'Small (51/60)',
  TINY: 'Tiny (61/70)',
  SALAD_STYLE: 'Salad Style (71+)',
});
