import { gql } from '@apollo/client';
import { ListingPosterFragment } from 'components/Listing/Poster/fragments';

export const ListingListSliderItemFragment = gql`
  fragment ListingListSliderItem on Listing {
    __typename
    id

    tags {
      id
      name
    }

    product {
      id
      name
      type
      image {
        url
      }
    }

    ... on ForSaleListing {
      price {
        amount
        currency
        unit
      }
      quantity {
        amount
        unit
      }
      cutOrSize
    }

    ... on WantedListing {
      price {
        amount
        currency
        unit
      }
      quantity {
        amount
        unit
      }
      cutOrSize
    }

    ... on TransportationListing {
      quantity {
        amount
        unit
      }
      pickupAddress {
        id
        city
        state
      }
      deliveryAddress {
        id
        city
        state
      }
      estimatedTravelDistance
    }

    ... on WholeSaleListing {
      wholesalePrice {
        amount
        currency
        unit
        isHidden
      }
      cutOrSize
    }

    ...ListingPoster
  }
  ${ListingPosterFragment}
`;
