import { gql, useQuery } from '@apollo/client';
import { FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { useLocation } from '@reach/router';
import Loading from 'components/Loading';
import { SearchParametersContext } from 'contexts/SearchParameters';
import type { TagsFacetQuery, TagsFacetQueryVariables } from 'generated-types';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Facet from '../Facet';

const PARAM_KEY = 'includedTags';

const StyledSelect = styled(Select)`
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  position: absolute;
`;

const TAGS_FACET = gql`
  query TagsFacetQuery {
    # TODO work on better solution once we have more tags
    tags(first: 20) {
      nodes {
        id
        name
      }
    }
  }
`;

export default function TagsFacet(): JSX.Element {
  const { loading, error, data } = useQuery<
    TagsFacetQuery,
    TagsFacetQueryVariables
  >(TAGS_FACET);
  const [open, setOpen] = useState(false);
  const { parameters, setParameters } = useContext(SearchParametersContext);
  const { pathname } = useLocation();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    // TODO @fguerra fix this once we upgrade MUI to support types here
    if (!Array.isArray(event.target.value)) {
      throw new Error('FacetTags handleChange value not an Array');
    }

    const includeTagIds = event.target.value as string[];

    setParameters({
      navigate: pathname,
      ...parameters,
      filters: {
        ...parameters.filters,
        includeTagIds: includeTagIds.length ? includeTagIds : undefined,
      },
    });
  };

  const selectedTags = data?.tags.nodes.filter((tag) =>
    parameters.filters?.includeTagIds?.includes(tag.id),
  );

  let selectedTagsLabel = '';
  if (selectedTags?.length) {
    selectedTagsLabel = selectedTags[0].name;

    if (selectedTags.length > 1) {
      selectedTagsLabel += ` +${selectedTags.length - 1}`;
    }
  }

  return (
    <Facet active={Boolean(selectedTags?.length)}>
      <FormControl>
        <label
          onClick={() => {
            setOpen(!open);
          }}
        >
          {selectedTags?.length ? selectedTagsLabel : 'Tags'}
        </label>
        <StyledSelect
          labelId={PARAM_KEY}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          onOpen={() => {
            setOpen(true);
          }}
          value={parameters.filters?.includeTagIds ?? []}
          onChange={handleChange}
          multiple={true}
        >
          {loading && <Loading />}
          {(error || !data) && (
            <Typography color="error">
              Unable to load list of tags. Please try again
            </Typography>
          )}
          {data?.tags.nodes.map((tag) => (
            <MenuItem key={tag.id} value={tag.id}>
              {tag.name}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </Facet>
  );
}
