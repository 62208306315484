import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
} from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import { useLocation } from '@reach/router';
import Facets from 'components/Facets';
import { Auth } from 'contexts/Auth';
import { SearchParametersContext } from 'contexts/SearchParameters';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import useTimer from 'utils/useTimer';

const StyledSearchBar = styled.nav`
  & form {
    position: relative;
    height: 2.625rem;
  }
  ${(p) => p.theme.breakpoints.down('xl')} {
    & form {
      width: 26rem;
    }
  }
`;

const StyledFormControl = styled(FormControl)`
  ${(p) => p.theme.typography.fontFamily}
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  border-radius: 5.25em;
  box-shadow: inset 0 0 0 0.125rem ${(p) => p.theme.palette.secondary.light};
  border: none;
  padding: 0 0.813rem 0 1rem;
  outline: none;
  transition: all 0.1s;
  &:focus {
    box-shadow: inset 0 0 0 0.125rem ${(p) => p.theme.palette.primary.dark} !important;
  }
`;

const StyledInput = styled(Input)`
  background-color: transparent;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  & .MuiFormControl-root {
    margin-left: 0.25rem;
  }
  &:before,
  &:after {
    display: none;
  }
`;

const SearchBar: React.FC = (props) => {
  const TIMEOUT = 3000;
  const [text, setText] = useState('');
  const [showClose, setShowClose] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const { parameters, setParameters } = useContext(SearchParametersContext);
  const { user, isLoading } = useContext(Auth);
  const [, setTimer] = useTimer(() => {
    setShowSearch(true);
  }, TIMEOUT);
  const { pathname } = useLocation();

  // Watch filters changes, if set show close X
  useEffect(() => {
    setShowClose(!!parameters.filters);
  }, [parameters.filters]);

  // handle logout
  useEffect(() => {
    if (
      !user &&
      !isLoading &&
      !parameters.filters?.proximity &&
      !parameters.filters?.availabilityRange &&
      !parameters.q
    ) {
      setShowClose(false);
    }
  }, [user, isLoading, parameters.filters, parameters.q]);

  useEffect(() => {
    setText(parameters.q || '');
    setShowClose(!!parameters.q);
  }, [parameters.q]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setText(e.target.value);
      setShowClose(e.target.value.length > 0);
      setTimer(e.target.value.length > 0);
      setShowSearch(e.target.value.length > 0 && showSearch);
    },
    [setShowClose, setText, setTimer, showSearch],
  );

  const submitText = useCallback(() => {
    if (text.length > 0) {
      setParameters({
        navigate: pathname,
        q: text,
        filters: {
          ...parameters.filters,
        },
      });
    } else {
      setParameters({
        q: undefined,
        filters: {
          ...parameters.filters,
        },
      });
      setTimer(false);
      setShowSearch(false);
    }
  }, [setParameters, text, setTimer, pathname, parameters.filters]);

  const handleSubmit = useCallback(
    (event: React.SyntheticEvent | null) => {
      if (event) event.preventDefault();
      submitText();
    },
    [submitText],
  );

  // Check for ENTER key to trigger submit
  const handleCheckSubmit = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.keyCode === 13) {
        handleSubmit(null);
      }
    },
    [handleSubmit],
  );

  const resetField = useCallback(() => {
    setText('');
    setShowClose(false);
    setParameters({
      navigate: pathname, // force results page
      ...parameters,
      q: undefined,
      filters: undefined,
    });
    setShowSearch(false);
    setTimer(false);
  }, [
    setShowClose,
    setText,
    parameters,
    setParameters,
    setTimer,
    setShowSearch,
    pathname,
  ]);

  return (
    <StyledSearchBar {...props}>
      <form method="POST" onSubmit={handleSubmit}>
        <StyledFormControl fullWidth={true}>
          <StyledInput
            name="q"
            type="text"
            placeholder="Search marketplace for..."
            value={text}
            onChange={handleChange}
            onKeyDown={handleCheckSubmit}
            endAdornment={
              <InputAdornment position="end">
                {showSearch && (
                  <IconButton aria-label="search" onClick={handleSubmit}>
                    <Search color="secondary" />
                  </IconButton>
                )}
                {showClose && (
                  <IconButton aria-label="close" onClick={resetField}>
                    <Close color="secondary" />
                  </IconButton>
                )}
                <Facets />
              </InputAdornment>
            }
          />
        </StyledFormControl>
      </form>
    </StyledSearchBar>
  );
};

export default SearchBar;
