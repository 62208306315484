import type { OrderListSliderItemFragment } from 'generated-types';
import React from 'react';
import OrderListSliderItemFulfillment from './Fulfillment';
import OrderListSliderItemShipment from './Shipment';
import OrderListSliderItemWholeSale from './WholeSale';

type Props = {
  order: OrderListSliderItemFragment;
};

const OrderListSliderItem: React.FC<Props> = ({ order }) => {
  if (order.__typename === 'FulfillmentOrder') {
    return <OrderListSliderItemFulfillment order={order} />;
  } else if (order.__typename === 'ShipmentOrder') {
    return <OrderListSliderItemShipment order={order} />;
  } else if (order.__typename === 'WholeSaleOrder') {
    return <OrderListSliderItemWholeSale order={order} />;
  }

  throw new Error('Unknown Order type');
};

export default OrderListSliderItem;
