import { red } from '@material-ui/core/colors';
import { Link } from '@reach/router';
import { Auth } from 'contexts/Auth';
import React, { useContext } from 'react';
import styled from 'styled-components';
import logo from './logo.svg';
import Nav from './Nav';
import SearchBar from './SearchBar';

export const height = '68px';

type StyledHeaderProps = {
  isImpersonating: boolean;
};

const StyledHeader = styled.header<StyledHeaderProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: ${(p) =>
    p.isImpersonating ? `linear-gradient(${red[800]}, #fff)` : '#fff'};
  height: ${height};

  display: flex;
  justify-content: center;
  align-items: center;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: 0 20px;
    justify-content: space-between;
  }
`;

const Left = styled.div`
  display: flex;
`;

const StyledSearchBar = styled(SearchBar)`
  margin: 0 20px;

  /* hide search on small screens */
  ${(p) => p.theme.breakpoints.down('xs')} {
    display: none;
  }
`;

const StyledNav = styled(Nav)`
  /* hide menu on small screens */
  ${(p) => p.theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const Header: React.FC = () => {
  const { user } = useContext(Auth);

  return (
    <StyledHeader isImpersonating={!!user?.impersonatedBy}>
      <Left>
        <Link to="/" aria-label="Home">
          <img src={logo} alt="" className="logo" />
        </Link>
        <StyledSearchBar />
      </Left>
      <StyledNav />
    </StyledHeader>
  );
};

export default Header;
