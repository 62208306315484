import { gql } from '@apollo/client';
import { OrderLabelFragment } from 'components/Order/Label/fragments';

const CommonOrderUserOrderListSliderItemWholeSaleFragment = gql`
  fragment CommonOrderUserOrderListSliderItemWholeSaleFragment on OrderUser {
    id
    displayName
  }
`;

export const OrderListSliderItemWholeSaleFragment = gql`
  fragment OrderListSliderItemWholeSaleFragment on WholeSaleOrder {
    id
    deliveredAt
    paid

    buyer {
      ...CommonOrderUserOrderListSliderItemWholeSaleFragment
    }
    seller {
      ...CommonOrderUserOrderListSliderItemWholeSaleFragment
    }

    wholesaleTotalPrice {
      amount
      currency
    }

    items {
      id
      listing {
        ... on WholeSaleListing {
          id

          product {
            id
            name
            type
            image {
              url
            }
          }

          wholesalePrice {
            amount
            currency
            unit
          }
        }
      }
    }
    ...OrderLabelFragment
  }
  ${CommonOrderUserOrderListSliderItemWholeSaleFragment}
  ${OrderLabelFragment}
`;
