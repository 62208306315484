import { Link, useLocation } from '@reach/router';
import ListSliderTile from 'components/ListSlider/Tile';
import React from 'react';
import styled from 'styled-components';
import cleanUrl from 'utils/clean-url';

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 13px;
  color: white;
  letter-spacing: -0.4px;
  text-decoration: none;
  border-radius: 6px;
  line-height: 0.5em;
`;

const AddListingCard: React.FC = () => {
  const { search } = useLocation();

  const addListingSearchParams = new URLSearchParams(search);
  addListingSearchParams.set('add-listing', '');

  return (
    <ListSliderTile backgroundImage="">
      <StyledLink to={cleanUrl('', addListingSearchParams)}>
        Add Listing
      </StyledLink>
    </ListSliderTile>
  );
};

export default AddListingCard;
