import FormControl from '@material-ui/core/FormControl';
import type { DateRange } from '@material-ui/pickers';
import DateRangeField from 'components/Shared/Fields/DateRange';
import { SearchParametersContext } from 'contexts/SearchParameters';
import { parseISO } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import Facet from '../Facet';

type DateFacetProps = {};

const AvailabilityEstimatedFacet: React.FC<DateFacetProps> = () => {
  const DEFAULT_LABEL = 'Date';

  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState(DEFAULT_LABEL);
  const { parameters, setParameters } = useContext(SearchParametersContext);

  const [dateRange, setDateRange] = useState<DateRange>([null, null]);

  useEffect(() => {
    const dates = parameters.filters?.availabilityRange;

    // dates not set in parameters, reset local variable
    if (!dates || (!dates.start && !dates.end)) {
      setDateRange([null, null]);
    }

    // dates set in parameters
    if (dates) {
      setActive(true);
      setLabel(`${dates.start} to ${dates.end}`);
      // dates not updated from parameters to local variables
      if (!dateRange[0] || !dateRange[1]) {
        setDateRange([parseISO(dates.start), parseISO(dates.end)]);
      }
    } else {
      setActive(false);
      setLabel(DEFAULT_LABEL);
    }
    // including dateRange leads to loop
  }, [parameters.filters]); // eslint-disable-line

  useEffect(() => {
    if (!dateRange || !dateRange[0] || !dateRange[1]) return;

    const start = dateRange[0]?.toISOString().slice(0, 10);
    const end = dateRange[1]?.toISOString().slice(0, 10);

    setParameters({
      navigate: '/',
      ...parameters,
      filters: {
        ...parameters.filters,
        availabilityRange: {
          start: start || '',
          end: end || '',
        },
      },
    });
    // including parameters leads to loop
  }, [dateRange]); // eslint-disable-line

  return (
    <Facet active={active}>
      <FormControl>
        <label
          onClick={() => {
            setOpen(!open);
          }}
        >
          {label}
        </label>

        <DateRangeField
          value={dateRange}
          open={open}
          calendars={1}
          onClose={() => {
            setOpen(false);
          }}
          onOpen={() => {
            setOpen(true);
          }}
          onChange={(range) => {
            if (range) setDateRange(range);
          }}
          renderInputs={false}
        />
      </FormControl>
    </Facet>
  );
};

export default AvailabilityEstimatedFacet;
