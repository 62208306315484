import React from 'react';
import styled from 'styled-components';

const StyledItem = styled.li`
  display: flex;
  flex-basis: 10%;
  padding: ${(p) => p.theme.spacing(1, 1)};
`;

type Props = {
  children: React.ReactNode;
};

export default function CollectionGridItem(props: Props): JSX.Element {
  return <StyledItem {...props} />;
}
