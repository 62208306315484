import { FormControl, MenuItem, Select } from '@material-ui/core';
import { useLocation } from '@reach/router';
import { Auth } from 'contexts/Auth';
import { SearchParametersContext } from 'contexts/SearchParameters';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Facet from '../Facet';

const PARAM_KEY = 'proximity';
const DEFAULT_LABEL = 'Distance';

const StyledSelect = styled(Select)`
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  position: absolute;
`;

export default function DistanceFacet(): JSX.Element {
  const [open, setOpen] = useState(false);
  const { user } = useContext(Auth);
  const { parameters, setParameters } = useContext(SearchParametersContext);
  const { pathname } = useLocation();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const proximity = event.target.value as number;

    setParameters({
      navigate: pathname,
      ...parameters,
      filters: {
        ...parameters.filters,
        proximity: proximity ? proximity : undefined,
      },
    });
  };

  const disabled = !user;
  const active = Boolean(!disabled && parameters.filters?.proximity);

  return (
    <Facet active={active} disabled={disabled}>
      <FormControl
        disabled={disabled}
        title={disabled ? `You must log in to see distance` : ''}
      >
        <label
          onClick={() => {
            if (!disabled) setOpen(!open);
          }}
        >
          {active ? `${parameters.filters?.proximity} miles` : DEFAULT_LABEL}
        </label>
        <StyledSelect
          labelId={PARAM_KEY}
          value={parameters.filters?.proximity ?? 0}
          onChange={handleChange}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          onOpen={() => {
            setOpen(true);
          }}
        >
          <MenuItem value={0}>All Distances</MenuItem>
          <MenuItem value={25}>25 Miles</MenuItem>
          <MenuItem value={50}>50 Miles</MenuItem>
          <MenuItem value={100}>100 Miles</MenuItem>
          <MenuItem value={500}>500 Miles</MenuItem>
          <MenuItem value={1000}>1000 Miles</MenuItem>
        </StyledSelect>
      </FormControl>
    </Facet>
  );
}
