import GA4React from 'ga-4-react';

export default async function analytics() {
  if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
    const ga4react = new GA4React(process.env.REACT_APP_GA_MEASUREMENT_ID);

    try {
      await ga4react.initialize();
    } catch (error) {
      // AdBlocker activated
    }
  }
}
