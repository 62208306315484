import { Chip } from '@material-ui/core';
import { Link } from '@reach/router';
import ListSliderTile from 'components/ListSlider/Tile';
import OfferListCounterParty from 'components/Offer/ListCounterParty';
import type { OfferListSliderItemFragment } from 'generated-types';
import React from 'react';
import styled from 'styled-components';
import cleanUrl from 'utils/clean-url';
import { cutsAndSizesLabels } from 'utils/cutsAndSizes';
import { getTransportImage } from 'utils/getTransportationImage';
import {
  currencyFormatter,
  currencyPerUnitFormatter,
  unitFormatter,
} from 'utils/units';

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  color: white;
  padding: 0.5rem;
  text-decoration: none;
`;

const StyledOfferParticipant = styled.span`
  ${(p) => p.theme.typography.subtitle2}
  line-height: 1;
  margin: 0;
`;

const StyledListItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
`;

const StyledListItemTitle = styled.h2`
  ${(p) => p.theme.typography.h3}
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  align-self: flex-start;
`;

const StyledListItemPrice = styled.p`
  ${(p) => p.theme.typography.subtitle1}
  margin: 0;
`;

const StyledListItemCutOrSize = StyledListItemPrice;

const StyledListItemProductType = styled.h3`
  ${(p) => p.theme.typography.subtitle2}
  line-height: 1;
  margin: 0;
  white-space: nowrap;
  width: 100%;
  align-self: flex-start;
`;

const StyledLinkFooter = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  offer: OfferListSliderItemFragment;
};

const OfferListSliderItem: React.FC<Props> = (props) => {
  const { offer } = props;
  const { listing } = offer;
  const { product } = listing;

  const { search, hash } = window.location;
  const searchParams = new URLSearchParams(search);
  searchParams.set('offer', offer.id);

  let backgroundImage;

  if (listing.__typename === 'TransportationListing') {
    backgroundImage = getTransportImage(listing.id);
  } else {
    backgroundImage = product.image ? product.image.url : 'none';
  }

  return (
    <ListSliderTile backgroundImage={backgroundImage}>
      <StyledLink to={cleanUrl('', searchParams, hash)} state={offer.stage}>
        <StyledOfferParticipant>
          <OfferListCounterParty offer={offer} />
        </StyledOfferParticipant>

        <StyledListItemDescription>
          <StyledListItemProductType>{product.type}</StyledListItemProductType>
          <StyledListItemTitle>{product.name}</StyledListItemTitle>
          {(listing.__typename === 'ForSaleListing' ||
            listing.__typename === 'WantedListing') &&
            listing.cutOrSize && (
              <StyledListItemCutOrSize>
                {cutsAndSizesLabels[listing.cutOrSize]}
              </StyledListItemCutOrSize>
            )}
          {offer.__typename === 'PerUnitOffer' && (
            <StyledListItemPrice>
              {unitFormatter(listing.quantity.amount, listing.quantity.unit)} at{' '}
              {currencyPerUnitFormatter(
                offer.price.amount,
                offer.price.currency,
                offer.price.unit,
              )}
            </StyledListItemPrice>
          )}

          {offer.__typename === 'FullPriceOffer' && (
            <StyledListItemPrice>
              {unitFormatter(listing.quantity.amount, listing.quantity.unit)}{' '}
              for {currencyFormatter(offer.price.amount, offer.price.currency)}
            </StyledListItemPrice>
          )}
        </StyledListItemDescription>
        <StyledLinkFooter>
          {!!offer.previousOffer && (
            <Chip
              label="Countered"
              color="primary"
              style={{ backgroundColor: '#013e7c' }}
              size="small"
            />
          )}
        </StyledLinkFooter>
      </StyledLink>
    </ListSliderTile>
  );
};

export default OfferListSliderItem;
