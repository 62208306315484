import { Drawer, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { MailOutline, Map, PhoneInTalk } from '@material-ui/icons';
import DownChevron from 'components/Shared/DownChevron';
import React from 'react';
import styled from 'styled-components';

const ContactMethods = styled.div`
  display: flex;
  flex-direction: row;
`;

const Method = styled.div`
  flex: 1 1;
  border-left: 1px solid #eee;
  text-align: center;
  align-items: center;

  &:first-of-type {
    border-left: none;
  }
`;

const StyledDrawer = styled(Drawer)`
  & .paper {
    margin: 0;
    border-radius: 16px 16px 0 0;
    height: 38%;
    max-height: 466px;
    min-height: 288px;
    & > .MuiIconButton-root {
      position: absolute;
      top: 0;
      right: 0;
      height: 68px;
      width: 68px;
      padding: 0;
    }
    & h3 {
      line-height: 68px;
      height: 68px;
      text-align: center;
    }
    & p {
      margin: 0 26px;
    }
  }
`;

const Contact: React.FC = () => {
  const [state, setState] = React.useState({
    isOpen: false,
  });
  return (
    <>
      <Button color="primary" onClick={() => setState({ isOpen: true })}>
        Contact
      </Button>
      <StyledDrawer
        classes={{ paper: 'paper' }}
        anchor="bottom"
        open={state.isOpen}
        onClose={() => {
          setState({ isOpen: false });
        }}
      >
        <IconButton
          color="secondary"
          aria-label="close"
          onClick={() => setState({ isOpen: false })}
        >
          <DownChevron />
        </IconButton>
        <Typography variant="h3">Contact</Typography>
        <ContactMethods>
          <Method>
            <PhoneInTalk fontSize="large" />
            <p>
              <a href="tel:+18555661389">855-566-1389</a>
            </p>
          </Method>
          <Method>
            <MailOutline fontSize="large" />
            <p>
              Email us at{' '}
              <a href="mailto:info@supplycaravan.com">info@supplycaravan.com</a>
            </p>
          </Method>
          <Method>
            <Map fontSize="large" />
            <p>We are located at:</p>
            <address>
              116 Middle Street
              <br />
              Portsmouth, NH 03801
            </address>
          </Method>
        </ContactMethods>
      </StyledDrawer>
    </>
  );
};

export default Contact;
