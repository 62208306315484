const RANDOM_TRANSPORTATION_IMAGES = [
  'https://images.unsplash.com/photo-1541969487406-1f1adf3884ab',
  'https://images.unsplash.com/photo-1501700493788-fa1a4fc9fe62',
  'https://images.unsplash.com/photo-1591768793355-74d04bb6608f',
  'https://images.unsplash.com/photo-1592805144716-feeccccef5ac',
  'https://images.unsplash.com/photo-1548695607-9c73430ba065',
  'https://images.unsplash.com/photo-1601835821923-002422cf0286',
  'https://images.unsplash.com/photo-1601631547344-1b6948ea55c2',
  'https://images.unsplash.com/photo-1543060133-437ca139412d',
];

export function getTransportImage(listingId: string) {
  return RANDOM_TRANSPORTATION_IMAGES[
    Math.floor(parseInt(listingId[listingId.length - 1], 36) % 7)
  ];
}
