import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import { HelpType } from 'components/Help/constants';
import Loading from 'components/Loading';
import { Auth } from 'contexts/Auth';
import { UserRole } from 'generated-types';
import React, { Suspense, useCallback, useContext } from 'react';
import cleanUrl from 'utils/clean-url';
import { getHelpTypeFromAnchor } from 'utils/helpUtils';

const About = React.lazy(() => import('components/About'));
const Drawer = React.lazy(() => import('components/Drawer'));
const AdminChatContainer = React.lazy(
  () => import('components/Users/AdminChat'),
);
const AccountDetailsContainer = React.lazy(
  () => import('containers/Account/Details'),
);
const AddListingContainer = React.lazy(() => import('containers/AddListing'));
const AdminNotificationChatDrawer = React.lazy(
  () => import('containers/AdminNotificationChatDrawer'),
);
const AdminNotificationsDrawerContainerWrapper = React.lazy(
  () => import('containers/AdminNotificationsDrawer'),
);
const CartContainer = React.lazy(() => import('containers/Cart'));
const CartChatDrawerContainer = React.lazy(
  () => import('containers/CartChatDrawer'),
);
const DirectChatDrawerContainer = React.lazy(
  () => import('containers/DirectChatDrawer'),
);
const DwollaAccountContainer = React.lazy(
  () => import('containers/Dwolla/Account'),
);
const DwollaBankContainer = React.lazy(() => import('containers/Dwolla/Bank'));
const DwollaDocumentContainer = React.lazy(
  () => import('containers/Dwolla/Document'),
);
const DwollaFundingsContainer = React.lazy(
  () => import('containers/Dwolla/Fundings'),
);
const EditAvatarDrawer = React.lazy(
  () => import('containers/EditAvatarDrawer'),
);
const ForgotPasswordContainer = React.lazy(
  () => import('containers/ForgotPassword'),
);
const HelpContainer = React.lazy(() => import('containers/Help'));
const ListingContainer = React.lazy(() => import('containers/Listing'));
const ListingUserDetailsContainer = React.lazy(
  () => import('containers/Listing/UserDetails'),
);
const ListingChatDrawer = React.lazy(
  () => import('containers/ListingChatDrawer'),
);
const LoginContainer = React.lazy(() => import('containers/Login'));
const MerchantChatDrawerContainer = React.lazy(
  () => import('containers/MerchantChatDrawer'),
);
const MerchantChatListDrawerContainer = React.lazy(
  () => import('containers/MerchantChatListDrawer'),
);
const NotificationsDrawerContainerWrapper = React.lazy(
  () => import('containers/NotificationsDrawer'),
);
const OfferContainer = React.lazy(() => import('containers/Offer'));
const OfferChatDrawerContainer = React.lazy(
  () => import('containers/OfferChatDrawer'),
);
const OrderContainer = React.lazy(() => import('containers/Order'));
const OrderChatDrawerContainer = React.lazy(
  () => import('containers/OrderChatDrawer'),
);
const RatingsCartContainer = React.lazy(
  () => import('containers/Ratings/Cart'),
);
const RatingsListingContainer = React.lazy(
  () => import('containers/Ratings/Listing'),
);
const RatingsOfferContainer = React.lazy(
  () => import('containers/Ratings/Offer'),
);
const RatingsOrderContainer = React.lazy(
  () => import('containers/Ratings/Order'),
);
const ResetPasswordContainer = React.lazy(
  () => import('containers/ResetPassword'),
);
const SignupContainer = React.lazy(() => import('containers/Signup'));

export default function Drawers(_: RouteComponentProps): JSX.Element {
  const { user } = useContext(Auth);
  const { pathname, search, hash } = useLocation();

  const searchParams = new URLSearchParams(search);
  const showAbout = searchParams.has('about');
  const showLogin = searchParams.has('login');
  const showSignup = searchParams.has('signup');
  const showDwollaFundingSources = searchParams.has('dwolla-funding-sources');
  const showDwollaBank = searchParams.has('dwolla-bank');
  const showDwollaDocument = searchParams.has('dwolla-document');
  const showDwollaAccount = searchParams.has('dwolla-account');
  const showAccountDetails = searchParams.has('account-details');
  const showAddListing = searchParams.has('add-listing');
  const showNotifications = searchParams.has('notification');
  const notificationChatId = searchParams.get('notification');
  const showAdminNotifications = searchParams.has('admin-notification');
  const adminNotificationChatId = searchParams.get('admin-notification');
  const listingId = searchParams.get('listing');
  const offerId = searchParams.get('offer');
  const cartId = searchParams.get('cart');
  const orderId = searchParams.get('order');
  const showHelpTopics = searchParams.has('help-topics');
  const showForgotPassword = searchParams.has('forgot-password');
  const resetPasswordKey = searchParams.get('reset-password');

  const showSubHelp = hash.indexOf('help') > -1;
  const showChat = hash === '#chat';
  const chatUserId = searchParams.get('chatUserId');
  const showRatings = hash === '#rate';
  const showUserDetails = hash === '#userdetails';
  const showAvatarUpload = hash === '#avatar' && showAccountDetails;
  const listingChatId = searchParams.get('listingChatId');
  const listingChat = hash === '#listingChat';
  const supportChat = searchParams.has('support-chat');
  const merchantChatList = searchParams.has('merchant-chat');
  const merchantChatId = searchParams.get('merchant-chat');

  const showAdminUserChat =
    pathname === '/admin/users' &&
    chatUserId &&
    user?.roles.includes(UserRole.ADMIN);

  const merchantPageMatch = pathname.match('/merchants/(.*)');
  const merchantId = merchantPageMatch && merchantPageMatch[1];

  // we only support 1 global drawer to be opened at a time,
  // thus we can remove all query params related with drawers
  searchParams.delete('about');
  searchParams.delete('login');
  searchParams.delete('signup');
  searchParams.delete('dwolla-funding-sources');
  searchParams.delete('dwolla-bank');
  searchParams.delete('dwolla-document');
  searchParams.delete('dwolla-account');
  searchParams.delete('account-details');
  searchParams.delete('add-listing');
  searchParams.delete('listing');
  searchParams.delete('offer');
  searchParams.delete('cart');
  searchParams.delete('order');
  searchParams.delete('chatUserId');
  searchParams.delete('help-topics');
  searchParams.delete('support-chat');
  searchParams.delete('notification');
  searchParams.delete('admin-notification');
  searchParams.delete('listingChatId');
  searchParams.delete('merchant-chat');
  searchParams.delete('forgot-password');
  searchParams.delete('reset-password');

  // login is a special case because we want to remove the login params too
  // when login drawer closes
  searchParams.delete('t');
  searchParams.delete('loginReason');

  // we only support 1 global drawer to be opened at a time,
  // thus we can remove hashes here
  const to = cleanUrl(pathname, searchParams);

  const closeHandler = useCallback(() => {
    navigate(to || '/');
  }, [to]);

  const secondaryCloseHandler = useCallback(() => {
    navigate('#');
  }, []);

  /**
   * The right drawer content can only have one container, thus if multiple
   * containers are requested, the one to show and the order of which one to
   * select first matters.
   */
  let rightDrawer;
  let secondaryDrawer;

  if (showAbout) {
    rightDrawer = <About onClose={closeHandler} />;
  } else if (showSignup) {
    rightDrawer = <SignupContainer onClose={closeHandler} />;
  } else if (showLogin) {
    rightDrawer = <LoginContainer onClose={closeHandler} />;
  } else if (showDwollaFundingSources) {
    rightDrawer = <DwollaFundingsContainer onClose={closeHandler} />;
  } else if (showDwollaBank) {
    rightDrawer = <DwollaBankContainer onClose={closeHandler} />;
  } else if (showDwollaDocument) {
    rightDrawer = <DwollaDocumentContainer onClose={closeHandler} />;
  } else if (showDwollaAccount) {
    rightDrawer = <DwollaAccountContainer onClose={closeHandler} />;
  } else if (showAccountDetails) {
    rightDrawer = <AccountDetailsContainer onClose={closeHandler} />;
  } else if (showAddListing) {
    rightDrawer = <AddListingContainer onClose={closeHandler} />;
  } else if (orderId) {
    rightDrawer = <OrderContainer onClose={closeHandler} id={orderId} />;
  } else if (cartId) {
    rightDrawer = <CartContainer onClose={closeHandler} id={cartId} />;
  } else if (offerId) {
    rightDrawer = <OfferContainer onClose={closeHandler} id={offerId} />;
  } else if (listingId) {
    rightDrawer = <ListingContainer onClose={closeHandler} id={listingId} />;
  } else if (showAdminUserChat && chatUserId) {
    rightDrawer = (
      <AdminChatContainer onClose={closeHandler} userId={chatUserId} />
    );
  } else if (showHelpTopics) {
    rightDrawer = (
      <HelpContainer
        onClose={closeHandler}
        helpType={HelpType.TOPICS}
        showChat={true}
        openChat={supportChat}
      />
    );
  } else if (showNotifications) {
    rightDrawer = (
      <NotificationsDrawerContainerWrapper onClose={closeHandler} />
    );
  } else if (showAdminNotifications) {
    rightDrawer = (
      <AdminNotificationsDrawerContainerWrapper onClose={closeHandler} />
    );
  } else if (merchantChatList) {
    rightDrawer = <MerchantChatListDrawerContainer onClose={closeHandler} />;
  } else if (showForgotPassword) {
    rightDrawer = <ForgotPasswordContainer onClose={closeHandler} />;
  } else if (resetPasswordKey) {
    rightDrawer = (
      <ResetPasswordContainer
        onClose={closeHandler}
        resetPasswordKey={resetPasswordKey}
      />
    );
  }

  if (showSubHelp) {
    const helpType = getHelpTypeFromAnchor(hash);
    secondaryDrawer = (
      <HelpContainer
        onClose={secondaryCloseHandler}
        helpType={helpType}
        showChat={!showHelpTopics}
        openChat={supportChat}
      />
    );
  } else if (showChat && listingId) {
    secondaryDrawer = (
      <ListingChatDrawer
        onClose={secondaryCloseHandler}
        listingId={listingId}
      />
    );
  } else if (showChat && offerId) {
    secondaryDrawer = (
      <OfferChatDrawerContainer
        onClose={secondaryCloseHandler}
        offerId={offerId}
      />
    );
  } else if (showChat && cartId) {
    secondaryDrawer = (
      <CartChatDrawerContainer
        onClose={secondaryCloseHandler}
        cartId={cartId}
      />
    );
  } else if (showChat && orderId) {
    secondaryDrawer = (
      <OrderChatDrawerContainer
        onClose={secondaryCloseHandler}
        orderId={orderId}
      />
    );
  } else if (showChat && notificationChatId) {
    secondaryDrawer = (
      <DirectChatDrawerContainer
        onClose={secondaryCloseHandler}
        chatId={notificationChatId}
      />
    );
  } else if (showChat && merchantChatId) {
    secondaryDrawer = (
      <DirectChatDrawerContainer
        onClose={secondaryCloseHandler}
        chatId={merchantChatId}
      />
    );
  } else if (showChat && adminNotificationChatId) {
    secondaryDrawer = (
      <AdminNotificationChatDrawer
        onClose={closeHandler}
        chatId={adminNotificationChatId}
      />
    );
  } else if (merchantPageMatch && merchantId && showChat) {
    secondaryDrawer = (
      <MerchantChatDrawerContainer
        merchantId={merchantId}
        onClose={secondaryCloseHandler}
      />
    );
  } else if (showRatings && orderId) {
    secondaryDrawer = (
      <RatingsOrderContainer id={orderId} onClose={secondaryCloseHandler} />
    );
  } else if (showRatings && cartId) {
    secondaryDrawer = (
      <RatingsCartContainer onClose={secondaryCloseHandler} id={cartId} />
    );
  } else if (showRatings && listingId) {
    secondaryDrawer = (
      <RatingsListingContainer id={listingId} onClose={secondaryCloseHandler} />
    );
  } else if (showRatings && offerId) {
    secondaryDrawer = (
      <RatingsOfferContainer id={offerId} onClose={secondaryCloseHandler} />
    );
  } else if (showAvatarUpload) {
    secondaryDrawer = <EditAvatarDrawer onClose={secondaryCloseHandler} />;
  } else if (showUserDetails && listingId) {
    secondaryDrawer = (
      <ListingUserDetailsContainer
        listingId={listingId}
        onClose={secondaryCloseHandler}
      />
    );
  } else if (listingChatId && listingId && listingChat) {
    secondaryDrawer = (
      <DirectChatDrawerContainer
        onClose={secondaryCloseHandler}
        chatId={listingChatId}
      />
    );
  }

  return (
    <>
      <Drawer anchor="right" open={!!rightDrawer} onClose={closeHandler}>
        <Suspense fallback={<Loading />}>{rightDrawer}</Suspense>
      </Drawer>
      <Drawer
        anchor="right"
        open={!!secondaryDrawer}
        onClose={secondaryCloseHandler}
        stacked={!!secondaryDrawer && !!rightDrawer}
      >
        <Suspense fallback={<Loading />}>{secondaryDrawer}</Suspense>
      </Drawer>
    </>
  );
}
