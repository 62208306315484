import type { ListingPosterFragment } from 'generated-types';
import React, { ReactNode } from 'react';

const postedByMap = {
  ForSaleListing: 'Seller',
  WantedListing: 'Buyer',
  TransportationListing: 'Shipper',
  WholeSaleListing: 'Seller',
};

type Props = {
  listing: ListingPosterFragment;
  children: (props: {
    label: string;
    user: ListingPosterFragment['postedBy'];
  }) => ReactNode;
};

const ListingPoster: React.FC<Props> = (props) => {
  const { listing } = props;

  return (
    <>
      {props.children({
        label: postedByMap[listing.__typename],
        user: listing.postedBy,
      })}
    </>
  );
};

export default ListingPoster;
