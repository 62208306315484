import { gql, useQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import Fab, { FabProps } from '@material-ui/core/Fab';
import Loading from 'components/Loading';
import { SearchParametersContext } from 'contexts/SearchParameters';
import type {
  CategoriesFilterQuery,
  CategoriesFilterQueryVariables,
} from 'generated-types';
import React from 'react';
import styled, { css } from 'styled-components';

const StyledCategories = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin: 0 8px;
  padding: 0 1em 1em;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const StyledCategory = styled.li`
  margin: 0 8px 0 0;
`;

type StyledFabProps = {
  active: boolean;
} & FabProps;

const widthInPx = 90;
const heightInPx = 26;

const FabProxy = ({ active, ...rest }: StyledFabProps) => <Fab {...rest} />;

const StyledFab = styled(FabProxy)<StyledFabProps>`
  &&& {
    width: ${widthInPx}px;
    height: ${heightInPx}px;
    background-color: ${(p) => p.theme.palette.primary.main};
    color: white;
    box-shadow: none;
    letter-spacing: -0.4px;
    white-space: nowrap;
    ${(p) => {
      if (p.active) {
        return;
      }

      return css`
        background-color: #fff;
        box-shadow: inset 0 0 0 1px ${(p) => p.theme.palette.primary.main},
          0 1px 2px rgba(0, 0, 0, 0.12);
        color: ${(p) => p.theme.palette.primary.main};
      `;
    }}
    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      opacity: 0;
      border-radius: inherit;
      transition: box-shadow 0.2s ease-out;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }
`;

const GET_CATEGORIES = gql`
  query CategoriesFilterQuery {
    categories {
      results {
        id
        name
      }
      count
    }
  }
`;

const CategoriesFilterContainer: React.FC = () => {
  const { loading, error, data } = useQuery<
    CategoriesFilterQuery,
    CategoriesFilterQueryVariables
  >(GET_CATEGORIES);
  const { parameters, setParameters } = React.useContext(
    SearchParametersContext,
  );

  if (loading) return <Loading />;

  if (error) {
    return (
      <Typography color="error" component="ul">
        <Box lineHeight="normal" component="li" m={1}>
          Unable to load categories.
        </Box>
      </Typography>
    );
  }

  const categories = data?.categories.results ?? [];

  return (
    <StyledCategories>
      <StyledCategory>
        <StyledFab
          variant="extended"
          active={!parameters.c}
          onClick={() => {
            setParameters({
              ...parameters,
              c: undefined,
            });
          }}
        >
          <Typography variant="h4" component="span">
            All items
          </Typography>
        </StyledFab>
      </StyledCategory>
      {categories.map((category: any) => (
        <StyledCategory key={category.id}>
          <StyledFab
            variant="extended"
            active={parameters.c === category.id}
            onClick={() => {
              setParameters({
                navigate: '/', // force navigate to results page
                ...parameters,
                c: category.id,
              });
            }}
          >
            <Typography variant="h4" component="span">
              {category.name}
            </Typography>
          </StyledFab>
        </StyledCategory>
      ))}
    </StyledCategories>
  );
};

export default CategoriesFilterContainer;
