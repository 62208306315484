import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';

const StyledList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  padding: ${(p) => p.theme.spacing(0.5, 3, 3)};
  margin: 0;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const StyledLoading = styled.div`
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  onLoadMore?: () => void;
  hasMore?: boolean;
  loading?: boolean;
  children: React.ReactNode;
};

export default function CollectionSlider(props: Props): JSX.Element {
  const { children, onLoadMore, hasMore, loading } = props;

  return (
    <StyledList>
      {children}
      {hasMore && (
        <li>
          <StyledLoading>
            {!loading && (
              <Waypoint
                horizontal={true}
                onEnter={() => {
                  onLoadMore?.();
                }}
              />
            )}
            <CircularProgress />
          </StyledLoading>
        </li>
      )}
    </StyledList>
  );
}
