export function addQueryParam(key: string, value: string): URLSearchParams;
export function addQueryParam(key: Record<string, string>): URLSearchParams;
export function addQueryParam(
  keyOrKeys: string | Record<string, string>,
  value = '',
): URLSearchParams {
  const searchParams = new URLSearchParams(window.location.search);

  if (typeof keyOrKeys === 'string') {
    searchParams.set(keyOrKeys, value);
  } else {
    Object.keys(keyOrKeys).forEach((key) => {
      searchParams.set(key, value);
    });
  }

  return searchParams;
}

export function removeQueryParam(key: string): URLSearchParams;
export function removeQueryParam(keys: string[]): URLSearchParams;
export function removeQueryParam(
  keyOrKeys: string | string[],
): URLSearchParams {
  const searchParams = new URLSearchParams(window.location.search);

  [keyOrKeys].flat().forEach((key) => {
    searchParams.delete(key);
  });

  return searchParams;
}
