import { Portal } from '@material-ui/core';
import { amber, blue, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import SnackbarContent, {
  SnackbarContentProps,
} from '@material-ui/core/SnackbarContent';
import type { SvgIconComponent } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import styled, { css } from 'styled-components';

export enum Variant {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
}

const variantIconMap: { [variant in Variant]: SvgIconComponent } = {
  [Variant.success]: CheckCircleIcon,
  [Variant.warning]: WarningIcon,
  [Variant.error]: ErrorIcon,
  [Variant.info]: InfoIcon,
};

type StyledProps = Omit<SnackbarContentProps, 'variant'> & {
  variant: Variant;
};

const SnackbarContentProxy = ({ variant, ...rest }: StyledProps) => (
  <SnackbarContent {...rest} />
);

const StyledSnackbarContent = styled(SnackbarContentProxy)<StyledProps>`
  && {
    flex-wrap: nowrap;
    ${(p) => {
      switch (p.variant) {
        case Variant.success:
          return css`
            background-color: ${green[600]};
          `;
        case Variant.warning:
          return css`
            background-color: ${amber[700]};
          `;
        case Variant.error:
          return css`
            background-color: ${p.theme.palette.error.dark};
          `;
        case Variant.info:
          return css`
            background-color: ${blue[500]};
          `;
      }
    }}

    @media print {
      opacity: 0;
    }
  }

  & .icon {
    opacity: 0.9;
    margin-right: ${(p) => p.theme.spacing(1)}px;
  }

  & .action {
    padding-left: ${(p) => p.theme.spacing(1)}px;
  }
`;

const Message = styled.span`
  display: flex;
  align-items: center;
`;

export type AlertProps = {
  variant: keyof typeof Variant;
  message: string;
  onClose?: () => void;
} & SnackbarProps;

const Alert: React.FC<AlertProps> = (props) => {
  const { message, onClose, variant: variantString, ...other } = props;
  const variant = Variant[variantString];
  const Icon = variantIconMap[variant];

  return (
    <Portal>
      <Snackbar {...other} autoHideDuration={6000} onClose={onClose}>
        <StyledSnackbarContent
          variant={variant}
          aria-describedby="alert-snackbar"
          classes={{ action: 'action' }}
          message={
            <Message id="alert-snackbar">
              <Icon fontSize="small" className="icon" />
              {message}
            </Message>
          }
          action={
            onClose && (
              <IconButton aria-label="close" color="inherit" onClick={onClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            )
          }
        />
      </Snackbar>
    </Portal>
  );
};

export default Alert;
