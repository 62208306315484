import { gql } from '@apollo/client';
import { OrderLabelFragment } from 'components/Order/Label/fragments';

const CommonOrderUserOrderListSliderItemFulfillmentFragment = gql`
  fragment CommonOrderUserOrderListSliderItemFulfillmentFragment on OrderUser {
    id
    displayName
  }
`;

export const OrderListSliderItemFulfillmentFragment = gql`
  fragment OrderListSliderItemFulfillmentFragment on FulfillmentOrder {
    id
    deliveredAt
    paid

    buyer {
      ...CommonOrderUserOrderListSliderItemFulfillmentFragment
    }
    seller {
      ...CommonOrderUserOrderListSliderItemFulfillmentFragment
    }

    offer {
      id
      stage

      price {
        amount
        currency
        unit
      }

      listing {
        ... on Listing {
          id
          product {
            id
            name
            type
            image {
              url
            }
          }
        }

        ... on ForSaleListing {
          quantity {
            amount
            unit
          }
          cutOrSize
        }

        ... on WantedListing {
          quantity {
            amount
            unit
          }
          cutOrSize
        }
      }
    }
    ...OrderLabelFragment
  }
  ${CommonOrderUserOrderListSliderItemFulfillmentFragment}
  ${OrderLabelFragment}
`;
