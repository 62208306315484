import React from 'react';
import styled from 'styled-components';

type Props = {
  onClick?: () => void;
};

const StyledSpan = styled.span`
  cursor: pointer;
  width: fit-content;
`;

const DownChevron: React.FC<Props> = ({ onClick }) => (
  <StyledSpan onClick={onClick}>
    <svg
      width="26"
      height="26"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      color="rgb(66, 66, 66))"
    >
      <g>
        <polyline points="6 9 12 15 18 9"></polyline>
      </g>
    </svg>
  </StyledSpan>
);

export default DownChevron;
