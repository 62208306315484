import { Avatar, Chip } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { Link } from '@reach/router';
import ListSliderTile from 'components/ListSlider/Tile';
import OrderLabel from 'components/Order/Label';
import { Auth } from 'contexts/Auth';
import type { OrderListSliderItemWholeSaleFragment } from 'generated-types';
import React, { useContext } from 'react';
import styled from 'styled-components';
import cleanUrl from 'utils/clean-url';
import { currencyFormatter } from 'utils/units';

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  color: white;
  padding: 0.5rem;
  text-decoration: none;
`;

const StyledOfferParticipant = styled.span`
  ${(p) => p.theme.typography.subtitle2}
  line-height: 1;
  margin: 0;
`;

const StyledListItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
`;

const StyledListItemProductName = styled.h2`
  ${(p) => p.theme.typography.h3}
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  align-self: flex-start;
`;

const StyledListItemPrice = styled.p`
  ${(p) => p.theme.typography.subtitle1}
  margin: 0;
`;

const StyledLinkFooter = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledAvatarGroup = styled(AvatarGroup)`
  align-self: flex-start;
  & .avatar {
    font-size: 0.75rem;
    width: ${(p) => p.theme.spacing(3)}px;
    height: ${(p) => p.theme.spacing(3)}px;
  }
`;

type Props = {
  order: OrderListSliderItemWholeSaleFragment;
};

const OrderListSliderItemWholeSale: React.FC<Props> = ({ order }) => {
  const { items } = order;

  const { user } = useContext(Auth);

  const { search, hash } = window.location;
  const searchParams = new URLSearchParams(search);
  searchParams.set('order', order.id);

  // we should always be authenticated at this point...
  if (!user) {
    throw new Error(
      'In OrderListSliderItemWholeSale rendering and not Authenticated',
    );
  }

  return (
    <ListSliderTile backgroundImage="https://images.unsplash.com/photo-1557821552-17105176677c">
      <StyledLink to={cleanUrl('', searchParams, hash)}>
        <StyledOfferParticipant>
          <strong>
            <OrderLabel order={order} />
          </strong>{' '}
          {user.id === order.buyer.id ? (
            <>to {order.seller.displayName}</>
          ) : (
            <>from {order.buyer.displayName}</>
          )}
        </StyledOfferParticipant>

        <StyledListItemDescription>
          <StyledAvatarGroup spacing={4} max={6} classes={{ avatar: 'avatar' }}>
            {items.map((item) => (
              <Avatar
                key={item.id}
                alt={item.listing.product.name}
                src={item.listing.product.image?.url ?? ''}
              />
            ))}
          </StyledAvatarGroup>

          <StyledListItemProductName>
            {items.length} product(s)
          </StyledListItemProductName>

          <StyledListItemPrice>
            {order.wholesaleTotalPrice.amount !== null
              ? currencyFormatter(
                  order.wholesaleTotalPrice.amount,
                  order.wholesaleTotalPrice.currency,
                )
              : '(Price hidden)'}
          </StyledListItemPrice>
        </StyledListItemDescription>
        <StyledLinkFooter>
          <div>
            {!!order.deliveredAt && (
              <Chip label="Fulfilled" color="primary" size="small" />
            )}
            {!!order.paid && <Chip label="Paid" color="primary" size="small" />}
          </div>
        </StyledLinkFooter>
      </StyledLink>
    </ListSliderTile>
  );
};

export default OrderListSliderItemWholeSale;
