import React from 'react';
import styled from 'styled-components';

const StyledItem = styled.li`
  flex-shrink: 0;
  margin-right: 1em;
`;

type Props = {
  children: React.ReactNode;
};

export default function CollectionSliderItem({
  children,
  ...props
}: Props): JSX.Element {
  return <StyledItem {...props}>{children}</StyledItem>;
}
