import { navigate, useLocation } from '@reach/router';
import type { ListingType } from 'generated-types';
import qs from 'qs';
import React from 'react';

type Filters = {
  proximity?: number;
  availabilityRange?: {
    start: string;
    end: string;
  };
  includeTagIds?: string[];
  listingType?: ListingType; // TODO: support multiple types
};

/**
 * Parameters Type
 * Valid parameters. If you need a new parameter, add it here
 */
type SearchParameters = {
  // Use this to change URL on update
  navigate?: string;
  // Query string for search
  q?: string;
  // Category ID
  c?: string;
  // Filters for facets
  filters?: Filters;
};

/**
 * Interface for Parameters
 */
export interface ParameterContextInterface {
  parameters: SearchParameters;
  setParameters: (p: SearchParameters) => void;
}

/**
 * Get parameters from the browser URL
 *
 * @param search string. Default: window.location.search
 */
export const initializeParameters = (
  search: string = window.location.search,
) => {
  const { navigate, q, c, filters } = qs.parse(search.replace(/^\?/, ''));

  const initParams: SearchParameters = {
    navigate: navigate as string | undefined,
    q: q as string | undefined,
    c: c as string | undefined,
    filters: filters as Filters,
  };

  if (typeof initParams.filters?.proximity === 'string') {
    initParams.filters.proximity = parseInt(initParams.filters.proximity);
    if (isNaN(initParams.filters.proximity)) {
      delete initParams.filters.proximity;
    }
  }
  if (typeof initParams.filters?.availabilityRange === 'string') {
    delete initParams.filters.availabilityRange;
  }

  return initParams;
};

/**
 * Navigate to path with new parameters
 * This is where we should allow/block parameters
 *
 * @param path path to navigate to
 * @param params parameters
 */
export const navigateWithParameters = (
  path: string = '',
  params: SearchParameters,
) => {
  // override path with parameter `navigate`
  if (params.navigate) {
    // set page to go to specified path
    path = params.navigate;
    // remove navigate property
    delete params.navigate;
  }

  const formattedParams = formatParameters(params);
  const parameters = formattedParams ? `?${formattedParams}` : '';
  navigate(`${path}${parameters}`);
};

export const INIT_PARAMETERS = {
  parameters: initializeParameters(),
  // if no context available, use this
  setParameters: (newParams: SearchParameters = initializeParameters()) => {
    // default to current page, but override with `navigate` parameter
    navigateWithParameters('', newParams);
  },
};

/**
 * Context for Parameters
 */
export const SearchParametersContext = React.createContext<ParameterContextInterface>(
  INIT_PARAMETERS,
);

/**
 * Create provider context that wraps
 * @param props React props
 */
export const SearchParametersProvider = (props: {
  children: React.ReactNode;
}) => {
  const [parameters, updateParameters] = React.useState(initializeParameters);
  const location = useLocation();

  const setParameters = React.useCallback(
    (newParams: SearchParameters): void => {
      navigateWithParameters(location.pathname, newParams);
      updateParameters(newParams);
    },
    [location.pathname],
  );

  return (
    <SearchParametersContext.Provider
      value={{ parameters, setParameters }}
      {...props}
    />
  );
};

/**
 * Utility for sharing parameters
 * @param init
 */
export const useSearchParameters = (
  init: SearchParameters = initializeParameters(),
): [
  SearchParameters,
  React.Dispatch<React.SetStateAction<SearchParameters>>,
] => {
  const [parameters, setParams] = React.useState(init);
  return [parameters, setParams];
};

/**
 * Convert parameters object to string for navigation
 * @param params Parameters format parameters using `qs`
 */
export const formatParameters = (params: SearchParameters) => {
  const searchParams = qs.stringify(params);
  return searchParams;
};

export default SearchParametersContext;
