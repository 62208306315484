import styled, { css } from 'styled-components';

type Props = {
  active?: boolean;
  disabled?: boolean;
};

const Facet = styled.div<Props>`
  & label {
    white-space: nowrap;
    font-size: 0.688rem;
    height: 1.5rem;
    box-shadow: inset 0 0 0 0.063rem ${(p) => p.theme.palette.text.secondary};
    border-radius: 100px;
    padding: 0.3rem 0.5rem;
    overflow: hidden;

    ${(p) =>
      p.active &&
      css`
        background-color: ${(p) => p.theme.palette.text.secondary};
        border: none;
        color: white;
      `}

    ${(p) =>
      p.disabled &&
      css`
        background-color: ${(p) => p.theme.palette.secondary.light};
        color: ${(p) => p.theme.palette.text};
        box-shadow: inset 0 0 0 0.063rem
          ${(p) => p.theme.palette.secondary.light};
      `}

    ${(p) =>
      !p.disabled &&
      css`
        &:hover {
          cursor: default;
        }
      `}
  }
`;

export default Facet;
