import { gql, useQuery } from '@apollo/client';
import type { RouteComponentProps } from '@reach/router';
import Chrome from 'components/Chrome';
import CartsContainer from 'containers/Carts';
import CategoriesFilterContainer from 'containers/CategoriesFilter';
import FixedPriceOffersContainer from 'containers/FixedPriceOffers';
import HomeSaleListingsContainer from 'containers/HomeSaleListings';
import HomeTransportationListingsContainer from 'containers/HomeTransportationListings';
import HomeWantedListingsContainer from 'containers/HomeWantedListings';
import OffersContainer from 'containers/Offers';
import OrdersContainer from 'containers/Orders';
import { Auth } from 'contexts/Auth';
import {
  initializeParameters,
  SearchParametersProvider,
} from 'contexts/SearchParameters';
import {
  HomeListingTypesTotalQuery,
  HomeListingTypesTotalQueryVariables,
  ListingType,
} from 'generated-types';
import React, { useContext } from 'react';

const LISTING_TYPES = gql`
  query HomeListingTypesTotalQuery {
    sale: listings(query: { types: [FOR_SALE, WHOLE_SALE] }, first: 1) {
      totalCount
    }
    wanted: listings(query: { types: [WANTED] }, first: 1) {
      totalCount
    }
    transportation: listings(
      query: { types: [TRANSPORTATION_WANTED] }
      first: 1
    ) {
      totalCount
    }
  }
`;

type ListingOperationName = 'sale' | 'wanted' | 'transportation';
const listingOperations: ListingOperationName[] = [
  'sale',
  'wanted',
  'transportation',
];

export default function Home(_: RouteComponentProps) {
  const { data } = useQuery<
    HomeListingTypesTotalQuery,
    HomeListingTypesTotalQueryVariables
  >(LISTING_TYPES);
  const { user } = useContext(Auth);
  const parameters = initializeParameters();

  const categoryIds = parameters.c ? [parameters.c] : undefined;
  const listingType = parameters.filters?.listingType;

  let onlyOneListingWithData =
    data &&
    listingOperations
      .map((group) => data[group].totalCount)
      .filter((totalCount) => totalCount > 1).length <= 1;

  return (
    <SearchParametersProvider>
      <Chrome>
        <CategoriesFilterContainer />

        {user && <CartsContainer />}
        {user && <FixedPriceOffersContainer />}
        {user && (
          <OffersContainer
            proximity={parameters.filters?.proximity}
            availabilityRange={parameters.filters?.availabilityRange}
          />
        )}
        {user && <OrdersContainer />}

        {(!listingType || listingType === ListingType.WANTED) && (
          <HomeWantedListingsContainer
            listSlider={!listingType && !onlyOneListingWithData}
            text={parameters.q}
            categoryIds={categoryIds}
            includeTagIds={parameters.filters?.includeTagIds}
            proximity={parameters.filters?.proximity}
            availabilityRange={parameters.filters?.availabilityRange}
          />
        )}

        {(!listingType ||
          listingType === ListingType.FOR_SALE ||
          listingType === ListingType.WHOLE_SALE) && (
          <HomeSaleListingsContainer
            listSlider={!listingType && !onlyOneListingWithData}
            text={parameters.q}
            categoryIds={categoryIds}
            includeTagIds={parameters.filters?.includeTagIds}
            proximity={parameters.filters?.proximity}
            availabilityRange={parameters.filters?.availabilityRange}
            types={listingType ? [listingType] : undefined}
          />
        )}

        {(!listingType ||
          listingType === ListingType.TRANSPORTATION_WANTED) && (
          <HomeTransportationListingsContainer
            listSlider={!listingType && !onlyOneListingWithData}
            text={parameters.q}
            categoryIds={categoryIds}
            includeTagIds={parameters.filters?.includeTagIds}
            proximity={parameters.filters?.proximity}
          />
        )}
      </Chrome>
    </SearchParametersProvider>
  );
}
