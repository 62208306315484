import NotificationsIcon from '@material-ui/icons/Notifications';
import React from 'react';

const AdminNotificationBell: React.FC = () => (
  <>
    <NotificationsIcon color="error" />
  </>
);

export default AdminNotificationBell;
