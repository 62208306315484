import { gql } from '@apollo/client';

export const CartListSliderItemFragment = gql`
  fragment CartListSliderItemFragment on Cart {
    id
    stage
    totalPrice {
      amount
      currency
    }

    fromUser {
      id
      displayName
      businessName
      slug
    }
    toUser {
      id
      displayName
      businessName
      slug
    }

    items {
      id
      listing {
        ... on WholeSaleListing {
          id

          product {
            id
            name
            type
            image {
              url
            }
          }
        }
      }
    }
  }
`;
