import React from 'react';
import styled from 'styled-components';

const StyledListItem = styled.li`
  flex-shrink: 0;
  margin-right: 1em;
`;

const ListSliderItem: React.FC = ({ children, ...props }) => {
  return <StyledListItem {...props}>{children}</StyledListItem>;
};

export default ListSliderItem;
