import { HelpType } from 'components/Help/constants';

export const getAnchorFromHelpType = (helpType: HelpType): string => {
  switch (helpType) {
    case HelpType.ACCOUNT:
      return '#help-account';
    case HelpType.DWOLLA:
      return '#help-dwolla';
    case HelpType.LISTING:
      return '#help-listing';
    case HelpType.LOGIN:
      return '#help-login';
    case HelpType.OFFER:
      return '#help-offer';
    case HelpType.ORDER:
      return '#help-order';
    case HelpType.RATINGS:
      return '#help-ratings';
    case HelpType.GENERAL:
      return '#help';
    case HelpType.TOPICS:
      throw new Error('HelpType.TOPICS has no anchor');
  }
};

export const getHelpTypeFromAnchor = (hash: string): HelpType => {
  const splitHelpType = hash.split('-');

  switch (splitHelpType[1]) {
    case 'account':
      return HelpType.ACCOUNT;
    case 'dwolla':
      return HelpType.DWOLLA;
    case 'listing':
      return HelpType.LISTING;
    case 'login':
      return HelpType.LOGIN;
    case 'offer':
      return HelpType.OFFER;
    case 'order':
      return HelpType.ORDER;
    case 'ratings':
      return HelpType.RATINGS;
    default:
      return HelpType.GENERAL;
  }
};

export const getLabelFromHelpType = (helpType: HelpType): string => {
  switch (helpType) {
    case HelpType.ACCOUNT:
      return 'Account';
    case HelpType.DWOLLA:
      return 'Dwolla';
    case HelpType.LISTING:
      return 'Listings';
    case HelpType.LOGIN:
      return 'Login';
    case HelpType.OFFER:
      return 'Offers';
    case HelpType.ORDER:
      return 'Orders & Payments';
    case HelpType.RATINGS:
      return 'Ratings';
    case HelpType.GENERAL:
      return 'General';
    case HelpType.TOPICS:
      throw new Error('HelpType.TOPICS has no label');
  }
};
