import { Chip } from '@material-ui/core';
import { Link } from '@reach/router';
import ListSliderTile from 'components/ListSlider/Tile';
import OrderLabel from 'components/Order/Label';
import { Auth } from 'contexts/Auth';
import type { OrderListSliderItemShipmentFragment } from 'generated-types';
import React, { useContext } from 'react';
import styled from 'styled-components';
import cleanUrl from 'utils/clean-url';
import { getTransportImage } from 'utils/getTransportationImage';
import { currencyFormatter, unitFormatter } from 'utils/units';

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  color: white;
  padding: 0.5rem;
  text-decoration: none;
`;

const StyledOfferParticipant = styled.span`
  ${(p) => p.theme.typography.subtitle2}
  line-height: 1;
  margin: 0;
`;

const StyledListItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
`;

const StyledListItemProductType = styled.h3`
  ${(p) => p.theme.typography.subtitle2}
  line-height: 1;
  margin: 0;
  white-space: nowrap;
  width: 100%;
  align-self: flex-start;
`;

const StyledListItemProductName = styled.h2`
  ${(p) => p.theme.typography.h3}
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  align-self: flex-start;
`;

const StyledListItemPrice = styled.p`
  ${(p) => p.theme.typography.subtitle1}
  margin: 0;
`;

const StyledLinkFooter = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  order: OrderListSliderItemShipmentFragment;
};

const OrderListSliderItemShipment: React.FC<Props> = ({ order }) => {
  const { offer } = order;
  const { listing } = offer;
  const { product } = listing;

  const { user } = useContext(Auth);

  const { search, hash } = window.location;
  const searchParams = new URLSearchParams(search);
  searchParams.set('order', order.id);

  // we should always be authenticated at this point...
  if (!user) {
    throw new Error(
      'In OrderListSliderItemShipment rendering and not Authenticated',
    );
  }

  let backgroundImage;

  if (listing.__typename === 'TransportationListing') {
    backgroundImage = getTransportImage(listing.id);
  } else {
    backgroundImage = product.image ? product.image.url : 'none';
  }

  return (
    <ListSliderTile backgroundImage={backgroundImage}>
      <StyledLink to={cleanUrl('', searchParams, hash)}>
        <StyledOfferParticipant>
          <strong>
            <OrderLabel order={order} />
          </strong>{' '}
          {user.id === order.requester.id ? (
            <>to {order.transporter.displayName}</>
          ) : (
            <>from {order.requester.displayName}</>
          )}
        </StyledOfferParticipant>

        <StyledListItemDescription>
          <StyledListItemProductType>{product.type}</StyledListItemProductType>
          <StyledListItemProductName>{product.name}</StyledListItemProductName>

          <StyledListItemPrice>
            {unitFormatter(listing.quantity.amount, listing.quantity.unit)} for{' '}
            {currencyFormatter(offer.price.amount, offer.price.currency)}
          </StyledListItemPrice>
        </StyledListItemDescription>
        <StyledLinkFooter>
          <div>
            {!!order.deliveredAt && (
              <Chip label="Delivered" color="primary" size="small" />
            )}
            {!!order.paid && <Chip label="Paid" color="primary" size="small" />}
          </div>
        </StyledLinkFooter>
      </StyledLink>
    </ListSliderTile>
  );
};

export default OrderListSliderItemShipment;
