import { gql, useQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import CartListSliderItem from 'components/Cart/ListSliderItem';
import { CartListSliderItemFragment } from 'components/Cart/ListSliderItem/fragments';
import ListSlider from 'components/ListSlider';
import ListSliderItem from 'components/ListSlider/Item';
import Loading from 'components/Loading';
import type { CartsQuery, CartsQueryVariables } from 'generated-types';
import React, { useCallback, useState } from 'react';

const CARTS = gql`
  query CartsQuery($after: String, $toUserIdOrSlug: String) {
    carts(
      # show only Carts from Buyer and in a stage that needs action
      query: {
        fromMe: true
        stages: [DRAFT, PENDING, DECLINED]
        toUserIdOrSlug: $toUserIdOrSlug
        withItems: true
      }
      first: 10
      after: $after
    ) {
      nodes {
        id
        ...CartListSliderItemFragment
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${CartListSliderItemFragment}
`;

type Props = {
  toUserIdOrSlug?: string;
};

const CartsContainer: React.FC<Props> = (props) => {
  const { toUserIdOrSlug } = props;
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const { loading, error, data, fetchMore } = useQuery<
    CartsQuery,
    CartsQueryVariables
  >(CARTS, { variables: { toUserIdOrSlug } });

  const loadMoreHandler = useCallback(() => {
    if (!data) {
      throw new Error('Trying to load more without current data');
    }
    if (fetchMoreLoading) {
      throw new Error(
        'Trying to load next page before current page finishes loading',
      );
    }

    setFetchMoreLoading(true);

    fetchMore({
      variables: {
        after: data.carts.pageInfo.endCursor,
        toUserIdOrSlug,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setFetchMoreLoading(false);

        if (!fetchMoreResult) return prev;

        return {
          carts: {
            __typename: prev.carts.__typename,
            nodes: [...prev.carts.nodes, ...fetchMoreResult.carts.nodes],
            pageInfo: fetchMoreResult.carts.pageInfo,
            totalCount: prev.carts.totalCount,
          },
        };
      },
    });
  }, [fetchMore, fetchMoreLoading, data, toUserIdOrSlug]);

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <Typography color="error">Unable to load carts.</Typography>;
  }

  if (data.carts.totalCount === 0) {
    // No pending carts
    return null;
  }

  return (
    <ListSlider
      title={`Carts (${data.carts.totalCount})`}
      onLoadMore={loadMoreHandler}
      hasMore={data.carts.pageInfo.hasNextPage}
      loading={fetchMoreLoading}
    >
      {data.carts.nodes.map((item) => (
        <ListSliderItem key={item.id}>
          <CartListSliderItem cart={item} />
        </ListSliderItem>
      ))}
    </ListSlider>
  );
};

export default CartsContainer;
