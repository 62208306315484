import React from 'react';
import styled from 'styled-components';

const StyledItem = styled.li`
  display: flex;
  flex-basis: 100%;
  padding: 0.125rem 0;
  box-shadow: 0 0.063rem 0 0 rgba(0, 0, 0, 0.1);
`;

type Props = {
  children: React.ReactNode;
};

export default function CollectionListItem({
  children,
  ...props
}: Props): JSX.Element {
  return <StyledItem {...props}>{children}</StyledItem>;
}
