import { gql, useQuery } from '@apollo/client';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { useLocation } from '@reach/router';
import Loading from 'components/Loading';
import { SearchParametersContext } from 'contexts/SearchParameters';
import {
  ListingType,
  ListingTypeFacetQuery,
  ListingTypeFacetQueryVariables,
} from 'generated-types';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Facet from '../Facet';

const PARAM_KEY = 'listingType';
const DEFAULT_LABEL = 'Type';

const StyledSelect = styled(Select)`
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  position: absolute;
`;

const listingMapSelectedLabel = {
  FOR_SALE: 'Negotiable',
  WANTED: 'Wanted',
  TRANSPORTATION_WANTED: 'Transport',
  WHOLE_SALE: 'Wholesale',
};

const listingMapOptionLabels = {
  FOR_SALE: 'For Sale (Negotiable Price)',
  WANTED: 'Wanted (Request for Offers)',
  TRANSPORTATION_WANTED: 'Transport Wanted',
  WHOLE_SALE: 'For Sale (Wholesale)',
};

const LISTING_TYPE_FACET = gql`
  query ListingTypeFacetQuery {
    forSale: listings(query: { types: [FOR_SALE] }, first: 1) {
      totalCount
    }
    wholesale: listings(query: { types: [WHOLE_SALE] }, first: 1) {
      totalCount
    }
    wanted: listings(query: { types: [WANTED] }, first: 1) {
      totalCount
    }
    transportation: listings(
      query: { types: [TRANSPORTATION_WANTED] }
      first: 1
    ) {
      totalCount
    }
  }
`;

type ListingOperationName =
  | 'forSale'
  | 'wanted'
  | 'transportation'
  | 'wholesale';

const listingTypeToQueryOperationMap: Record<
  ListingType,
  ListingOperationName
> = {
  FOR_SALE: 'forSale',
  WANTED: 'wanted',
  TRANSPORTATION_WANTED: 'transportation',
  WHOLE_SALE: 'wholesale',
};

export default function ListingTypeFacet(): JSX.Element | null {
  const { loading, data } = useQuery<
    ListingTypeFacetQuery,
    ListingTypeFacetQueryVariables
  >(LISTING_TYPE_FACET);
  const [open, setOpen] = useState(false);
  const { parameters, setParameters } = useContext(SearchParametersContext);
  const { pathname } = useLocation();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const listingType = event.target.value as '' | ListingType;

    setParameters({
      navigate: pathname,
      ...parameters,
      filters: {
        ...parameters.filters,
        listingType: listingType ? listingType : undefined,
      },
    });
  };

  const options = [
    ListingType.FOR_SALE,
    ListingType.WHOLE_SALE,
    ListingType.WANTED,
    ListingType.TRANSPORTATION_WANTED,
  ].filter((type) => {
    // if there is an error or no data, then we don't filter.
    // this is ok because this filtering is temporary (hopefully)
    if (data?.[listingTypeToQueryOperationMap[type]].totalCount === 0) {
      return false;
    }

    return true;
  });

  if (options.length <= 1) {
    // no point on enable this facet if there aren't options to filter by
    return null;
  }

  return (
    <Facet active={Boolean(parameters.filters?.listingType)}>
      <FormControl>
        <label
          onClick={() => {
            setOpen(!open);
          }}
        >
          {parameters.filters?.listingType
            ? listingMapSelectedLabel[parameters.filters?.listingType]
            : DEFAULT_LABEL}
        </label>
        <StyledSelect
          labelId={PARAM_KEY}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          onOpen={() => {
            setOpen(true);
          }}
          value={parameters.filters?.listingType ?? ''}
          onChange={handleChange}
        >
          {loading && <Loading />}
          <MenuItem value={''}>All</MenuItem>
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {listingMapOptionLabels[option]}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </Facet>
  );
}
