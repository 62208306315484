import Chip from '@material-ui/core/Chip';
import { Link } from '@reach/router';
import ListingPoster from 'components/Listing/Poster';
import ListSliderTile from 'components/ListSlider/Tile';
import { Auth } from 'contexts/Auth';
import type { ListingListSliderItemFragment } from 'generated-types';
import React, { useContext } from 'react';
import styled from 'styled-components';
import cleanUrl from 'utils/clean-url';
import { cutsAndSizesLabels } from 'utils/cutsAndSizes';
import { getTransportImage } from 'utils/getTransportationImage';
import { currencyPerUnitFormatter, unitFormatter } from 'utils/units';

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0.5rem;
  color: white;
  text-decoration: none;
`;

const StyledList = styled.div`
  line-height: 1;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledListItemType = styled.span`
  ${(p) => p.theme.typography.subtitle1}
  line-height: 1;
  margin: 0;
`;

const PostedBy = styled.span`
  ${(p) => p.theme.typography.subtitle2}
  line-height: 1;
  margin: 0;
`;

const StyledListItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
`;

const StyledListItemProductType = styled.h3`
  ${(p) => p.theme.typography.subtitle2}
  line-height: 1;
  margin: 0;
  white-space: nowrap;
  width: 100%;
  align-self: flex-start;
`;

const StyledListItemProductName = styled.h2`
  ${(p) => p.theme.typography.h3}
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  align-self: flex-start;
`;

const StyledListItemPrice = styled.p`
  ${(p) => p.theme.typography.subtitle1}
  margin: 0;
`;

const StyledListItemPriceType = styled.p`
  ${(p) => p.theme.typography.subtitle2}
  line-height: 1;
  margin: 0;
`;

const StyledLinkFooter = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const StyledListTags = styled.div`
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0.25rem;
  color: ${(p) => p.theme.palette.background.default};
`;

const listingMap = {
  ForSaleListing: 'is selling',
  WantedListing: 'wants',
  TransportationListing: 'needs transport for',
  WholeSaleListing: 'is selling',
};

type Props = {
  listing: ListingListSliderItemFragment;
};

const ListingListSliderItem: React.FC<Props> = (props) => {
  const { user } = useContext(Auth);
  const { listing } = props;
  const { product } = listing;
  const { search, hash } = window.location;
  const searchParams = new URLSearchParams(search);
  searchParams.set('listing', listing.id);

  let backgroundImage;

  if (listing.__typename === 'TransportationListing') {
    backgroundImage = getTransportImage(listing.id);
  } else {
    backgroundImage = product.image ? product.image.url : 'none';
  }

  return (
    <ListSliderTile backgroundImage={backgroundImage}>
      <StyledLink to={cleanUrl('', searchParams, hash)}>
        <StyledList>
          <div>
            <ListingPoster listing={listing}>
              {({ user }) => <PostedBy>{user.displayName}</PostedBy>}
            </ListingPoster>{' '}
            <StyledListItemType>
              {listingMap[listing.__typename]}
            </StyledListItemType>
          </div>
          {user?.id === listing.postedBy.id && (
            <Chip color="secondary" size="small" label="Your Listing" />
          )}
        </StyledList>
        <StyledListItemDescription>
          <StyledListItemProductType>{product.type}</StyledListItemProductType>
          <StyledListItemProductName>{product.name}</StyledListItemProductName>
          <StyledListItemPrice>
            {(listing.__typename === 'ForSaleListing' ||
              listing.__typename === 'WantedListing' ||
              listing.__typename === 'WholeSaleListing') &&
              listing.cutOrSize &&
              cutsAndSizesLabels[listing.cutOrSize]}
            {(listing.__typename === 'ForSaleListing' ||
              listing.__typename === 'WantedListing' ||
              listing.__typename === 'TransportationListing') &&
              ` ${unitFormatter(
                listing.quantity.amount,
                listing.quantity.unit,
              )}`}
            {(listing.__typename === 'ForSaleListing' ||
              listing.__typename === 'WantedListing') &&
              ` at ${currencyPerUnitFormatter(
                listing.price.amount,
                listing.price.currency,
                listing.price.unit,
              )}`}
            {listing.__typename === 'WholeSaleListing' &&
              (listing.wholesalePrice.amount
                ? ` at ${currencyPerUnitFormatter(
                    listing.wholesalePrice.amount,
                    listing.wholesalePrice.currency,
                    listing.wholesalePrice.unit,
                  )}`
                : '(Price hidden)')}
            {listing.__typename === 'TransportationListing' && (
              <span>
                {' '}
                transported{' '}
                {Math.round(listing?.estimatedTravelDistance * 10) / 10} mi
              </span>
            )}
          </StyledListItemPrice>
        </StyledListItemDescription>
        <StyledLinkFooter>
          {(listing.__typename === 'ForSaleListing' ||
            listing.__typename === 'WantedListing' ||
            listing.__typename === 'WholeSaleListing') && (
            <StyledListItemPriceType>
              {listing.__typename === 'WholeSaleListing'
                ? 'Wholesale'
                : 'Negotiable'}
            </StyledListItemPriceType>
          )}
          {listing.__typename === 'TransportationListing' && (
            <StyledListItemPriceType>
              {listing?.pickupAddress?.city}, {listing?.pickupAddress?.state} to{' '}
              {listing?.deliveryAddress?.city},{' '}
              {listing?.deliveryAddress?.state}{' '}
            </StyledListItemPriceType>
          )}
          <StyledListTags>
            {listing.tags?.map((tag) => (
              <Chip
                key={tag.id}
                label={tag.name}
                variant="outlined"
                color="default"
                size="small"
              />
            ))}
          </StyledListTags>
        </StyledLinkFooter>
      </StyledLink>
    </ListSliderTile>
  );
};

export default ListingListSliderItem;
