import { Divider, IconButton } from '@material-ui/core';
import { ViewComfy, ViewList } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';

const StyledGroup = styled.div`
  display: flex;
`;

type ViewMode = 'grid' | 'list';

export const isViewMode = (mode: string): mode is ViewMode => {
  const validViewModes = ['grid', 'list'];

  return validViewModes.includes(mode);
};

export const sanitizeViewMode = (
  mode: string | null,
  fallback: ViewMode = 'grid',
): ViewMode => {
  return mode && isViewMode(mode) ? mode : fallback;
};

type Props = {
  mode: ViewMode;
  onChange: (mode: ViewMode) => void;
};

export default function CollectionViewModeSelector({
  mode,
  onChange,
}: Props): JSX.Element {
  return (
    <StyledGroup role="group">
      <IconButton
        disabled={mode === 'grid'}
        aria-label="View in grid mode"
        onClick={() => {
          onChange('grid');
        }}
      >
        <ViewComfy fontSize="large" />
      </IconButton>
      <Divider orientation="vertical" flexItem={true} />
      <IconButton
        disabled={mode === 'list'}
        aria-label="View in list mode"
        onClick={() => {
          onChange('list');
        }}
      >
        <ViewList fontSize="large" />
      </IconButton>
    </StyledGroup>
  );
}
