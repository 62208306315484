import React from 'react';
import styled from 'styled-components';
import AvailabilityEstimated from './AvailabilityEstimated';
import DistanceFacet from './Distance';
import ListingTypeFacet from './ListingType';
import TagsFacet from './Tags';

const StyledFacets = styled.div`
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin: 0;
  padding-left: 0;
`;

const Facets: React.FC = () => {
  return (
    <StyledFacets>
      <TagsFacet />
      <DistanceFacet />
      <AvailabilityEstimated />
      <ListingTypeFacet />
    </StyledFacets>
  );
};

export default Facets;
